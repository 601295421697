import { AppBar, Backdrop, Badge, Box, Button, Card, Chip, CircularProgress, Dialog, DialogActions, Divider, FormControl, Grid,
  IconButton, InputLabel, MenuItem, Select, Slide, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateNumber, validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { ATTACHMENT_STATUS, MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import TSCamera from '../../../../common/components/TSCamera.js';
import TSGallery from '../../../../common/components/TSGallery.js';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import { ORDERS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import JumpingPrice from '../booking/JumpingPrice';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import PlaylistAddCircleRoundedIcon from '@mui/icons-material/PlaylistAddCircleRounded';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OrdersDirectBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [markList, setMarkList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [isViewAccessGranted, setIsViewAccessGranted] = useState(false);
  const [isCreateAccessGranted, setIsCreateAccessGranted] = useState(false);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [isSharePdfDialogOpen, setIsSharePdfDialogOpen] = useState(false);
  const [isCreateJumpingRangeDialogOpen, setIsCreateJumpingRangeDialogOpen] = useState(false);
  const [selectedJumpingPriceData, setSelectedJumpingData] = useState({
    data: undefined,
    refresh_id: 0
  });
  const [jumpingPriceCache, setJumpingPriceCache] = useState({});
  const APIToken = {
    GET_DIRECT_BOOKING_ORDER_LIST: 'CODB01',
    GET_DIRECT_BOOKING_ORDER: 'CODB02',
    GET_PRODUCT_ID: 'CODB03',
    CREATE_DIRECT_BOOKING_ORDER: 'CODB04',
    UPDATE_DIRECT_BOOKING_ORDER: 'CODB05',
    GET_MARK_LIST: 'CODB06',
    UPLOAD_DIRECT_ORDER_ATTACHMENTS: 'CODB07',
    DOWNLOAD_DIRECT_ORDER_ATTACHMENT: 'CODB08',
    DOWNLOAD_DIRECT_ORDER_ATTACHMENTS: 'CODB09',
    GET_USER: 'CODB10',
    DOWNLOAD_DIRECT_ORDER_PDF: 'COB11'
  };
  const [orderDetails, setOrderDetails] = useState({
    order_id: '',
    order_date: '',
    ac_id: '',
    ac_name: '',
    agent_id: '',
    agent_name: '',
    tran_id: '',
    tran_name: '',
    ac_email: '',
    agent_email: '',
    remarks: '',
    order_data: [],
    refresh_id: 0
  });
  const [originalValue, setOriginalValue] = useState(orderDetails);
  const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [cameraParams, setCameraParams] = useState({});
  const [allowedFeatures, setAllowedFeatures] = useState([]);
  const [pdfDialogMessage, setPdfDialogMessage] = useState('');
  const [createdOrderDetails, setCreatedOrderDetails] = useState({
    created_order_id: '',
    ac_email: '',
    agent_email: '',
    need_email: false,
    is_create: true
  });

  const readymadeDefaultSubObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    size_id: '',
    color_id: '',
    color_name: '',
    buy_price: '',
    description: '',
    quantity: '',
    jumping_price_id: undefined,
    jumping_price_sub_data_id: undefined,
    attachments: {}
  };

  const readyMadeDefaultObject = {
    id: '',
    cate_id: '',
    cate_name: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: [readymadeDefaultSubObject]
  };

  const silkAndCutPieceDefaultSubObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    buy_price: '',
    description: '',
    quantity: '',
    attachments: {}
  };
  const silkAndCutPieceDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: [silkAndCutPieceDefaultSubObject]
  };

  const polyesterDefaultSubObject = {
    id: '',
    buy_price: '',
    no_of_sets: '',
    description: '',
    quantity: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    qty_per_set: 6,
    attachments: {}
  };
  const polyesterDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: [polyesterDefaultSubObject]
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      if (response.data.mark_list != undefined && response.data.mark_list.length > 0) {
        const filteredMark = response.data.mark_list.filter((mark) => mark.shop_id === response.data.mark_list[0].shop_id);
        setMarkList(filteredMark);
      }
      raiseGetOrderListRequest(callbackValues.isCreateAllowed);
    } else if (apiData == APIData.getDirectBookingOrderList && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER_LIST ) {
      if (response.data != undefined) {
        const updatedOrderList = [
          ...response.data,
          { order_id: '-- NEW --', order_date: moment().format( 'DD-MM-YYYY') }
        ];
        setOrderList(callbackValues.isCreateAllowed ? updatedOrderList : response.data);
        const filteredOrder = callbackValues.isCreateAllowed ? updatedOrderList.find((obj) => obj.order_id == '-- NEW --') : {};
        orderDetails.order_id = validateStringForNull(filteredOrder.order_id) ? filteredOrder.order_id : '';
        orderDetails.order_date = validateStringForNull(filteredOrder.order_date) ? filteredOrder.order_date : '';
        orderDetails.ac_name = '',
        orderDetails.ac_id = '',
        orderDetails.agent_id = '',
        orderDetails.agent_name = '',
        orderDetails.tran_id = '',
        orderDetails.tran_name = '',
        orderDetails.remarks = '',
        orderDetails.ac_email = '',
        orderDetails.agent_email = '',
        orderDetails.order_data = [];
        notifyOrderDetailsChange();
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order list');
      }
      setIsUpdateRequest(false);
    } else if (apiData == APIData.getDirectBookingOrder && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER ) {
      if (response.data != undefined) {
        setIsUpdateRequest(true);
        populateOrderData(response.data, callbackValues.showShareDialog, callbackValues.isCreateOrder);
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order details');
      }
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID ) {
      setProductName(callbackValues.id, response.data);
    } else if (apiData == APIData.createDirectBookingOrder && apiToken == APIToken.CREATE_DIRECT_BOOKING_ORDER) {
      setPdfDialogMessage('Order Created successfully! You can now email/download PO');
      const cloned = cloneDeep(orderList);
      cloned.splice(cloned.length-1, 0, { order_id: response.data.order_id, order_date: moment().format( 'DD-MM-YYYY') });
      setOrderList(cloned);
      raiseGetOrderRequest(response.data.order_id, true, true);
    } else if (apiData == APIData.updateDirectBookingOrder && apiToken == APIToken.UPDATE_DIRECT_BOOKING_ORDER) {
      setPdfDialogMessage('Order Updated Successfully! You can now email/download PO');
      raiseGetOrderRequest(callbackValues.order_id, true, false);
    } else if (apiData == APIData.uploadDirectOrderAttachments && apiToken == APIToken.UPLOAD_DIRECT_ORDER_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachmentId = response.data.attachments[callbackValues.imageKey];
      if (attachmentId == undefined) {
        subData.attachments[callbackValues.imageKey].attachment_id = undefined;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
        if (callbackValues.showSnackBar == true) {
          showSnackBar('error', 'Image upload failed. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
        }
      } else {
        subData.attachments[callbackValues.imageKey].attachment_id = attachmentId;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadDirectOrderAttachment && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENT) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachment = response.data.attachment;
      const attachmentId = response.data.attachment_id;
      let showSB = false;
      if (attachmentId == subData.attachments[callbackValues.imageKey].attachment_id) {
        try {
          subData.attachments[callbackValues.imageKey].data = Utils.base64ToFile(attachment, callbackValues.imageKey);
          subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
        } catch (err) {
          showSB = true;
          subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
        }
      } else {
        showSB = true;
        subData.attachments[callbackValues.imageKey].data = undefined;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      }
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Image download failed. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadDirectOrderAttachments && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachments = response.data;
      let showSB = false;
      callbackValues.imageKeys.map((key) => {
        const aId = subData.attachments[key].attachment_id;
        const arr = attachments.find((obj) => obj.attachment_id == aId);
        if (arr != undefined && arr != null) {
          const attachment = arr.attachment;
          try {
            subData.attachments[key].data = Utils.base64ToFile(attachment, key);
            subData.attachments[key].status = ATTACHMENT_STATUS.DONE;
          } catch (err) {
            showSB = true;
            subData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
          }
        }
      });
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'ORDERS');
        if (filtered != undefined && filtered.length > 0) {
          setAllowedFeatures(filtered[0].available_features);
          const isAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.VIEW_ORDERS);
          const isCreateAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.CREATE_OR_UPDATE_ORDERS);
          setIsViewAccessGranted(isAllowed);
          setIsCreateAccessGranted(isCreateAllowed);
          if (!isAllowed) {
            showSnackBar('error', 'You don\'t have permission to view order details');
          } else if (validateStringForNull(purchaserId)) {
            raiseGetMarkListRequest(isCreateAllowed);
            return;
          }
        }
      }
    } else if (apiData == APIData.downloadDirectOrderPdf && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_PDF) {
      showSnackBar('success', 'PDF downloaded successfully.');
      if (callbackValues.orderDetails.need_email) {
        sendPOInEmail(callbackValues.orderDetails);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      defaultMsg = 'Failed to retrieve mark list';
    } else if (apiData == APIData.getDirectBookingOrderList && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER_LIST) {
      defaultMsg = 'Failed to retrieve Order List';
    } else if (apiData == APIData.getDirectBookingOrder && apiToken == APIToken.GET_DIRECT_BOOKING_ORDER ) {
      defaultMsg = 'Failed to retrieve Order';
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID ) {
      defaultMsg = 'Failed to get product details';
    } else if (apiData == APIData.createDirectBookingOrder && apiToken == APIToken.CREATE_DIRECT_BOOKING_ORDER) {
      defaultMsg = 'Failed to Create Order';
    } else if (apiData == APIData.updateDirectBookingOrder && apiToken == APIToken.UPDATE_DIRECT_BOOKING_ORDER) {
      defaultMsg = 'Failed to Update Order';
    } else if (apiData == APIData.uploadDirectOrderAttachments && apiToken == APIToken.UPLOAD_DIRECT_ORDER_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      subData.attachments[callbackValues.imageKey].attachment_id = undefined;
      subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Image upload failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadDirectOrderAttachment && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENT) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      subData.attachments[callbackValues.imageKey].data = undefined;
      subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Image download failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadDirectOrderAttachments && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      callbackValues.imageKeys.map((key) => {
        subData.attachments[key].data = undefined;
        subData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      });
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    } else if (apiData == APIData.downloadDirectOrderPdf && apiToken == APIToken.DOWNLOAD_DIRECT_ORDER_PDF) {
      defaultMsg = 'Failed to Generate PDF';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const raiseGetMarkListRequest = (isCreateAllowed) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getMarkListForOrders },
      APIToken.GET_MARK_LIST, processSuccess, processError, { isCreateAllowed: isCreateAllowed });
  };

  const raiseGetOrderListRequest = (isCreateAllowed) => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getDirectBookingOrderList, params: params },
      APIToken.GET_DIRECT_BOOKING_ORDER_LIST, processSuccess, processError, { isCreateAllowed: isCreateAllowed } );
  };

  const raiseGetOrderRequest = (orderId, showShareDialog, isCreateOrder) => {
    setIsLoading(true);
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getDirectBookingOrder, params: params },
      APIToken.GET_DIRECT_BOOKING_ORDER, processSuccess, processError, { showShareDialog: showShareDialog, isCreateOrder: isCreateOrder } );
  };


  const raiseGetProductDetailsRequest = (id, itemId, productId) => {
    setIsLoading(true);
    const params = 'item_id=' +itemId +'&prod_id=' + productId;
    Services.sendBackendRequest({ apiData: APIData.getProductId, params: params },
      APIToken.GET_PRODUCT_ID, processSuccess, processError, { id: id });
  };

  const notifyOrderDetailsChange = () => {
    setOrderDetails({ ...orderDetails, refresh_id: orderDetails.refresh_id + 1 });
  };

  const handleSharePOClick = () => {
    setPdfDialogMessage('Your PO is ready to email/download click on below button to proceed');
    setIsSharePdfDialogOpen(true);
  };

  const handleProductDetailsSearch = (id, prodId, itemId) => {
    if (validateStringForNull(prodId) && validateStringForNull(itemId) ) {
      raiseGetProductDetailsRequest(id, itemId, prodId);
    }
  };

  const populateOrderAttachments = (attachmentIds, dataId, subdataId) => {
    const attachments = {};
    if (attachmentIds == undefined) return attachments;
    attachmentIds.map((id, i) => {
      const key = dataId.toString() + subdataId.toString() + moment().valueOf().toString() + i + '.png';
      const obj = {
        attachment_id: id,
        status: ATTACHMENT_STATUS.NOT_DOWNLOADED
      };
      attachments[key]= obj;
    });
    return attachments;
  };

  const populateOrderData = (data, showShareDialog, isCreateOrder) => {
    const obj = {};
    data.order_data.forEach((order) => {
      const materialType = MATERIAL_TYPE[order.material_type];
      if (materialType == 3) {
        const key = `${order.item_id}_${order.cate_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: order.cate_name,
            cate_id: order.cate_id,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            is_expanded: true,
            sub_data: []
          };
        }
        const readymadeObj = {
          id: obj[key].sub_data.length + 1,
          model_name: validateStringForNull(order.model_name) ? order.model_name : '',
          brand_name: validateStringForNull(order.brand_name) ? order.brand_name : '',
          model_id: validateStringForNull(order.model_id) ? order.model_id : '',
          brand_id: validateStringForNull(order.brand_id) ? order.brand_id : '',
          buy_price: order.buy_price,
          size_id: order.size_id,
          quantity: order.quantity,
          description: order.description,
          serial: order.serial,
          color_id: validateStringForNull(order.color_id) ? order.color_id : '',
          color_name: validateStringForNull(order.color_name) ? order.color_name : ''
        };
        readymadeObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, readymadeObj.id);
        obj[key].sub_data.push(readymadeObj);
      } else if (materialType == 2 || materialType == 4) {
        const key = `${order.item_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            is_expanded: true,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            sub_data: []
          };
        }
        const silkCutPieceObj = {
          id: obj[key].sub_data.length + 1,
          buy_price: order.buy_price,
          quantity: order.quantity,
          description: order.description,
          serial: order.serial,
          model_name: validateStringForNull(order.model_name) ? order.model_name : '',
          brand_name: validateStringForNull(order.brand_name) ? order.brand_name : '',
          model_id: validateStringForNull(order.model_id) ? order.model_id : '',
          brand_id: validateStringForNull(order.brand_id) ? order.brand_id : ''
        };
        silkCutPieceObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, silkCutPieceObj.id);
        obj[key].sub_data.push(silkCutPieceObj);
      } else {
        const key = `${order.item_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            is_expanded: true,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            sub_data: []
          };
        }
        const polyesterObj = {
          id: obj[key].sub_data.length + 1,
          buy_price: order.buy_price,
          quantity: order.quantity,
          description: order.description,
          model_name: validateStringForNull(order.model_name) ? order.model_name : '',
          brand_name: validateStringForNull(order.brand_name) ? order.brand_name : '',
          model_id: validateStringForNull(order.model_id) ? order.model_id : '',
          brand_id: validateStringForNull(order.brand_id) ? order.brand_id : '',
          qty_per_set: 6,
          no_of_sets: validateStringForNull(order.no_of_sets) ? order.no_of_sets : '',
          serial: order.serial
        };
        polyesterObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, polyesterObj.id);
        obj[key].sub_data.push(polyesterObj);
      }
    });
    const filteredData = Object.values(obj);
    const filteredOrderObj = {
      order_id: data.order_id,
      order_date: validateStringForNull(data.order_date) ? data.order_date.display : '',
      ac_id: validateStringForNull(data.ac_id) ? data.ac_id: '',
      ac_name: validateStringForNull(data.ac_name) ? data.ac_name: '',
      agent_id: validateStringForNull(data.agent_id) ? data.agent_id: '',
      agent_name: validateStringForNull(data.agent_name) ? data.agent_name: '',
      tran_id: validateStringForNull(data.tran_id) ? data.tran_id: '',
      tran_name: validateStringForNull(data.tran_name) ? data.tran_name: '',
      remarks: validateStringForNull(data.remarks) ? data.remarks : '',
      ac_email: validateStringForNull(data.ac_email) ? data.ac_email : '',
      agent_email: validateStringForNull(data.agent_email) ? data.agent_email : '',
      order_data: filteredData,
      refresh_id: 0
    };
    setOrderDetails(filteredOrderObj);
    setCreatedOrderDetails({ ...createdOrderDetails,
      ac_email: filteredOrderObj.ac_email,
      agent_email: filteredOrderObj.agent_email,
      created_order_id: filteredOrderObj.order_id,
      is_create: isCreateOrder
    });
    if (showShareDialog) {
      setIsSharePdfDialogOpen(true);
    }
    setOriginalValue(cloneDeep(filteredOrderObj));
  };

  const validateAndGetAttachmentsForCreateUpdate = (attachments) => {
    const imgs = [];
    if (attachments == undefined) return imgs;
    Object.keys(attachments).map((key) => {
      if (!validateStringForNull(attachments[key].attachment_id)) {
        let err = 'Please wait until the attachments finish uploading.';
        err+= ' For rows with a warning sign, click the upload button again for each image to retry uploading';
        throw new Error(err);
      }
      imgs.push(attachments[key].attachment_id);
    });
    return imgs;
  };

  const handleRemarksTextChange = (event) => {
    setOrderDetails({ ...orderDetails, [event.target.name]: event.target.value });
  };

  const computeBuyQuantityForPolyester = (subData) => {
    if (validateStringForNull(subData.no_of_sets) && validateStringForNull(subData.qty_per_set)) {
      const noOfSets = parseInt(subData.no_of_sets);
      const qtyPerSet = parseInt(subData.qty_per_set);
      subData.quantity = (noOfSets * qtyPerSet).toString();
      notifyOrderDetailsChange();
    }
  };

  const handleOrderChange = (event) => {
    const { name, value } = event.target;
    const filteredOrder = orderList.find((order) => order.order_id === value);
    setOrderDetails({ ...orderDetails, [name]: value, ac_name: '', ac_id: '', agent_id: '', remarks: '', ac_email: '',
      agent_email: '', agent_name: '', tran_id: '', tran_name: '', order_data: [], order_date: moment().format( 'DD-MM-YYYY') });
    setCreatedOrderDetails({ ...createdOrderDetails,
      created_order_id: '',
      ac_email: '',
      agent_email: '',
      need_email: false
    });
    if (filteredOrder.order_id != '-- NEW --') {
      raiseGetOrderRequest(value, false, false);
    } else {
      setIsUpdateRequest(false);
    }
    setJumpingPriceCache({});
  };

  const setProductName = (id, response) => {
    const dataObj= orderDetails.order_data.find((item) => item.id === id);
    if (dataObj != undefined && response != undefined) {
      const { prod_id: prodId, prod_name: prodName } = response;
      dataObj.prod_id = prodId;
      dataObj.prod_name = prodName;
      notifyOrderDetailsChange();
    }
  };

  const handleAddMarkClick = (mark) => {
    const material = MATERIAL_TYPE[mark.material_type];
    const lastIdData = orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id : 0;
    let newSubObj = {};
    let newObj = {};
    if (material == 3) {
      newObj = readyMadeDefaultObject;
      newSubObj = readymadeDefaultSubObject;
    } else if (material == 2 || material == 4) {
      newObj = silkAndCutPieceDefaultObject;
      newSubObj = silkAndCutPieceDefaultSubObject;
    } else if (material == 1) {
      newObj = polyesterDefaultObject;
      newSubObj = polyesterDefaultSubObject;
    }
    newSubObj.id = 1;
    newObj.id = lastIdData + 1;
    newObj.sub_data = [newSubObj];
    newObj.item_id = mark.item_id;
    newObj.material_type = material;
    newObj.mark_name = mark.mark_name;
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setOrderDetails(cloned);
  };

  const handleOrderDetailsAutoCompleteChange = (idField, nameField, value) => {
    if (value != undefined) {
      const idValue = idField === 'agent_id' || idField === 'ac_id' ? value.ac_id : value[idField];
      const nameValue = idField === 'agent_id' || idField === 'ac_id' ? value.ac_name : value[nameField];

      setOrderDetails({
        ...orderDetails,
        [idField]: idValue,
        [nameField]: nameValue
      });
    } else {
      setOrderDetails({
        ...orderDetails,
        [idField]: '',
        [nameField]: ''
      });
    }
  };

  const handleAutoCompleteChange = (value, order, idField, nameField, subData) => {
    const selectedData = subData != undefined ? subData : order;
    const oldValue = selectedData[idField];
    selectedData[idField] = value != null ? value[idField] : '';
    selectedData[nameField] = value != null ? value[nameField] : '';
    if (idField === 'cate_id') {
      order.prod_id = '';
      order.prod_name = '';
    }
    if (oldValue != selectedData[idField]) {
      if (subData != undefined) {
        removeJumpingPriceCache(subData.jumping_price_id, order);
      } else {
        if (oldValue != selectedData[idField]) {
          const jumpingPriceIdArray = [];
          order.sub_data.forEach((subData) => {
            if (!jumpingPriceIdArray.includes(subData.jumping_price_id)) {
              jumpingPriceIdArray.push(subData.jumping_price_id);
            }
            subData.jumping_price_id = undefined;
            subData.jumping_price_sub_data_id = undefined;
          });
          if (jumpingPriceIdArray.length > 0) {
            const updatedCache = cloneDeep(jumpingPriceCache);
            jumpingPriceIdArray.forEach((id) => {
              delete updatedCache[id];
            });
            setJumpingPriceCache(updatedCache);
          }
        }
      }
    }
    notifyOrderDetailsChange();
  };

  const handleAddSubDataClick = (data) => {
    const lastId = data.sub_data[data.sub_data.length - 1].id;
    let newSubObj = {};
    if (data.material_type == 3) {
      newSubObj = readymadeDefaultSubObject;
    } else if (data.material_type == 2 || data.material_type == 4) {
      newSubObj = silkAndCutPieceDefaultSubObject;
    } else if (data.material_type == 1) {
      newSubObj = polyesterDefaultSubObject;
    }
    newSubObj.id = lastId + 1;
    data.sub_data.push(newSubObj);
    notifyOrderDetailsChange();
  };

  const handleRemoveSubDataClick = (data, subData) => {
    data.sub_data = data.sub_data.filter((subItem) => subItem.id !== subData.id);
    removeJumpingPriceCache(subData.jumping_price_id, data);
    notifyOrderDetailsChange();
  };

  const handleRemoveDataClick = (id) => {
    orderDetails.order_data = orderDetails.order_data.filter((item) => item.id !== id);
    notifyOrderDetailsChange();
  };

  const handleProductIdChange = (event, data) => {
    const { name, value } = event.target;
    data[name] = value.toUpperCase();
    if (!validateStringForNull(value)) {
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleSubDataTextChange = (event, subData, order) => {
    const { name, value } = event.target;
    if (name === 'size_id') {
      removeJumpingPriceCache(subData.jumping_price_id, order);
    }
    subData[name] = value.toUpperCase();
    notifyOrderDetailsChange();
  };

  const removeJumpingPriceCache = (jumpingPriceId, orderData) => {
    if (!validateStringForNull(jumpingPriceId) || orderData.material_type != 3) {
      return;
    }
    const updatedCache = cloneDeep(jumpingPriceCache);
    delete updatedCache[jumpingPriceId];
    setJumpingPriceCache(updatedCache);

    if (orderData != undefined) {
      orderData.sub_data.forEach((subData) => {
        if (subData.jumping_price_id === jumpingPriceId) {
          subData.jumping_price_id = undefined;
          subData.jumping_price_sub_data_id = undefined;
        }
      });
    }
    notifyOrderDetailsChange();
  };

  const handleSubDataNumberChange = (event, subData, order) => {
    const { name, value } = event.target;
    if (validateStringForNull(value) && !validateNumber(value) ) {
      return;
    }
    if (name === 'quantity' || name === 'buy_price' ) {
      removeJumpingPriceCache(subData.jumping_price_id, order);
    }
    subData[name] = value == '' ? '' : Number(value);
    notifyOrderDetailsChange();
  };


  const getProductNameSearchParams = (cateId) => {
    if (validateStringForNull(cateId)) {
      return 'cate_id='+ cateId;
    }
    return '';
  };

  const handleAddPhotoClick = (dataId, subdataId) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, subdataId: subdataId, images: sdata.attachments });
      setIsCameraDialogOpen(true);
    }
  };

  const findSubData = (dataId, subdataId) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    if (data != null && data != undefined) {
      const subdata = data.sub_data.find((obj) => obj.id == subdataId);
      return subdata;
    }
    return undefined;
  };

  const handleGalleryClick = (dataId, subdataId) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, subdataId: subdataId, images: sdata.attachments });
      setIsGalleryOpen(true);
      // const ids= [];
      Object.keys(sdata.attachments).map((key) => {
        if (sdata.attachments[key].data == undefined) {
          // ids.push(key);
          handleDownloadPic(dataId, subdataId, key, false);
        }
      });
      // if (ids.length > 0) {
      //   try {
      //     raiseDownloadImages(dataId, subdataId, ids);
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
    }
  };

  const handleDownloadPic = (dataId, subdataId, imgKey, showSnackBar) => {
    if (imgKey != undefined) {
      try {
        raiseDownloadImage(dataId, subdataId, imgKey, showSnackBar);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleError = (status, message) => {
    showSnackBar(status, message);
  };

  const handleImageCapture = (img) => {
    const sdata = findSubData(cameraParams.dataId, cameraParams.subdataId);
    if (sdata != null && sdata != undefined) {
      const key = cameraParams.dataId.toString() + cameraParams.subdataId.toString() + moment().valueOf().toString() + '.png';
      const file = new File([img], key, { type: 'image/png' });
      sdata.attachments[key] = {};
      sdata.attachments[key].data = file;
      notifyOrderDetailsChange();
      try {
        raiseUploadImage(cameraParams.dataId, cameraParams.subdataId, file, false);
      } catch (err) {
        console.log(err);
      }
    }
    setIsCameraDialogOpen(false);
  };

  const handleImageDelete = (dataId, subdataId, key) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      delete sdata.attachments[key];
      notifyOrderDetailsChange();
    }
  };

  const getIsAttachmentsUploadPending = (imgs) => {
    let uploadPending = false;
    if (imgs == undefined) return uploadPending;
    Object.keys(imgs).map((key) => {
      if (!validateStringForNull(imgs[key].attachment_id)) {
        uploadPending = true;
      }
    });
    return uploadPending;
  };

  const handleAddDataClick = (order) => {
    let newSubObj;

    if (order.material_type == 1) {
      newSubObj = polyesterDefaultSubObject;
    } else if (order.material_type == 2 || order.material_type == 4) {
      newSubObj = silkAndCutPieceDefaultSubObject;
    } else {
      newSubObj = readymadeDefaultSubObject;
    }
    newSubObj.id = 1;
    let newObj;

    if (order.material_type == 3) {
      const defaultObj = cloneDeep(readyMadeDefaultObject);
      newObj = {
        ...defaultObj,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        cate_id: order.cate_id,
        cate_name: order.cate_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [cloneDeep(newSubObj)]
      };
    } else if (order.material_type == 2 || order.material_type == 4) {
      const defaultObj = cloneDeep(silkAndCutPieceDefaultObject);
      newObj = {
        ...defaultObj,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [cloneDeep(newSubObj)]
      };
    } else {
      const defaultObj = cloneDeep(polyesterDefaultObject);
      newObj = {
        ...defaultObj,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [cloneDeep(newSubObj)]
      };
    }
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const validateOrderDataArray = (params) => {
    const validatedOrders = params.order_data.map((order, index) => {
      if (order.material_type == 1) {
        return validatePolyesterPlanParams(order);
      } else if (order.material_type == 2 || order.material_type == 4) {
        return validateSilkAndCutPiecePlanParams(order);
      } else if (order.material_type == 3) {
        return validateReadyMadePlanParams(order);
      }
    });
    return validatedOrders;
  };

  const handleCreateOrUpdateOrderRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(orderDetails);
      const filteredParamsArray = validateOrderDataArray(params).flat();
      const filteredParams = {
        ...validateOrderDetails(params),
        order_data: filteredParamsArray
      };
      raiseCreateOrUpdateOrderRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Order' : 'Failed to Create Order'));
      setIsLoading(false);
    }
  };

  const validateOrderDetails = (params) => {
    const finalParams = {};
    if (isUpdateRequest) {
      if (!validateStringForNull(params.order_id)) {
        throw new Error('Select valid Order Id');
      }
      finalParams.order_id = params.order_id;
    }
    if (!validateStringForNull(params.order_date)) {
      throw new Error('Select valid Order Date');
    }
    if (!validateStringForNull(params.ac_id)) {
      throw new Error('Select valid Supplier Name');
    }

    if (validateStringForNull(params.agent_id)) {
      finalParams.agent_id = params.agent_id;
    }
    if (validateStringForNull(params.tran_id)) {
      finalParams.tran_id = params.tran_id;
    }
    if (validateStringForNull(orderDetails.remarks)) {
      finalParams.remarks = orderDetails.remarks;
    }

    finalParams.order_date = params.order_date;
    finalParams.ac_id = params.ac_id;

    return finalParams;
  };

  const validateSilkAndCutPiecePlanParams = (order) => {
    if (!validateStringForNull(order.prod_id)) {
      throw new Error('Select valid Product');
    }
    if (!validateStringForNull(order.item_id)) {
      throw new Error('Select valid Mark');
    }
    const orderDataArray = [];
    order.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const orderObj = {
        prod_id: order.prod_id,
        item_id: order.item_id,
        buy_price: parseFloat(subData.buy_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        orderObj.attachments = imgs;
      }
      if (validateStringForNull(subData.description)) {
        orderObj.description = subData.description;
      }
      if (validateStringForNull(subData.model_id)) {
        orderObj.brand_id = subData.model_id;
      }
      if (validateStringForNull(subData.brand_id)) {
        orderObj.brand_id = subData.brand_id;
      }
      orderDataArray.push(orderObj);
    });
    return orderDataArray;
  };

  const validateReadyMadePlanParams = (order) => {
    if (!validateStringForNull(order.cate_id)) {
      throw new Error('Select valid Category');
    }
    if (!validateStringForNull(order.prod_id)) {
      throw new Error('Select valid Product');
    }
    if (!validateStringForNull(order.item_id)) {
      throw new Error('Select valid Mark');
    }
    const orderDataArray = [];
    order.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const orderObj = {
        cate_id: order.cate_id,
        prod_id: order.prod_id,
        item_id: order.item_id,
        buy_price: parseFloat(subData.buy_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        orderObj.attachments = imgs;
      }
      if (validateStringForNull(subData.description)) {
        orderObj.description = subData.description;
      }
      if (validateStringForNull(subData.model_id)) {
        orderObj.model_id = subData.model_id;
      }
      if (validateStringForNull(subData.brand_id)) {
        orderObj.brand_id = subData.brand_id;
      }
      if (validateStringForNull(subData.size_id)) {
        orderObj.size_id = subData.size_id;
      }
      if (validateStringForNull(subData.color_id)) {
        orderObj.color_id = subData.color_id;
      }
      orderDataArray.push(orderObj);
    });
    return orderDataArray;
  };

  const validatePolyesterPlanParams = (order) => {
    if (!validateStringForNull(order.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(order.prod_id)) {
      throw new Error('Select valid Product');
    }

    const orderDataArray = [];

    order.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const orderObj = {
        prod_id: order.prod_id,
        item_id: order.item_id,
        buy_price: parseFloat(subData.buy_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        orderObj.attachments = imgs;
      }
      if (validateStringForNull(subData.description)) {
        orderObj.description = subData.description;
      }
      if (validateStringForNull(subData.model_id)) {
        orderObj.brand_id = subData.model_id;
      }
      if (validateStringForNull(subData.brand_id)) {
        orderObj.brand_id = subData.brand_id;
      }
      orderDataArray.push(orderObj);
    });

    return orderDataArray;
  };

  const raiseUploadImage = (dataId, subdataId, img, showSnackBar) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    const subdata = data.sub_data.find((obj) => obj.id == subdataId);
    subdata.attachments[img.name].status = ATTACHMENT_STATUS.UPLOADING;
    notifyOrderDetailsChange();
    const formData = new FormData();
    formData.append('item_id', data.item_id);
    formData.append('attachments', img);
    Services.sendMultipartRequest({ apiData: APIData.uploadDirectOrderAttachments, params: formData },
      APIToken.UPLOAD_DIRECT_ORDER_ATTACHMENTS, processSuccess, processError,
      { dataId: dataId, subdataId: subdataId, imageKey: img.name, showSnackBar: showSnackBar });
  };

  const raiseDownloadImage = (dataId, subdataId, imgKey, showSnackBar) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    const subdata = data.sub_data.find((obj) => obj.id == subdataId);
    subdata.attachments[imgKey].status = ATTACHMENT_STATUS.DOWNLOADING;
    notifyOrderDetailsChange();
    const params = 'order_id=' + orderDetails.order_id + '&serial=' + subdata.serial;
    Services.sendBackendRequest({ apiData: APIData.downloadDirectOrderAttachment,
      uriValues: [subdata.attachments[imgKey].attachment_id], params: params },
    APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENT, processSuccess, processError,
    { dataId: dataId, subdataId: subdataId, imageKey: imgKey, showSnackBar: showSnackBar });
  };

  const raiseDownloadPdfRequest = (orderDetails, isEmailPDF) => {
    const params = 'email_pdf=' + isEmailPDF;
    Services.sendPDFDownloadRequest({ apiData: APIData.downloadDirectOrderPdf, uriValues: [orderDetails.created_order_id], params: params },
      APIToken.DOWNLOAD_DIRECT_ORDER_PDF, processSuccess, processError, { orderDetails: orderDetails });
  };

  // const raiseDownloadImages = (dataId, subdataId, imgKeys, showSnackBar) => {
  //   const data = orderDetails.order_data.find((obj) => obj.id == dataId);
  //   const subdata = data.sub_data.find((obj) => obj.id == subdataId);
  //   const attachmentIds = [];
  //   imgKeys.map((key) => {
  //     subdata.attachments[key].status = ATTACHMENT_STATUS.DOWNLOADING;
  //     attachmentIds.push(subdata.attachments[key].attachment_id);
  //   });
  //   notifyOrderDetailsChange();
  //   const params = 'attachment_ids=' + attachmentIds.join(',') + '&order_id=' + orderDetails.order_id + '&serial=' + subdata.serial;
  //   Services.sendBackendRequest({ apiData: APIData.downloadDirectOrderAttachments, params: params },
  //     APIToken.DOWNLOAD_DIRECT_ORDER_ATTACHMENTS, processSuccess, processError,
  //  { dataId: dataId, subdataId: subdataId, imageKeys: imgKeys, showSnackBar: showSnackBar });
  // };

  const raiseCreateOrUpdateOrderRequest = (finalParams) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updateDirectBookingOrder;
      apiToken = APIToken.UPDATE_DIRECT_BOOKING_ORDER;
    } else {
      apiData = APIData.createDirectBookingOrder;
      apiToken = APIToken.CREATE_DIRECT_BOOKING_ORDER;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError, { order_id: orderDetails.order_id } );
  };

  const handleShowPlanDetails = (data) => {
    data.is_expanded = !data.is_expanded;
    notifyOrderDetailsChange();
  };

  const sendPOInEmail = (orderData) => {
    if (!orderData || !orderData.ac_email) {
      throw new Error('Missing required email details in orderDetails.');
    }

    const toEmail = orderData.ac_email;
    const subject = encodeURIComponent('Purchase Order Details');
    const body = encodeURIComponent('Please find the attached purchase order details.');

    let mailtoLink = `mailto:${toEmail}?subject=${subject}&body=${body}`;
    if (validateStringForNull(orderData.agent_email)) {
      mailtoLink += `&cc=${orderData.agent_email}`;
    }
    window.location.href = mailtoLink;
  };

  const handleEmailOrDownloadPdfClick = (needEmail) => {
    try {
      setIsLoading(true);
      setIsSharePdfDialogOpen(false);
      const cloned = cloneDeep(createdOrderDetails);
      cloned.need_email = needEmail;
      setCreatedOrderDetails(cloned);
      raiseDownloadPdfRequest(cloned, needEmail);
    } catch (err) {
      showSnackBar('error', err.message);
      setIsLoading(false);
    }
  };

  const getModelNameSearchParams = (prodId, brandId) => {
    if (validateStringForNull(prodId) && validateStringForNull(brandId)) {
      return 'prod_id='+ prodId+'&brand_id='+ brandId;
    }
    return '';
  };

  const handleJumpingPriceDialogClick = (order, subData) => {
    if (validateStringForNull(subData.jumping_price_id)) {
      selectedJumpingPriceData.data = jumpingPriceCache[subData.jumping_price_id];
    } else {
      selectedJumpingPriceData.data = subData;
      selectedJumpingPriceData.data.data_id = order.id;
      selectedJumpingPriceData.data.sub_data_id = subData.id;
      selectedJumpingPriceData.data.cate_id = order.cate_id;
      selectedJumpingPriceData.data.cate_name = order.cate_name;
      selectedJumpingPriceData.data.prod_id = order.prod_id;
      selectedJumpingPriceData.data.prod_name = order.prod_name;
      selectedJumpingPriceData.data.mark_name = order.mark_name;
      selectedJumpingPriceData.data.item_id = order.item_id;
    }
    notifySelectedSubDataForJumpingPriceDetails();
    setIsCreateJumpingRangeDialogOpen(true);
  };

  const notifySelectedSubDataForJumpingPriceDetails = () => {
    setSelectedJumpingData({ ...selectedJumpingPriceData, refresh_id: selectedJumpingPriceData.refresh_id +1 });
  };

  const handleJumpingPriceDialogCloseClick = () =>{
    setIsCreateJumpingRangeDialogOpen(false);
  };

  const handleJumpingPriceConfirmClick = (jumpingPriceObj) => {
    const jumpingPriceCacheId = Object.keys(jumpingPriceObj)[0];
    const newObj = cloneDeep(jumpingPriceCache);
    newObj[jumpingPriceCacheId] = jumpingPriceObj[jumpingPriceCacheId];
    setJumpingPriceCache(newObj);
    createJumpingPriceOrderDetails(jumpingPriceObj);
    setIsCreateJumpingRangeDialogOpen(false);
  };

  const createJumpingPriceOrderDetails = (data) => {
    const [jumpingPriceCacheId, jumpingPriceData] = Object.entries(data)[0];
    const { jumping_price_data: jumpingPriceDataArray } = jumpingPriceData;

    const matchingOrder = orderDetails.order_data.find((order) => {
      return order.id === jumpingPriceData.data_id;
    });

    if (!matchingOrder) {
      console.log('No matching order_data found for the given Jumping Price Object');
      return;
    }

    const matchingSubDataIndex = matchingOrder.sub_data.findIndex((sub) => {
      return sub.id === jumpingPriceData.sub_data_id;
    });
    if (matchingSubDataIndex !== -1) {
      matchingOrder.sub_data.splice(matchingSubDataIndex, 1);
    } else {
      console.log('No matching sub_data found in order_data for the given Jumping Price Object');
      return;
    }

    matchingOrder.sub_data = matchingOrder.sub_data.filter(
      (sub) => sub.jumping_price_id !== jumpingPriceCacheId
    );
    let startIndex = 1;
    if (matchingOrder.sub_data.length > 0) {
      startIndex = matchingOrder.sub_data[matchingOrder.sub_data.length - 1].id + 1;
    }

    const defaultSubObj = cloneDeep(readymadeDefaultSubObject);

    const newSubDataArray = jumpingPriceDataArray.map((jumpData, index) => ({
      ...defaultSubObj,
      id: startIndex + index,
      model_id: jumpingPriceData.model_id,
      model_name: jumpingPriceData.model_name,
      brand_id: jumpingPriceData.brand_id,
      brand_name: jumpingPriceData.brand_name,
      color_id: jumpingPriceData.color_id,
      color_name: jumpingPriceData.color_name,
      size_id: jumpData.size_id,
      buy_price: jumpData.buy_price,
      quantity: jumpData.quantity,
      jumping_price_id: jumpingPriceCacheId,
      jumping_price_sub_data_id: jumpData.id,
      attachments: jumpingPriceData.attachments
    }));
    matchingOrder.sub_data = [...matchingOrder.sub_data, ...newSubDataArray];
    notifyOrderDetailsChange();
  };

  const getMaterialContent = (order) => {
    switch (order.material_type) {
    case 1:
      return getPolyesterContent(order);
    case 2:
    case 4:
      return getSilkAndCutPieceContent(order);
    case 3:
      return getReadyMadeContent(order);
    default:
      return 'Invalid Material type';
    }
  };

  const handleCreateNewOrderButtonClick = () => {
    setIsSharePdfDialogOpen(false);
    raiseGetOrderListRequest(isCreateAccessGranted);
  };

  const getReadyMadeContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid item container xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Category Name'}
                  isDisabled={!isCreateAccessGranted}
                  apiInfo={{
                    searchURI: APIData.getCategory,
                    searchField: 'cate_name',
                    idField: 'cate_id'
                  }}
                  defaultValue={{
                    cate_id: order.cate_id,
                    cate_name: order.cate_name
                  }}
                  onSelectionChange={(event, value) =>
                    handleAutoCompleteChange(value, order, 'cate_id', 'cate_name', undefined)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Product Name'}
                  isDisabled={!isCreateAccessGranted}
                  apiInfo={{
                    searchURI: APIData.getProductName,
                    searchField: 'prod_name',
                    idField: 'prod_id',
                    searchParams: getProductNameSearchParams(order.cate_id)
                  }}
                  defaultValue={{
                    prod_id: order.prod_id,
                    prod_name: order.prod_name
                  }}
                  onSelectionChange={(event, value) =>
                    handleAutoCompleteChange(value, order, 'prod_id', 'prod_name', undefined)}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton tabIndex={-1} disabled={!isCreateAccessGranted} onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  disabled={!isCreateAccessGranted}
                  tabIndex={-1}
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton tabIndex={-1} color='primary' onClick={() => handleShowPlanDetails(order)}>
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((orderSubData, j) => (
              <Grid item container xs={12} key={orderSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={4} >
                  <CustomAutocomplete
                    label={'Brand Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getBrand,
                      searchField: 'brand_name',
                      idField: 'brand_id'
                    }}
                    defaultValue={{
                      brand_id: orderSubData.brand_id,
                      brand_name: orderSubData.brand_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, order, 'brand_id', 'brand_name', orderSubData)}
                  />
                </Grid>
                <Grid item xs={12} sm={4} >
                  <CustomAutocomplete
                    label={'Model Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getModel,
                      searchField: 'model_name',
                      idField: 'model_id',
                      searchParams: getModelNameSearchParams(order.prod_id, orderSubData.brand_id)
                    }}
                    defaultValue={{
                      model_id: orderSubData.model_id,
                      model_name: orderSubData.model_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, order, 'model_id', 'model_name', orderSubData)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Color Name'}
                    apiInfo={{
                      searchURI: APIData.getColor,
                      searchField: 'color_name',
                      idField: 'color_id'
                    }}
                    defaultValue={{
                      color_id: orderSubData.color_id,
                      color_name: orderSubData.color_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, order, 'color_id', 'color_name', orderSubData)}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <TextField
                    id="size"
                    label="Size"
                    name="size_id"
                    autoComplete ='off'
                    disabled={!isCreateAccessGranted}
                    variant='standard'
                    value={orderSubData.size_id}
                    onChange={(event) => handleSubDataTextChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8} sm={2.5}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    disabled={!isCreateAccessGranted}
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.description}
                    onChange={(event) => handleSubDataTextChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4.5} sm={2.5}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    disabled={!isCreateAccessGranted}
                    name="buy_price"
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                    type='number'
                  />
                </Grid>
                {validateStringForNull(order.cate_id) && validateStringForNull(order.prod_id) ? (
                  <Grid item xs={1} sm={1} display='flex' justifyContent='center' sx={{ mt: 2 }} >
                    <Tooltip title='Jumping Price'>
                      <span>
                        <IconButton disabled={!isCreateAccessGranted} onClick={() =>
                          handleJumpingPriceDialogClick(order, orderSubData)} color='primary' size='small'>
                          {orderSubData.jumping_price_id != undefined ? (
                            <PlaylistAddCheckCircleRoundedIcon
                              style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer', color: 'green' }} />
                          ) : (
                            <PlaylistAddCircleRoundedIcon
                              style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                ) : ''}
                <Grid item xs={4} sm={2.5}>
                  <TextField
                    id="buy_quantity"
                    label="Buy Quantity"
                    disabled={!isCreateAccessGranted}
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={3.5} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleRemoveSubDataClick(order, orderSubData)}
                      color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={orderSubData.attachments != undefined ? Object.keys(orderSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, orderSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(orderSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getSilkAndCutPieceContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid item container xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  disabled={!isCreateAccessGranted}
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_id}
                  onChange={(event) => handleProductIdChange(event, order)}
                  size='small'
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(order.id, order.prod_id, order.item_id)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton tabIndex={-1} disabled={!isCreateAccessGranted} onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  disabled={!isCreateAccessGranted}
                  tabIndex={-1}
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton tabIndex={-1} color='primary' onClick={() => handleShowPlanDetails(order)} >
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((orderSubData, j) => (
              <Grid item container xs={12} key={orderSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={2.5} >
                  <CustomAutocomplete
                    label={'Brand Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getBrand,
                      searchField: 'brand_name',
                      idField: 'brand_id'
                    }}
                    defaultValue={{
                      brand_id: orderSubData.brand_id,
                      brand_name: orderSubData.brand_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, order, 'brand_id', 'brand_name', orderSubData)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5} >
                  <CustomAutocomplete
                    label={'Model Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getModel,
                      searchField: 'model_name',
                      idField: 'model_id',
                      searchParams: getModelNameSearchParams(order.prod_id, orderSubData.brand_id)
                    }}
                    defaultValue={{
                      model_id: orderSubData.model_id,
                      model_name: orderSubData.model_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, order, 'model_id', 'model_name', orderSubData)}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="description"
                    label="Description"
                    name="description"
                    disabled={!isCreateAccessGranted}
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.description}
                    onChange={(event) => handleSubDataTextChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    disabled={!isCreateAccessGranted}
                    name="buy_price"
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                    type='number'
                  />
                </Grid>
                <Grid item xs={6} sm={1.5}>
                  <TextField
                    id="buy_quantity"
                    disabled={!isCreateAccessGranted}
                    label="Buy Qty"
                    name="quantity"
                    variant='standard'
                    autoComplete ='off'
                    value={orderSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={6} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleRemoveSubDataClick(order, orderSubData)}
                      color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={orderSubData.attachments != undefined ? Object.keys(orderSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, orderSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(orderSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getPolyesterContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid item container xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  disabled={!isCreateAccessGranted}
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_id}
                  onChange={(event) => handleProductIdChange(event, order)}
                  size='small'
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(order.id, order.prod_id)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton tabIndex={-1} disabled={!isCreateAccessGranted} onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  disabled={!isCreateAccessGranted}
                  tabIndex={-1}
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton tabIndex={-1} color='primary' onClick={() => handleShowPlanDetails(order)} >
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((orderSubData, j) => (
              <Grid container item xs={12} key={orderSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={5.25} >
                  <CustomAutocomplete
                    label={'Brand Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getBrand,
                      searchField: 'brand_name',
                      idField: 'brand_id'
                    }}
                    defaultValue={{
                      brand_id: orderSubData.brand_id,
                      brand_name: orderSubData.brand_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, order, 'brand_id', 'brand_name', orderSubData)}
                  />
                </Grid>
                <Grid item xs={12} sm={5.25} >
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Model Name'}
                    apiInfo={{
                      searchURI: APIData.getModel,
                      searchField: 'model_name',
                      idField: 'model_id',
                      searchParams: getModelNameSearchParams(order.prod_id, orderSubData.brand_id)
                    }}
                    defaultValue={{
                      model_id: orderSubData.model_id,
                      model_name: orderSubData.model_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, order, 'model_id', 'model_name', orderSubData)}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <TextField
                    id="description"
                    label="Description"
                    disabled={!isCreateAccessGranted}
                    name="description"
                    autoComplete ='off'
                    variant='standard'
                    value={orderSubData.description}
                    onChange={(event) => handleSubDataTextChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2.5}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    disabled={!isCreateAccessGranted}
                    name="buy_price"
                    autoComplete ='off'
                    type='number'
                    variant='standard'
                    value={orderSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="no_of_sets"
                    label="No Of Sets"
                    name="no_of_sets"
                    disabled={!isCreateAccessGranted}
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={orderSubData.no_of_sets}
                    onChange={(event) =>handleSubDataNumberChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                    onBlur={() => computeBuyQuantityForPolyester(orderSubData)}
                  />
                </Grid>
                <Grid item xs={4} sm={1.5}>
                  <TextField
                    id="qty_per_set"
                    label="Quan Per Set"
                    disabled={!isCreateAccessGranted}
                    name="qty_per_set"
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={orderSubData.qty_per_set}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                    onBlur={() => computeBuyQuantityForPolyester(orderSubData)}
                  />
                </Grid>
                <Grid item xs={4}sm={2}>
                  <TextField
                    id="buy_quantity"
                    label="Buy Qty"
                    name="quantity"
                    disabled={!isCreateAccessGranted}
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={orderSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, orderSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleRemoveSubDataClick(order, orderSubData)}
                      color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={orderSubData.attachments != undefined ? Object.keys(orderSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, orderSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(orderSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {isViewAccessGranted ? (
        <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
          <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant='h5' align='center'>
                Orders Direct Booking
              </Typography>
            </Grid>
            <Grid item xs={7} sm={4}>
              <FormControl fullWidth >
                <InputLabel size="small" id="order-list-select-label"> Order Id</InputLabel>
                <Select
                  labelId="order-list-select-label"
                  id="order-list-select"
                  label="Order Id"
                  name='order_id'
                  value={orderDetails.order_id}
                  onChange={(event) => handleOrderChange(event)}
                  size="small"
                >
                  {orderList.map((obj) => (
                    <MenuItem key={obj.order_id} value={obj.order_id}>
                      {obj.order_id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5} sm={2}>
              <TextField
                id="Order Date"
                label="Order Date"
                name="order_date"
                autoComplete ='off'
                value={orderDetails.order_date}
                size='small'
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomAutocomplete
                label={'Supplier Name'}
                isDisabled={!isCreateAccessGranted}
                apiInfo={{
                  searchURI: APIData.getSupplier,
                  searchField: 'ac_name',
                  idField: 'ac_id'
                }}
                defaultValue={{
                  ac_id: orderDetails.ac_id,
                  ac_name: orderDetails.ac_name
                }}
                onSelectionChange={(event, value) =>
                  handleOrderDetailsAutoCompleteChange('ac_id', 'ac_name', value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomAutocomplete
                label={'Agent Name'}
                apiInfo={{
                  searchURI: APIData.getSupplier,
                  searchField: 'ac_name',
                  idField: 'ac_id'
                }}
                disabled={!isCreateAccessGranted}
                defaultValue={{
                  ac_id: orderDetails.agent_id,
                  ac_name: orderDetails.agent_name
                }}
                onSelectionChange={(event, value) =>
                  handleOrderDetailsAutoCompleteChange('agent_id', 'agent_name', value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomAutocomplete
                label={'Transport Name'}
                apiInfo={{
                  searchURI: APIData.getTransport,
                  searchField: 'tran_name',
                  idField: 'tran_id'
                }}
                disabled={!isCreateAccessGranted}
                defaultValue={{
                  tran_id: orderDetails.tran_id,
                  tran_name: orderDetails.tran_name
                }}
                onSelectionChange={(event, value) =>
                  handleOrderDetailsAutoCompleteChange('tran_id', 'tran_name', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {orderDetails.order_data.length > 0 ?
              orderDetails.order_data.map((order, index) => (
                <React.Fragment key={index}>
                  {getMaterialContent(order)}
                </React.Fragment>
              )) : ''}
            {validateStringForNull(orderDetails.order_id)&& isCreateAccessGranted ? (
              <Grid item container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, mb: { xs: 1 } }}>
                  <Typography>
                    {orderDetails.order_data.length > 0 ? 'You can also add' : 'Select Mark'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {markList.map((mark) => (
                    <Chip
                      sx={{ m: 1 }}
                      key={mark.item_id}
                      label={mark.mark_name}
                      onClick={() => handleAddMarkClick(mark)}
                    />
                  ))}
                </Grid>
                {orderDetails.order_data.length > 0 ? (
                  <React.Fragment>
                    <Grid item xs={12} sx={{ my: 2 }}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        name="remarks"
                        autoComplete ='off'
                        value={orderDetails.remarks}
                        onChange={(event) => {handleRemarksTextChange(event);}}
                        size='small'
                        multiline
                        fullWidth
                        maxRows={4}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex', mt: 1 }}>
                      <Button
                        type='submit'
                        variant="text"
                        onClick={handleResetClick}
                        sx={{ mr: 2, fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                      >
                        Reset
                      </Button>
                      {validateStringForNull(orderDetails.order_id) && orderDetails.order_id != '-- NEW --' ? (
                        <Button
                          type="submit"
                          variant='outlined'
                          color='success'
                          onClick={handleSharePOClick}
                          sx={{ mr: 2, fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                          endIcon={<ShareIcon sx={{ fontSize: Utils.isMobile() ? '18px' : '20px' }} />}
                        >
                          Share Po
                        </Button>
                      ) : ''}
                      <Button
                        type="submit"
                        variant='contained'
                        sx={{ fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                        onClick={handleCreateOrUpdateOrderRequest}
                      >
                        {isUpdateRequest == true ? 'Update' : 'Create' }
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : ''}
              </Grid>
            ) : ''}
          </Grid>
          <Dialog
            fullScreen
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }
            }}
            open={isCreateJumpingRangeDialogOpen}
            TransitionComponent={Transition}
            onClose={() => {}}
            PaperProps={{
              sx: {
                width: {
                  lg: '50%'
                }
              }
            }}
          >
            <AppBar position='sticky'>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleJumpingPriceDialogCloseClick()}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Jumping Price
                </Typography>
              </Toolbar>
            </AppBar>
            <JumpingPrice data={selectedJumpingPriceData.data} onConfirmClick={handleJumpingPriceConfirmClick} />
          </Dialog>
          <Dialog
            fullScreen
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }
            }}
            open={isCameraDialogOpen}
            TransitionComponent={Transition}
            onClose={() => {}}
            PaperProps={{
              sx: {
                width: {
                  lg: '40%'
                }
              }
            }}
          >
            <AppBar position='sticky'>
              <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Capture Image
                </Typography>
              </Toolbar>
            </AppBar>
            <TSCamera isOpen={isCameraDialogOpen} handleImageCapture={(img) => handleImageCapture(img)}
              handleClose={() => setIsCameraDialogOpen(false)} />
          </Dialog>
          <Dialog
            fullScreen
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }
            }}
            open={isGalleryOpen}
            TransitionComponent={Transition}
            onClose={() => {}}
            PaperProps={{
              sx: {
                width: {
                  lg: '40%'
                }
              }
            }}
          >
            <AppBar position='sticky'>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setIsGalleryOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Gallery
                </Typography>
              </Toolbar>
            </AppBar>
            <TSGallery dataId={cameraParams.dataId} subdataId={cameraParams.subdataId}
              images={cameraParams.images} openCamera={handleAddPhotoClick}
              handleAddPic={handleImageCapture} handleRemovePic={handleImageDelete} handleUploadPic={raiseUploadImage}
              handleDownloadPic={handleDownloadPic} allowedFeatures={allowedFeatures} handleError={handleError}/>
          </Dialog>
          <Dialog open={isSharePdfDialogOpen}
            onClose={() => setIsSharePdfDialogOpen(false)} maxWidth="xs" fullWidth
            sx={{
              '& .MuiDialog-paper': {
                borderRadius: '15px'
              }
            }}
          >
            <Box sx={{ textAlign: 'center', p: 3, pb: 1 }}>
              <IconButton
                aria-label="close"
                onClick={() => setIsSharePdfDialogOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" gutterBottom sx={{ p: 1, mt: 2 }}>
                {pdfDialogMessage}
              </Typography>
            </Box>
            <DialogActions sx={{ justifyContent: 'center', mb: 4, flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                <Button
                  onClick={() => handleEmailOrDownloadPdfClick(true)}
                  sx={{
                    backgroundColor: '#4cac4c',
                    '&:hover': {
                      backgroundColor: '#4cac4c'
                    }
                  }}
                  variant="contained"
                  endIcon={<EmailIcon />}
                >
                  Email Po
                </Button>
                <Button
                  onClick={() => handleEmailOrDownloadPdfClick(false)}
                  sx={{
                    backgroundColor: '#4cac4c',
                    '&:hover': {
                      backgroundColor: '#4cac4c'
                    }
                  }}
                  variant="contained"
                  endIcon={<FileDownloadIcon />}
                >
                  Download Po
                </Button>
              </Box>
              {createdOrderDetails.is_create ? (
                <React.Fragment>
                  <Typography variant="h6" textAlign="center">
                    Or
                  </Typography>
                  <Button
                    onClick={handleCreateNewOrderButtonClick}
                    variant="contained"
                    color="primary"
                    endIcon={<AddCircleIcon />}
                  >
                    Create New Order
                  </Button>
                </React.Fragment>
              ) : ''}
            </DialogActions>
          </Dialog>
        </Container>
      ) : ''}
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </React.Fragment>
  );
};

export default OrdersDirectBooking;
