import { Backdrop, CircularProgress, Container, Grid, Table, TableBody, Paper,
  TableCell, TableContainer, TableHead, TableRow, Typography, IconButton,
  Tooltip, FormControl, Select, MenuItem, Button, InputLabel, Checkbox, ListItemText,
  Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import Services from '../../../../utils/Services';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { validateDate, validateStringForNull } from '../../../../utils/FieldValidations';
import APIData from '../../../../utils/APIData';
import { ANALYSIS_COMPONENTS } from '../../../../utils/EnumDefinitions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import { ORDERS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { cloneDeep } from 'lodash';

const ActivePlanList = ({ onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaserList, setPurchaserList] = useState([]);
  const [activePlanList, setActivePlanList] = useState({
    from_date: null,
    to_date: null,
    plan_data: []
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [showFilterOption, setShowfilterOption] = useState(false);
  const [isNoPlanExist, setIsNoPlanExist] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [filteredPlanList, setFilteredPlanList] = useState([]);
  const [isFilteredPlanListEmpty, setIsFilterPlanListEmpty] = useState(false);
  const [distinctPurchasers, setDistinctPurchasers] = useState({});
  const [filterSortCriteria, setFilterSortCriteria] = useState({
    purchaser_id: []
  });
  const APIToken = {
    GET_ACTIVE_PLAN_LIST: 'CAPL01',
    GET_USER: 'CAPL02',
    GET_PURCHASERS_LIST: 'CAPL03'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const raiseGetPurchaserListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getPurchaserList },
      APIToken.GET_PURCHASERS_LIST, processSuccess, processError);
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getActivePlanList && apiToken == APIToken.GET_ACTIVE_PLAN_LIST ) {
      if (response.data.length > 0) {
        setActivePlanList({ ...activePlanList, plan_data: response.data });
        setFilteredPlanList(response.data);
        setDistinctPurchasers(getUniqueAndSortedPurchasers(purchaserList, response.data));
        setShowfilterOption(true);
        setIsNoPlanExist(false);
      } else {
        setIsNoPlanExist(true);
      }
      setIsLoading(false);
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      setIsLoading(false);
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'ORDERS');
        if (filtered != undefined && filtered.length > 0) {
          setIsAdmin(filtered[0].role == 1);
          if (!validateFeature(filtered[0].available_features, ORDERS_FEATURES.ANALYSIS)) {
            showSnackBar('error', 'You don\'t have permission to analyze the orders');
          } else {
            raiseGetPurchaserListRequest();
          }
        } else {
          setIsAdmin(false);
        }
      }
    } else if (apiData == APIData.getPurchaserList && apiToken == APIToken.GET_PURCHASERS_LIST) {
      if (response.data != undefined) {
        setPurchaserList(response.data);
      }
      setIsLoading(false);
    }
  };

  const handlePurchaserSelectionChange = (event) => {
    const { target: { value } } = event;
    setFilterSortCriteria((prevState) => ({
      ...prevState,
      purchaser_id: typeof value === 'string' ? value.split(',') : value
    }));
  };

  const getUniqueAndSortedPurchasers = (purchaserList, data) => {
    const activePurchaserIds = new Set(data.map((plan) => plan.purchaser_id));
    const purchasers = {};
    const purchaserMap = new Map(purchaserList.map((details) => [details.purchaser_id, details.purchaser_name]));
    activePurchaserIds.forEach((purchaserId) => {
      if (purchaserMap.has(purchaserId)) {
        purchasers[purchaserId] = purchaserMap.get(purchaserId);
      }
    });
    const sortedPurchasers = Object.entries(purchasers)
      .sort((a, b) => a[1].localeCompare(b[1]))
      .reduce((purchaserByOrder, [id, name]) => {
        purchaserByOrder[id] = name;
        return purchaserByOrder;
      }, {});

    return sortedPurchasers;
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getActivePlanList && apiToken == APIToken.GET_ACTIVE_PLAN_LIST) {
      defaultMsg = 'Failed to Get Active plan list';
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    } else if (apiData == APIData.getPurchaserList && apiToken == APIToken.GET_PURCHASERS_LIST) {
      defaultMsg = 'Failed to get purchaser list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseActivePlanList = (finalParams) => {
    setIsLoading(true);
    let params = '';
    if (validateStringForNull(finalParams.from_date)) {
      params += 'from_date=' + finalParams.from_date;
    }
    if (validateStringForNull(finalParams.to_date)) {
      if (validateStringForNull(params)) {
        params += '&';
      }
      params +='to_date=' + finalParams.to_date;
    }
    if (!validateStringForNull(params)) {
      params = undefined;
    }
    Services.sendBackendRequest({ apiData: APIData.getActivePlanList, params: params },
      APIToken.GET_ACTIVE_PLAN_LIST, processSuccess, processError);
  };

  const handleRowClick = (planId) => {
    setSelectedPlan(planId);
  };

  const handleClearFilterClick = () => {
    const obj = {
      purchaser_id: []
    };
    setFilterSortCriteria(obj);
    const originalActivePlanList = cloneDeep(activePlanList.plan_data);
    setFilteredPlanList(originalActivePlanList);
    setIsFilterPlanListEmpty(false);
  };

  const handleFilterActivePlansApplyClick = () => {
    const filteredActivePlans = [];
    activePlanList.plan_data.map((activePlan) => {
      let valid = true;
      if (filterSortCriteria.purchaser_id.length > 0 && !filterSortCriteria.purchaser_id.includes(activePlan.purchaser_id)) {
        valid = false;
      }
      if (valid) {
        filteredActivePlans.push(activePlan);
      }
    });
    if (filteredActivePlans.length == 0) {
      setIsFilterPlanListEmpty(true);
      setFilteredPlanList([]);
    } else {
      setFilteredPlanList(filteredActivePlans);
      setIsFilterPlanListEmpty(false);
    }
  };

  const handleViewPlanClick = () => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.plan_details,
      plan_id: selectedPlan.plan_id,
      material_type: selectedPlan.material_type
    };
    onSubmit(obj);
  };

  const handleViewOrdersClick = () => {
    const obj = {
      component_id: ANALYSIS_COMPONENTS.plan_orders,
      plan_id: selectedPlan.plan_id
    };
    onSubmit(obj);
  };

  const handleDateChange = (dateType, newValue) => {
    setActivePlanList((prevDetails) => ({ ...prevDetails, [dateType]: newValue }));
  };

  const getFilterPlanMessage = () => {
    if (isLoading) {
      return 'isLoading....';
    } else if (isNoPlanExist) {
      return 'No Active plans found..!';
    } else if (isFilteredPlanListEmpty) {
      return 'No Plans found for applied filters. Modify and try again.!';
    } else {
      return '';
    }
  };

  const validateParams = () => {
    const finalParams = {};
    if (validateDate(activePlanList.from_date)) {
      finalParams.from_date = moment(activePlanList.from_date).format('yyyy-MM-DD');
    }
    if (validateDate(activePlanList.to_date)) {
      finalParams.to_date = moment(activePlanList.to_date).format('yyyy-MM-DD');
    }
    return finalParams;
  };

  const handleGetActivePlanListRequest = () => {
    setIsLoading(true);
    try {
      const finalParams = validateParams();
      raiseActivePlanList(finalParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to get active plans');
      setIsLoading(false);
    }
  };

  const getPlanData = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Plan ID</TableCell>
              <TableCell>Plan Tag</TableCell>
              <TableCell>Percentage Covered</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPlanList.map((item, index) => (
              <TableRow key={index}
                onClick={() => handleRowClick(item)} selected={selectedPlan === item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'rgb(183, 211, 249)'
                  }
                }}
              >
                <TableCell>{item.plan_id}</TableCell>
                <TableCell>{item.plan_tag}</TableCell>
                <TableCell>{validateStringForNull(item.percent_covered) ? item.percent_covered : 0}</TableCell>
                <TableCell sx={{ p: 0 }}>{selectedPlan.plan_id === item.plan_id ? (
                  <React.Fragment>
                    <Tooltip title='View Plan'>
                      <IconButton color='primary'
                        onClick={() => handleViewPlanClick()}
                      >
                        <VisibilityIcon/>
                      </IconButton>
                    </Tooltip>
                    {item.bought_quantity != undefined ? (
                      <Tooltip title='View Orders'>
                        <IconButton color='primary'
                          onClick={() => handleViewOrdersClick()}
                        >
                          <BackupTableIcon/>
                        </IconButton>
                      </Tooltip>
                    ) : ''}
                  </React.Fragment>
                ) : ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' align='center'>
           Analysis - Active Plans
          </Typography>
        </Grid>
        <Grid item container xs={12} rowSpacing={2} columnSpacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={4} display='flex' alignItems='center' >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Start Date"
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                disableFuture
                value={activePlanList.from_date}
                name="from_date"
                onChange={(newValue) => handleDateChange('from_date', newValue)}
                slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                maxDate={activePlanList.to_date}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4} display='flex' alignItems='center'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="End Date"
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                disableFuture
                value={activePlanList.to_date}
                name="to_date"
                onChange={(newValue) => handleDateChange('to_date', newValue)}
                slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
                minDate={activePlanList.from_date}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4} display='flex' justifyContent='flex-end' alignItems='flex-end'>
            <Button
              type='submit'
              variant="contained"
              onClick={handleGetActivePlanListRequest}
              size='small'
              sx={{ height: 'fit-content' }}
            >
              Get Plans
            </Button>
          </Grid>
        </Grid>
        {isAdmin && showFilterOption ? (
          <Grid item container xs={12} sx={{ mt: 2 }}>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <Typography
                variant="h6"
                component="h5"
                sx={{
                  position: 'absolute',
                  top: '-4px',
                  left: '16px',
                  background: '#fff',
                  padding: '0 8px'
                }}
              >
                Filter By
              </Typography>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  p: '16px',
                  marginTop: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {Object.keys(distinctPurchasers).length > 0 ? (
                      <FormControl fullWidth>
                        <InputLabel size='small' id="purchaser-select-label">Purchaser Name</InputLabel>
                        <Select
                          labelId="purchaser-select-label"
                          multiple
                          label="Purchaser Name"
                          value={filterSortCriteria.purchaser_id}
                          onChange={handlePurchaserSelectionChange}
                          size="small"
                          renderValue={(selected) => selected.length === 1 ? distinctPurchasers[selected[0]] :
                            selected.length === 0 ? '' : `${selected.length} purchasers selected`
                          }
                        >
                          {Object.keys(distinctPurchasers).map((purchaserId) => (
                            <MenuItem key={purchaserId} value={purchaserId}>
                              <Checkbox checked={filterSortCriteria.purchaser_id.includes(purchaserId)} />
                              <ListItemText primary={distinctPurchasers[purchaserId]} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : ''}
                  </Grid>
                  <Grid item container xs={12} sm={6} justifyContent='flex-end' alignItems='flex-end' sx={{ m: 0 }}>
                    <Grid item sx={{ mr: 2 }}>
                      <Button
                        size="small"
                        color='primary'
                        onClick={handleClearFilterClick}
                        sx={{ borderRadius: 15, fontSize: '13px' }}
                      >
                        Clear
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        size="small"
                        color='primary'
                        sx={{ borderRadius: 15, fontSize: '13px' }}
                        onClick={handleFilterActivePlansApplyClick}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        ) : ''}
        {filteredPlanList.length > 0 ? (
          <Grid item xs={12}>
            {getPlanData()}
          </Grid>
        ) :
          <Grid item xs={12} sx={{ my: 15 }}>
            <Typography variant='h6' display='flex' justifyContent='center' alignItems='center'>
              {getFilterPlanMessage()}
            </Typography>
          </Grid>
        }
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

ActivePlanList.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default ActivePlanList;
