import { Button, Card, Container, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { Utils } from '../../../../utils/UtilFunctions';
import PropTypes from 'prop-types';

const JumpingPrice = ({ data, onConfirmClick }) => {
  const getPossibleInitialData = (data, primaryKey, secondaryKey) => {
    if (validateStringForNull(data[primaryKey])) {
      return data[primaryKey];
    } else if (!validateStringForNull(data.jumping_price_id) && validateStringForNull(data[secondaryKey])) {
      return data[secondaryKey];
    } else {
      return '';
    }
  };
  const [jumpingPriceObj, setJumpingPriceObj] = useState({
    cate_id: data.cate_id,
    cate_name: data.cate_name,
    prod_id: data.prod_id,
    prod_name: data.prod_name,
    model_id: data.model_id,
    model_name: data.model_name,
    brand_id: data.brand_id,
    brand_name: data.brand_name,
    color_id: data.color_id,
    color_name: data.color_name,
    mark_name: data.mark_name,
    item_id: data.item_id,
    jumping_price_id: data.jumping_price_id,
    starting_price: getPossibleInitialData(data, 'starting_price', 'buy_price'),
    increment_by: validateStringForNull(data.increment_by) ? data.increment_by : '',
    quantity_per_size: getPossibleInitialData(data, 'quantity_per_size', 'quantity'),
    sub_data_id: data.sub_data_id,
    data_id: data.data_id,
    jumping_price_data: validateStringForNull(data.jumping_price_data) ? data.jumping_price_data : [],
    refresh_id: 0
  });

  const defaultJumpingPriceDataObj = {
    id: 0,
    size_id: '',
    buy_price: '',
    quantity: ''
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const getSelectedOrderDetails = () => {
    if (data != undefined) {
      let str = `(${data.mark_name})- ${data.cate_name}/ ${data.prod_name}`;
      if (validateStringForNull(data.brand_id)) {
        str += `/ ${data.brand_name}`;
      }
      if (validateStringForNull(data.model_id)) {
        str += `/ ${data.model_name}`;
      }
      if (validateStringForNull(data.color_id)) {
        str += `/ ${data.color_name}`;
      }
      return str;
    } else {
      return '';
    }
  };

  const calculateBuyPrices = (data) => {
    return data.map((item, index) => {
      return {
        ...item,
        id: index,
        buy_price: (index * jumpingPriceObj.increment_by) + jumpingPriceObj.starting_price,
        quantity: jumpingPriceObj.quantity_per_size
      };
    });
  };

  const handleApplyJumpingPriceClick = () => {
    if (!validateStringForNull(jumpingPriceObj.starting_price)) {
      showSnackBar('error', 'Please enter a starting price before applying');
      return;
    };

    if (!validateStringForNull(jumpingPriceObj.increment_by)) {
      showSnackBar('error', 'Please enter a increment price before applying');
      return;
    };

    if (!validateStringForNull(jumpingPriceObj.quantity_per_size)) {
      showSnackBar('error', 'Please enter a quantity per size  before applying');
      return;
    };

    if (jumpingPriceObj.jumping_price_data.length == 0) {
      const newSubObj = defaultJumpingPriceDataObj;
      newSubObj.buy_price = jumpingPriceObj.starting_price;
      newSubObj.quantity = jumpingPriceObj.quantity_per_size;
      jumpingPriceObj.jumping_price_data.push(newSubObj);
    } else {
      jumpingPriceObj.jumping_price_data = calculateBuyPrices(jumpingPriceObj.jumping_price_data);
    }
    notifyJumpingPriceObjChange();
  };

  const notifyJumpingPriceObjChange = () => {
    setJumpingPriceObj({ ...jumpingPriceObj, refresh_id: jumpingPriceObj.refresh_id + 1 });
  };

  const handleSubDataTextChange = (event, subData) => {
    const { name, value } = event.target;
    subData[name] = name === 'size_id' ? value.toUpperCase() : value;
    notifyJumpingPriceObjChange();
  };

  const generateJumpingObjKey = () => {
    let key = `${jumpingPriceObj.data_id}-${jumpingPriceObj.sub_data_id}-${jumpingPriceObj.cate_id}-${jumpingPriceObj.prod_id}`;
    if (validateStringForNull(jumpingPriceObj.brand_id)) {
      key += `-${jumpingPriceObj.brand_id}`;
    }
    if (validateStringForNull(jumpingPriceObj.model_id)) {
      key += `-${jumpingPriceObj.model_id}`;
    }
    if (validateStringForNull(jumpingPriceObj.color_id)) {
      key += `-${jumpingPriceObj.color_id}`;
    }
    return key;
  };

  const handleAddSizeClick = (clickedId) => {
    const clickedIndex = jumpingPriceObj.jumping_price_data.findIndex((item) => item.id === clickedId);
    const newSubObj = {
      ...defaultJumpingPriceDataObj,
      id: clickedId + 1
    };
    jumpingPriceObj.jumping_price_data.splice(clickedIndex + 1, 0, newSubObj);
    jumpingPriceObj.jumping_price_data = calculateBuyPrices(jumpingPriceObj.jumping_price_data);
    notifyJumpingPriceObjChange();
  };

  const handleRemoveSizeClick = (clickedId) => {
    jumpingPriceObj.jumping_price_data = jumpingPriceObj.jumping_price_data.filter(
      (item) => item.id !== clickedId
    );
    jumpingPriceObj.jumping_price_data = calculateBuyPrices(jumpingPriceObj.jumping_price_data);
    notifyJumpingPriceObjChange();
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setJumpingPriceObj({ ...jumpingPriceObj, [name]: value == '' ? '' : Number(value) });
  };

  const handleConfirmClick = () => {
    if (jumpingPriceObj.jumping_price_data.length == 0) {
      showSnackBar('warning', 'Please create atleast 1 jumping price to confirm');
    }
    const key = generateJumpingObjKey();
    const finalJumpObj = {
      [key]: jumpingPriceObj
    };
    onConfirmClick(finalJumpObj);
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Card elevation={12}>
        <Grid container>
          <Grid item container xs={12} columnSpacing={2} rowSpacing={2}
            sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
            <Grid item xs={12}>
              <Typography variant='caption'>
                {getSelectedOrderDetails()}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3.5}>
              <TextField
                id="starting_price"
                name="starting_price"
                autoComplete ='off'
                label='Starting Price'
                sx={{ backgroundColor: '#fff' }}
                // disabled={!isCreateAccessGranted}
                variant='outlined'
                type='number'
                onChange={(event) => handleTextChange(event)}
                value={jumpingPriceObj.starting_price}
                size='small'
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                id="increment_by"
                name="increment_by"
                autoComplete ='off'
                label= 'Increment By'
                sx={{ backgroundColor: '#fff' }}
                // disabled={!isCreateAccessGranted}
                variant='outlined'
                type='number'
                onChange={(event) => handleTextChange(event)}
                value={jumpingPriceObj.increment_by}
                size='small'
                fullWidth
              />
            </Grid>
            <Grid item xs={7.8} sm={4}>
              <TextField
                id="quantity_per_size"
                name="quantity_per_size"
                autoComplete ='off'
                label='Qty Per Size'
                sx={{ backgroundColor: '#fff' }}
                onChange={(event) => handleTextChange(event)}
                // disabled={!isCreateAccessGranted}
                variant='outlined'
                type='number'
                value={jumpingPriceObj.quantity_per_size}
                size='small'
                fullWidth
              />
            </Grid>
            <Grid item xs={4.2} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', alignItems: 'center', display: 'flex' }}>
              <Button
                type="submit"
                variant='contained'
                size='small'
                onClick={handleApplyJumpingPriceClick}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
          {jumpingPriceObj.jumping_price_data.length > 0 ? jumpingPriceObj.jumping_price_data.map((subData, i) => (
            <Grid item container xs={12} key={subData.id} rowSpacing={2} columnSpacing={2}
              sx={{ py: 1, px: 2 }}>
              <Grid item xs={12} sm={3.5} >
                <TextField
                  id="size"
                  label="Size"
                  name="size_id"
                  autoComplete ='off'
                  value={subData.size_id}
                  variant='standard'
                  onChange={(event) => handleSubDataTextChange(event, subData)}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3.5} >
                <TextField
                  id="buy_price"
                  label="Buy Price"
                  name="buy_price"
                  disabled={true}
                  autoComplete ='off'
                  value={subData.buy_price}
                  variant='standard'
                  type='number'
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={10} sm={3}>
                <TextField
                  id="quantity"
                  label="Quantity"
                  name="quantity"
                  // disabled={!isCreateAccessGranted}
                  autoComplete ='off'
                  value={subData.quantity}
                  variant='standard'
                  type='number'
                  onChange={(event) => handleSubDataTextChange(event, subData)}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sm={2} display='flex' justifyContent='flex-end' alignItems='center' sx={{ mt: 1 }}>
                <IconButton onClick={() => handleAddSizeClick(subData.id)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton onClick={() => handleRemoveSizeClick(subData.id)} color='error' size='small'>
                  <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
              </Grid>
            </Grid>
          )) : ''}
        </Grid>
      </Card>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        {jumpingPriceObj.jumping_price_data.length > 0 ? (
          <Grid item xs={12} sx={{ mt: 2 }} display='flex' justifyContent='flex-end'>
            <Button
              variant="contained"
              onClick={handleConfirmClick}
            >
                Confirm
            </Button>
          </Grid>
        ) : ''}
      </Grid>
      {getSnackbar}
    </Container>
  );
};

JumpingPrice.propTypes = {
  data: PropTypes.object,
  onConfirmClick: PropTypes.func
};

export default JumpingPrice;
