import { Backdrop, Button, Checkbox, Chip, CircularProgress, Container, FormControl, Grid, InputLabel, ListItemText, MenuItem,
  Paper, Table, TableBody, TableContainer, TableHead, TableCell, TableRow,
  Select, Typography,
  Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ORDER_REPORT_OPTIONS } from '../../../../utils/EnumDefinitions';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import APIData from '../../../../utils/APIData';
import moment from 'moment';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import Services from '../../../../utils/Services';
import { validateDate, validateStringForNull } from '../../../../utils/FieldValidations';
import { ORDERS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator';
import { cloneDeep } from 'lodash';

const OrderReports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [purchaserList, setPurchaserList] = useState([]);
  const [allowedFeatures, setAllowedFeatures] = useState([]);
  const [filteredPlanList, setFilteredPlanList] = useState([]);
  const [filteredPurchaserList, setFilteredPurchaserList] = useState({});
  const [reportDetails, setReportDetails] = useState({
    from_date: null,
    to_date: null,
    report_option: 0,
    report_data: [],
    refresh_id: 0
  });
  const [filteredReportData, setFilteredReportData] = useState([]);
  const [isFilteredReportDataEmpty, setIsFilteredReportDataEmpty] = useState(false);
  const [isReportDataEmpty, setIsReportDataEmpty] = useState(false);
  const [filterSortCriteria, setFilterSortCriteria] = useState({
    purchaser_id: [],
    plan_id: []
  });
  const APIToken = {
    GET_ORDER_REPORTS: 'COR01',
    GET_USER: 'COR02',
    GET_PURCHASERS_LIST: 'COR03'
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getOrderReports && apiToken == APIToken.GET_ORDER_REPORTS ) {
      if (response.data != undefined && response.data.length > 0) {
        setReportDetails({ ...reportDetails, report_data: response.data });
        setFilteredPurchaserList(getUniqueAndSortedPurchasers(purchaserList, response.data));
        setFilteredReportData(response.data);
        const distinctPlanIds = getDistinctPlanIds(response.data);
        setFilteredPlanList(distinctPlanIds);
        setFilterSortCriteria({
          purchaser_id: [],
          plan_id: []
        });
        setIsReportDataEmpty(false);
      } else {
        setIsReportDataEmpty(true);
      }
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'ORDERS');
        if (filtered != undefined && filtered.length > 0) {
          setAllowedFeatures(filtered[0].available_features);
          setIsAdmin(filtered[0].role == 1);
          if (!validateFeature(filtered[0].available_features, ORDERS_FEATURES.ANALYSIS)) {
            showSnackBar('error', 'You don\'t have permission to view reports');
          } else {
            raiseGetPurchaserListRequest();
            return;
          }
        } else {
          setIsAdmin(false);
        }
      }
    } else if (apiData == APIData.getPurchaserList && apiToken == APIToken.GET_PURCHASERS_LIST) {
      if (response.data != undefined) {
        setPurchaserList(response.data);
      }
    }
    setIsLoading(false);
  };

  const getDistinctPlanIds = (data) => {
    const planIds = data.map((item)=> item.plan_id);
    const validPlanIds = planIds.filter((item) => validateStringForNull(item));
    return [...new Set(validPlanIds)];
  };

  const getUniqueAndSortedPurchasers = (purchaserList, data) => {
    const activePurchaserIds = new Set(data.map((report) => report.purchaser_id));
    const purchasers = {};
    const purchaserMap = new Map(purchaserList.map((details) => [details.purchaser_id, details.purchaser_name]));
    activePurchaserIds.forEach((purchaserId) => {
      if (purchaserMap.has(purchaserId)) {
        purchasers[purchaserId] = purchaserMap.get(purchaserId);
      }
    });
    const sortedPurchasers = Object.entries(purchasers)
      .sort((a, b) => a[1].localeCompare(b[1]))
      .reduce((purchaserByOrder, [id, name]) => {
        purchaserByOrder[id] = name;
        return purchaserByOrder;
      }, {});

    return sortedPurchasers;
  };

  const raiseGetPurchaserListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getPurchaserList },
      APIToken.GET_PURCHASERS_LIST, processSuccess, processError);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getOrderReports && apiToken == APIToken.GET_ORDER_REPORTS) {
      defaultMsg = 'Failed to Get Order Reports';
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    } else if (apiData == APIData.getPurchaserList && apiToken == APIToken.GET_PURCHASERS_LIST) {
      defaultMsg = 'Failed to get purchaser list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const handleClearFilterClick = () => {
    const obj = {
      purchaser_id: [],
      plan_id: []
    };
    setFilterSortCriteria(obj);
    const originalData = cloneDeep(reportDetails.report_data);
    setFilteredReportData(originalData);
    setFilteredPlanList(getDistinctPlanIds(originalData));
    setFilteredPurchaserList(getUniqueAndSortedPurchasers(purchaserList, originalData));
    setIsFilteredReportDataEmpty(false);
  };

  const handleFilterOrderReportsApplyClick = () => {
    const filteredOrderReports = [];
    reportDetails.report_data.map((report) => {
      let valid = true;
      if (filterSortCriteria.purchaser_id.length > 0 && !filterSortCriteria.purchaser_id.includes(report.purchaser_id)) {
        valid = false;
      }
      if (valid && filterSortCriteria.plan_id.length > 0 &&
        !filterSortCriteria.plan_id.includes(report.plan_id)) {
        valid = false;
      }
      if (valid) {
        filteredOrderReports.push(report);
      }
    });
    if (filteredOrderReports.length == 0) {
      setIsFilteredReportDataEmpty(true);
      setFilteredReportData([]);
    } else {
      setFilteredReportData(filteredOrderReports);
      setIsFilteredReportDataEmpty(false);
    }
  };

  const handleGetReportClick = () => {
    setIsLoading(true);
    try {
      const finalParams = validateParams();
      raiseGetOrderReports(finalParams);
    } catch (err) {
      showSnackBar('error', err.message ?? 'Failed to Get Report');
      setIsLoading(false);
    }
  };

  const validateParams = () => {
    const finalParams = {};
    if (validateDate(reportDetails.from_date)) {
      finalParams.from_date = moment(reportDetails.from_date).format('yyyy-MM-DD');
    }
    if (validateDate(reportDetails.to_date)) {
      finalParams.to_date = moment(reportDetails.to_date).format('yyyy-MM-DD');
    }
    if (reportDetails.report_option <= 0) {
      throw new Error('Select valid Report option');
    }
    finalParams.report_option = reportDetails.report_option;

    return finalParams;
  };

  const raiseGetOrderReports = (finalParams) => {
    setIsLoading(true);
    let params = 'report_options=' + finalParams.report_option;
    if (validateStringForNull(finalParams.from_date)) {
      params += '&from_date=' + finalParams.from_date;
    }
    if (validateStringForNull(finalParams.to_date)) {
      params +='&to_date=' + finalParams.to_date;
    }
    Services.sendBackendRequest({ apiData: APIData.getOrderReports, params: params },
      APIToken.GET_ORDER_REPORTS, processSuccess, processError);
  };

  const handleOrderInfoChipClick = (chipValue) => {
    if ((reportDetails.report_option & chipValue) === chipValue) {
      reportDetails.report_option = reportDetails.report_option - chipValue;
    } else {
      reportDetails.report_option = reportDetails.report_option + chipValue;
    }
    reportDetails.report_data = [];
    notifyReportValueChange();
  };

  const notifyReportValueChange = () => {
    setReportDetails({ ...reportDetails, refresh_id: reportDetails.refresh_id + 1 });
  };

  const handleDateChange = (dateType, newValue) => {
    setReportDetails((prevDetails) => ({ ...prevDetails, [dateType]: newValue }));
  };

  const handlePurchaserSelectionChange = (event) => {
    const { target: { value } } = event;

    setFilterSortCriteria((prevState) => ({
      ...prevState,
      purchaser_id: typeof value === 'string' ? value.split(',') : value
    }));
    const selectedPurchasers = typeof value === 'string'? value.split(',') : value;

    const filteredData = reportDetails.report_data
      .filter((data) => selectedPurchasers.includes(data.purchaser_id));
    setFilteredPlanList(getDistinctPlanIds(filteredData));
  };

  const handlePlanSelectionChange = (event) => {
    const { target: { value } } = event;
    setFilterSortCriteria((prevState) => ({
      ...prevState,
      plan_id: typeof value === 'string' ? value.split(',') : value
    }));
    const selectedPlans = typeof value === 'string'? value.split(',') : value;

    const filteredData = reportDetails.report_data
      .filter((data) => selectedPlans.includes(data.plan_id));
    setFilteredPurchaserList(getUniqueAndSortedPurchasers(purchaserList, filteredData));
  };

  const getFilterReportDataMessage = () => {
    if (isLoading) {
      return 'isLoading....';
    } else if (isReportDataEmpty) {
      return 'No Active orders found..!';
    } else if (isFilteredReportDataEmpty) {
      return 'No orders found for applied filters. Modify and try again.!';
    } else {
      return '';
    }
  };

  const renderRows = () => {
    return filteredReportData.map((item, index) => (
      <TableRow key={index}>
        {(reportDetails.report_option == 1 || reportDetails.report_option == 3) ? (
          <>
            <TableCell>{item.order_id}</TableCell>
            <TableCell>{item.plan_id}</TableCell>
            <TableCell>{item.ac_name}</TableCell>
            <TableCell>{item.planned_quantity}</TableCell>
            <TableCell>{item.bought_quantity}</TableCell>
            <TableCell>{item.percent_covered}</TableCell>
          </>
        ) : ''}
        {reportDetails.report_option == 2 ? (
          <>
            <TableCell>{item.order_id}</TableCell>
            <TableCell>{item.ac_name}</TableCell>
            <TableCell>{item.bought_quantity}</TableCell>
          </>
        ) : ''}
      </TableRow>
    ));
  };

  const getTableData = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {(reportDetails.report_option == 1 || reportDetails.report_option == 3) ? (
                <>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Plan ID</TableCell>
                  <TableCell>Supplier Name</TableCell>
                  <TableCell>Planned Qty</TableCell>
                  <TableCell>Bought Qty</TableCell>
                  <TableCell>% Covered</TableCell>
                </>
              ) : ''}
              {reportDetails.report_option === 2 ? (
                <>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Supplier Name</TableCell>
                  <TableCell>Bought Qty</TableCell>
                </>
              ) : ''}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRows()}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant='h5' align='center'>
            Order Report
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} display='flex' alignItems='center' >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Start Date"
              format="DD/MM/YYYY"
              sx={{ width: '100%' }}
              disableFuture
              value={reportDetails.from_date}
              name="from_date"
              onChange={(newValue) => handleDateChange('from_date', newValue)}
              slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
              maxDate={reportDetails.to_date}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3} display='flex' alignItems='center'>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="End Date"
              format="DD/MM/YYYY"
              sx={{ width: '100%' }}
              disableFuture
              value={reportDetails.to_date}
              name="to_date"
              onChange={(newValue) => handleDateChange('to_date', newValue)}
              slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
              minDate={reportDetails.from_date}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ mt: -1 }}>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Typography
              variant="h6"
              component="h5"
              sx={{
                position: 'absolute',
                top: '-4px',
                left: '16px',
                background: '#fff',
                padding: '0 8px'
              }}
            >
              Request Options
            </Typography>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '8px',
                marginTop: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px'
              }}
            >
              <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                {Object.keys(ORDER_REPORT_OPTIONS).map((type, i) => (
                  <Chip
                    sx={{ m: 1 }}
                    key={i}
                    label={ORDER_REPORT_OPTIONS[type]}
                    onClick={() => handleOrderInfoChipClick(parseInt(type))}
                    color={(reportDetails.report_option & parseInt(type)) === parseInt(type) ? 'primary' : undefined}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>
        {validateFeature(allowedFeatures, ORDERS_FEATURES.ANALYSIS) ? (
          <Grid item container display='flex' justifyContent='flex-end' alignItems='center' xs={12} sm={6} md={2}>
            <Button
              type='submit'
              variant="contained"
              onClick={handleGetReportClick}
              size='small'
              sx={{ height: 'fit-content' }}
            >
              Get Report
            </Button>
          </Grid>
        ): ''}
        {isAdmin && filteredReportData.length > 0 ? (
          <Grid item container xs={12} sx={{ mt: 2 }}>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <Typography
                variant="h6"
                component="h5"
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  left: '16px',
                  background: '#fff',
                  padding: '0 8px'
                }}
              >
                Filter By
              </Typography>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  padding: '16px',
                  marginTop: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    {Object.keys(filteredPurchaserList).length > 0 && (
                      <FormControl fullWidth>
                        <InputLabel size='small' id="purchaser-select-label">Purchaser Name</InputLabel>
                        <Select
                          labelId="purchaser-select-label"
                          multiple
                          label="Purchaser Name"
                          value={filterSortCriteria.purchaser_id}
                          onChange={handlePurchaserSelectionChange}
                          size="small"
                          renderValue={(selected) => selected.length === 1 ? filteredPurchaserList[selected[0]] :
                            selected.length === 0 ? '' : `${selected.length} purchasers selected`
                          }
                        >
                          {Object.keys(filteredPurchaserList).map((purchaserId) => (
                            <MenuItem key={purchaserId} value={purchaserId}>
                              <Checkbox checked={filterSortCriteria.purchaser_id.includes(purchaserId)} />
                              <ListItemText primary={filteredPurchaserList[purchaserId]} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {(filteredPlanList.length > 0 && reportDetails.report_option !=2) && (
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="plan-select-label">Plan Id</InputLabel>
                        <Select
                          labelId="plan-select-label"
                          label="Plan Id"
                          multiple
                          value={filterSortCriteria.plan_id}
                          onChange={handlePlanSelectionChange}
                          size="small"
                          renderValue={(selected) =>
                            selected.length === 1 ? selected[0] :
                              selected.length === 0 ? '' : `${selected.length} PlanIds selected`
                          }
                        >
                          {filteredPlanList.map((planId) => (
                            <MenuItem key={planId} value={planId}>
                              <Checkbox checked={filterSortCriteria.plan_id.includes(planId)} />
                              <ListItemText primary={planId} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item container xs={12} sm={reportDetails.report_option == 2 ? 8 : 4} justifyContent="flex-end" alignItems="flex-end">
                    <Grid item sx={{ mr: 2 }}>
                      <Button
                        size="small"
                        color="primary"
                        onClick={handleClearFilterClick}
                        sx={{ borderRadius: 15, fontSize: '13px' }}
                      >
                        Clear
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleFilterOrderReportsApplyClick}
                        sx={{ borderRadius: 15, fontSize: '13px' }}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        ) : ''}
        {filteredReportData.length > 0 ? (
          <Grid item xs={12} sx={{ mt: 1 }}>
            {getTableData()}
          </Grid>
        ) :
          <Grid item xs={12} sx={{ my: 15 }}>
            <Typography variant='h6' display='flex' justifyContent='center' alignItems='center'>
              {getFilterReportDataMessage()}
            </Typography>
          </Grid>
        }
      </Grid>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default OrderReports;
