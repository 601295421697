import { AppBar, Backdrop, Button, Card, CircularProgress, Container, Dialog, Grid, IconButton,
  InputAdornment, Slide, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { blue } from '@mui/material/colors';
import { REPORTS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import DownloadIcon from '@mui/icons-material/Download';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { QrReader } from 'react-qr-reader';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ItemTimeline = () => {
  const [timelineData, setTimelineData] = useState({});
  const [selectedItemId, setSelectedItemId] = useState('');
  const [isScanStarted, setIsScanStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewAccessGranted, setIsViewAccessGranted] = useState(false);
  const APIToken = {
    GET_ITEM_TIMELINE: 'CIT01',
    GET_USER: 'CIT02'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getItemTimeline && apiToken == APIToken.GET_ITEM_TIMELINE ) {
      if (response.data != undefined) {
        setTimelineData(response.data);
      }
      setIsLoading(false);
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'REPORTS');
        if (filtered != undefined && filtered.length > 0) {
          const isViewAllowed = validateFeature(filtered[0].available_features, REPORTS_FEATURES.VIEW_STOCK);
          setIsViewAccessGranted(isViewAllowed);
          setIsLoading(false);
          if (!isViewAllowed) {
            showSnackBar('error', 'You don\'t have permission to view item timeline report');
          }
        }
      }
    }
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getItemTimeline && apiToken == APIToken.GET_ITEM_TIMELINE) {
      defaultMsg = 'Failed to retrieve item timeline data';
      setTimelineData({});
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const raiseGetItemTimelineData = (itemId) => {
    if (!validateStringForNull(itemId)) {
      showSnackBar('error', ' Invalid Item Id');
      return;
    }
    setIsLoading(true);
    const params = 'item_name=' + itemId;
    Services.sendBackendRequest({ apiData: APIData.getItemTimeline, params: params },
      APIToken.GET_ITEM_TIMELINE, processSuccess, processError );
  };

  const getItemDetails = (data, label, smValue) => {
    if (!validateStringForNull(data)) {
      return '';
    }
    return (
      <Grid item xs={6} sm={smValue} sx={{ mb: 2, borderLeft: '5px solid #205295' }}>
        <Typography
          variant='h5'
          sx={{
            textAlign: 'left',
            color: 'primary.main',
            wordWrap: 'break-word',
            fontSize: '14px',
            pl: 2
          }}
        >
          {data}
        </Typography>
        <Typography
          variant='h6'
          sx={{
            textAlign: 'left',
            color: '#000',
            fontSize: '10px',
            pl: 2
          }}
        >
          {label}
        </Typography>
      </Grid>
    );
  };

  const getTimelineIcon = (data) => {
    if (data.type == 'Purchase') {
      return <ShoppingCartIcon />;
    } else if (data.type == 'Sales Return') {
      return <MoneyOffIcon />;
    } else {
      return <AttachMoneyIcon />;
    }
  };

  const getTimelineUI = () => {
    return (
      <Timeline position="alternate">
        {timelineData.timeline.map((data, i) => (
          <TimelineItem key={i}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {data.trans_date.display}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary">
                {getTimelineIcon(data)}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                <strong>{data.type}</strong>
              </Typography>
              <Typography>{data.trans_no}</Typography>
              <Typography>Qty: {data.qty}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  };

  const handleScannerResult = (result) => {
    if (result != undefined && result.text != undefined) {
      setSelectedItemId(result.text.toUpperCase());
      setIsScanStarted(false);
      raiseGetItemTimelineData(result.text.toUpperCase());
    }
  };

  return (
    <Container maxWidth={false} sx={{ backgroundColor: 'white', p: 2 }}>
      <Grid container justifyContent='center'>
        <Grid item container xs={12} sm={8} lg={5} alignItems='center' display='flex' sx={{ pb: 2 }}>
          <Grid item xs={12} sx={{ pr: 1 }}>
            <TextField
              fullWidth
              disabled = {!isViewAccessGranted}
              name="item_id"
              size='small'
              label="Item Id"
              value={selectedItemId}
              onChange={(event) => setSelectedItemId(event.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end">
                    {isScanStarted == false ? (
                      <Tooltip title='Scan Barcode'>
                        <IconButton tabIndex={-1}
                          onMouseDown={(e) => {
                            setIsScanStarted(true);
                            e.preventDefault();
                          }}
                        >
                          <QrCode2Icon/>
                        </IconButton>
                      </Tooltip>
                    ) : ''}
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      startIcon={<DownloadIcon/>}
                      onClick={(e) => {
                        e.preventDefault();
                        raiseGetItemTimelineData(selectedItemId);
                      }}
                    >
                      Get
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        {Object.keys(timelineData).length > 0 ? (
          <Grid item xs={12} lg={8} sx={{ mt: 1 }}>
            <Grid container spacing={4} justifyContent='center'>
              <Grid item container xs={12} sm={3.5} justifyContent='flex-start' rowGap={1}>
                <Card elevation={6} sx={{ p: 2, width: '100%' }} >
                  <Grid item container xs={12}>
                    {getItemDetails(timelineData.bill, 'Bill', 12)}
                    {getItemDetails(timelineData.bill_date?.display || '', 'Bill Date', 12)}
                    {getItemDetails(timelineData.stock_name, 'Stock', 12)}
                  </Grid>
                </Card>
              </Grid>
              <Grid item container xs={12} sm={3.5} rowGap={1} justifyContent='flex-start'>
                <Card elevation={6} sx={{ p: 2, width: '100%' }}>
                  <Grid item container xs={12} >
                    {getItemDetails(timelineData.prod_name, 'Product', 12)}
                    {getItemDetails(timelineData.cate_name, 'Category', 6)}
                    {getItemDetails(timelineData.brand_name, 'Brand', 6)}
                    {getItemDetails(timelineData.model_name, 'Model', 6)}
                    {getItemDetails(timelineData.size_id, 'Size', 6)}
                  </Grid>
                </Card>
              </Grid>
              <Grid item container xs={12} sm={5} rowGap={1} justifyContent='flex-start'>
                <Card elevation={6} sx={{ p: 2, width: '100%' }}>
                  <Grid item container xs={12}>
                    {getItemDetails(timelineData.ac_name, 'Party', 6)}
                    {getItemDetails(timelineData.place, 'Place', 6)}
                    {getItemDetails(timelineData.gstin, 'GSTIN', 6)}
                    {getItemDetails(timelineData.prrate?.display || '', 'Purchase Rate', 6)}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        ): ''}
        {Object.keys(timelineData).length > 0 && timelineData.timeline != undefined ? (
          <Grid item xs={12} justifyContent='center' display='flex'>
            {getTimelineUI()}
          </Grid>
        ): ''}
      </Grid>
      <Dialog
        fullScreen
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }
        }}
        open={isScanStarted}
        TransitionComponent={Transition}
        onClose={() => {}}
        PaperProps={{
          sx: {
            width: {
              lg: '50%'
            }
          }
        }}
      >
        <AppBar position='sticky'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsScanStarted(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Scan Item
            </Typography>
          </Toolbar>
        </AppBar>
        <QrReader
          constraints= {{
            facingMode: 'environment',
            torch: false,
            autoFocus: true
          }}
          scanDelay={1}
          onResult={(result) => {
            if (result) {
              handleScannerResult(result);
            }
          }}
          containerStyle={{ width: '100%', height: '65vh' }}
          videoContainerStyle={{ height: '100%', width: 'auto', padding: '0%' }}
          videoStyle={{ objectFit: 'cover' }}
        />
      </Dialog>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </Container>
  );
};

export default ItemTimeline;
