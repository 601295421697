import React, { useEffect, useState } from 'react';
import Services from '../../../utils/Services';
import { CookieUtils } from '../../../utils/UtilFunctions';
import APIData from '../../../utils/APIData';
import { ORDERS_FEATURES, validateFeature } from '../../../utils/FeatureValidator';
import { Backdrop, Box, Card, CardMedia, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { blue } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

const OrdersHome = () => {
  const [allowedOrderApps, setAllowedOrderApps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [clientMsg, setClientMsg] = useState('Loading');

  const APIToken = {
    GET_USER: 'POH01'
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);


  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filteredApps = [];
        const filtered = response.data.app_list.filter((app) => app.app_id == 'ORDERS');
        if (filtered != undefined && filtered.length > 0) {
          const isViewPlanAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.VIEW_PLAN);
          const isViewOrderAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.VIEW_ORDERS);
          const isCreateEntityAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.CREATE_ENTITY);
          const isAnalysisAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.ANALYSIS);

          if (isViewPlanAllowed) {
            filteredApps.push({ app_name: 'Planning', path: '/orders/planning', img_name: 'order_planning' });
          }
          if (isViewPlanAllowed && isViewOrderAllowed) {
            filteredApps.push({ app_name: 'Booking', path: '/orders/booking', img_name: 'order_booking' });
          }
          if (isViewOrderAllowed) {
            filteredApps.push({ app_name: 'Direct Booking', path: '/orders/direct-booking', img_name: 'order_direct_booking' });
          }
          if (isCreateEntityAllowed) {
            filteredApps.push({ app_name: 'Create Entities', path: '/orders/new-entities', img_name: 'order_entities' });
          }
          if (isAnalysisAllowed) {
            filteredApps.push({ app_name: 'Analysis', path: '/orders/order-analysis', img_name: 'order_analysis' });
            filteredApps.push({ app_name: 'Reports', path: '/orders/order-report', img_name: 'order_report' });
          }
        }
        if (filtered[0].role == 1) {
          filteredApps.push({ app_name: 'Jewellery', path: '/orders/jewellery', img_name: 'order_report' });
        }
        if (filteredApps.length == 0 ) {
          setClientMsg('You don\'t have permission to access order apps. Contact Administrator');
        }
        setAllowedOrderApps(filteredApps);
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    setClientMsg('Unable to retrieve allowed app list');
    setIsLoading(false);
  };

  const handleAppClick = (app) => {
    navigate(app.path);
  };

  const getCardImage = (imgName) => {
    return '/static/images/' + imgName + '.png';
  };

  const getAppsCards = () => {
    return (
      <Container sx={{ py: 10 }}>
        <Grid sx={{ py: 2, pb: 5 }} container spacing={4} >
          {allowedOrderApps.map((app) => (
            <Grid item key={app.app_name} xs={12} sm={6} md={4} lg={3}>
              <Card
                onClick={() => handleAppClick(app)}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 3,
                  backgroundImage: 'linear-gradient(45deg, #144272, #2C74B3)',
                  '&:hover': {
                    backgroundPosition: 'right'
                  },
                  backgroundSize: '150%',
                  transition: '0.6s'
                }}>
                <Grid container sx={{ width: '100%', p: 1 }}>
                  <Grid item container xs={6} display='flex' alignItems='center'>
                    <Grid item xs={12} alignItems='flex-end' display='flex' sx={{ pl: 1 }}>
                      <Typography color='white' variant='h5'><strong>{app.app_name}</strong></Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
                    <CardMedia
                      component="img"
                      sx={{ width: 100, height: 100, m: 1 }}
                      image={getCardImage(app.img_name)}
                      alt={app.app_name}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };

  const getNoAppsCard = () => {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ pt: 25 }}
      >
        <Grid item>
          <Typography
            align="center"
            color="textPrimary"
            variant="h5"
          >
            {isLoading ? 'Loading... ' : clientMsg}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getApps = () => {
    if (allowedOrderApps == undefined) {
      return <React.Fragment/>;
    } else if (allowedOrderApps.length > 0) {
      return getAppsCards();
    } else {
      return getNoAppsCard();
    }
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '90vh'
        }}>
        <Helmet>
          <title>Home | Order Apps</title>
        </Helmet>
        {getApps()}
        <Backdrop open={isLoading}>
          <CircularProgress style= {{ color: blue[200] }} />
        </Backdrop>
      </Box>
    </React.Fragment>
  );
};

export default OrdersHome;
