import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, TextField, Card, IconButton, Divider, Button, Checkbox, Typography } from '@mui/material';
import { Utils } from '../../../../utils/UtilFunctions';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import { validateStringForNull } from '../../../../utils/FieldValidations';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { cloneDeep } from 'lodash';

const FilterPlanDetails = ({ selectedPlan, onAddOrderClick }) => {
  const [planDetails, setPlanDetails] = useState({
    plan_id: '',
    plan_date: '',
    plan_tag: '',
    plan_data: [],
    refresh_id: 0
  });

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    if (selectedPlan != undefined) {
      populatePlanData(selectedPlan);
    }
  }, []);

  const notifyPlanDetailsChange = () => {
    setPlanDetails({ ...planDetails, refresh_id: planDetails.refresh_id + 1 });
  };

  const populatePlanData = (data) => {
    const obj = {};
    data.plan_data.forEach((plan) => {
      const materialType = MATERIAL_TYPE[plan.material_type];
      if (materialType == 3 ) { // Readymade
        const key = `${plan.item_id}_${plan.cate_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: plan.cate_name,
            cate_id: plan.cate_id,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            is_expanded: true,
            checked: false,
            indeterminate: false,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          serial: plan.serial,
          model_name: plan.model_name,
          brand_name: plan.brand_name,
          model_id: plan.model_id,
          brand_id: plan.brand_id,
          min_price: plan.min_price,
          max_price: plan.max_price,
          quantity: plan.quantity,
          color_name: plan.color_name,
          color_id: plan.color_id,
          size_id: plan.size_id,
          checked: false
        });
      } else { // Silk and Polyester and cutPiece
        const key = `${plan.item_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            is_expanded: true,
            checked: false,
            indeterminate: false,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          serial: plan.serial,
          max_price: plan.max_price,
          min_price: plan.min_price,
          quantity: plan.quantity,
          model_name: plan.model_name,
          brand_name: plan.brand_name,
          model_id: plan.model_id,
          brand_id: plan.brand_id,
          checked: false
        });
      }
    });
    const filteredData = Object.values(obj);
    setPlanDetails({
      ...planDetails,
      plan_id: data.plan_id,
      plan_date: validateStringForNull(data.plan_date) ? data.plan_date.display : '',
      plan_tag: validateStringForNull(data.plan_tag) ? data.plan_tag: '',
      plan_data: filteredData
    });
  };

  const handleShowPlanDetails = (id) => {
    setPlanDetails((prevPlanDetails) => ({
      ...prevPlanDetails,
      plan_data: prevPlanDetails.plan_data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_expanded: !item.is_expanded
          };
        }
        return item;
      })
    }));
  };

  const getMaterialContent = (plan) => {
    switch (plan.material_type) {
    case 1:
    case 2:
    case 4:
      return getSilkPolyesterAndCutPieceContent(plan);
    case 3:
      return getReadyMadeContent(plan);
    default:
      return 'Invalid Material type';
    }
  };

  const handleAddToOrderClick = () => {
    const selectedPlans = [];
    planDetails.plan_data.map((plan) => {
      if (plan.checked) {
        selectedPlans.push(plan);
      } else if (plan.indeterminate) {
        const planObj = cloneDeep(plan);
        planObj.sub_data = plan.sub_data.filter((p)=> p.checked == true);
        selectedPlans.push(planObj);
      }
    });
    if (selectedPlans.length == 0) {
      showSnackBar('warning', 'Select atleast 1 plan to add');
    } else {
      onAddOrderClick(selectedPlans);
    }
  };

  const handlePlanCheckboxChange = (checked, plan) => {
    plan.checked = checked;
    plan.indeterminate = false;
    plan.sub_data.map((p) => p.checked = checked);
    notifyPlanDetailsChange();
  };

  const handleSubDataCheckboxChange = (checked, plan, subData) => {
    subData.checked = checked;
    plan.checked = plan.sub_data.every((p) => p.checked == true);
    plan.indeterminate = !plan.checked && plan.sub_data.some((p) => p.checked == true);
    notifyPlanDetailsChange();
  };

  const getSilkPolyesterAndCutPieceContent = (plan) => {
    return (
      <Grid item xs={12} key={plan.id}>
        <Card elevation={12}>
          <Grid container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={1.5} sm={1}>
                <Checkbox
                  checked={plan.checked}
                  indeterminate={plan.indeterminate}
                  onChange={(e) => handlePlanCheckboxChange(e.target.checked, plan)}
                />
              </Grid>
              <Grid item xs={2.5} sm={1.5}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={8} sm={4}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  value={plan.prod_id}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={10.5} sm={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  value={plan.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              {planDetails.plan_data.length != 0 ? (
                <Grid item xs={1.5} sm={1} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  <IconButton color='primary' onClick={() => handleShowPlanDetails(plan.id)}>
                    {plan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                  </IconButton>
                </Grid>
              ) : ''}
            </Grid>
            {plan.is_expanded == true ? plan.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={1.5} sm={1}>
                  <Checkbox
                    checked={planSubData.checked}
                    onChange={(e) => handleSubDataCheckboxChange(e.target.checked, plan, planSubData)}
                  />
                </Grid>
                <Grid item xs={5.25} sm={2.5}>
                  <TextField
                    id="Brand Name"
                    label="Brand Name"
                    name="brand_name"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.brand_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5.25} sm={2.5}>
                  <TextField
                    id="Model Name"
                    label="Model Name"
                    name="model_name"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.model_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4.5} sm={2.3}>
                  <TextField
                    id="min_price"
                    label="Min Price"
                    name="min_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.min_price}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4.5} sm={2.2}>
                  <TextField
                    id="max_price"
                    label="Max Price"
                    name="max_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.max_price}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} sm={1.5}>
                  <TextField
                    id="quantity"
                    label="Quantity"
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.quantity}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getReadyMadeContent = (plan) => {
    return (
      <Grid item xs={12} key={plan.id}>
        <Card elevation={12}>
          <Grid key={plan.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={1.5} sm={1}>
                <Checkbox
                  checked={plan.checked}
                  indeterminate={plan.indeterminate}
                  onChange={(e) => handlePlanCheckboxChange(e.target.checked, plan)}
                />
              </Grid>
              <Grid item xs={2.5} sm={1.5} alignContent='center'>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={8} sm={4.3}>
                <TextField
                  id="category Name"
                  label="Category Name"
                  name="cate_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.cate_name}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              <Grid item xs={10.5} sm={4.2}>
                <TextField
                  id="product Name"
                  label="Product Name"
                  name="prod_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.prod_name}
                  disabled={true}
                  size='small'
                  fullWidth
                />
              </Grid>
              {planDetails.plan_data.length != 0 ? (
                <Grid item xs={1.5} sm={1} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  <IconButton color='primary' onClick={() => handleShowPlanDetails(plan.id)} >
                    {plan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                  </IconButton>
                </Grid>
              ) : ''}
            </Grid>
            {plan.is_expanded == true ? plan.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={1.5} sm={1}>
                  <Checkbox
                    checked={planSubData.checked}
                    onChange={(e) =>
                      handleSubDataCheckboxChange(e.target.checked, plan, planSubData)
                    }
                  />
                </Grid>
                <Grid item xs={5.25} sm={3.7}>
                  <TextField
                    id="Brand Name"
                    label="Brand Name"
                    name="brand_name"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.brand_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5.25} sm={3.7}>
                  <TextField
                    id="Model Name"
                    label="Model Name"
                    name="model_name"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.model_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8} sm={3.6}>
                  <TextField
                    id="Color Name"
                    label="Color Name"
                    name="color_name"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.color_name}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={3}>
                  <TextField
                    id="size"
                    label="Size"
                    name="size_id"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.size_id}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4.5} sm={3}>
                  <TextField
                    id="min_price"
                    label="Min Price"
                    name="min_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.min_price}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4.5} sm={3}>
                  <TextField
                    id="max_price"
                    label="Max Price"
                    name="max_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.max_price}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <TextField
                    id="quantity"
                    label="Quantity"
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.quantity}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
      <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="Plan Id"
            label="Plan Id"
            name="plan_id"
            autoComplete ='off'
            value={planDetails.plan_id}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6.5}>
          <TextField
            id="Plan tag"
            label="Plan Tag"
            name="plan_tag"
            autoComplete ='off'
            value={planDetails.plan_tag}
            size='small'
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <TextField
            id="Plan Date"
            label="Plan Date"
            name="plan_date"
            autoComplete ='off'
            value={planDetails.plan_date}
            size='small'
            disabled={true}
            fullWidth
          />
        </Grid>
        {planDetails.plan_data.length > 0 ?
          planDetails.plan_data.map((plan, index) => (
            <React.Fragment key={index}>
              {getMaterialContent(plan)}
            </React.Fragment>
          )) : '' }
        {planDetails.plan_data.length > 0 ? (
          <Grid item xs={12} display='flex' justifyContent='flex-end'>
            <Button
              variant="contained"
              onClick={handleAddToOrderClick}
            >
                Add To Orders
            </Button>
          </Grid>
        ) :
          <Grid item xs={12} sx={{ mt: 15 }}>
            <Typography textAlign='center'>
              No Plan Found.!
            </Typography>
          </Grid>
        }
      </Grid>
      {getSnackbar}
    </Container>
  );
};

FilterPlanDetails.propTypes = {
  selectedPlan: PropTypes.object,
  onAddOrderClick: PropTypes.func
};

export default FilterPlanDetails;
