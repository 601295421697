import { Backdrop, Button, Card, Chip, CircularProgress, Divider, FormControl, Grid,
  IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateNumber, validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import { ORDERS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator';

const OrdersPlanning = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [isViewAccessGranted, setIsViewAccessGranted] = useState(false);
  const [isCreateAccessGranted, setIsCreateAccessGranted] = useState(false);
  const [markList, setMarkList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const APIToken = {
    GET_MARK_LIST: 'COPO1',
    GET_PLAN_LIST: 'COP02',
    GET_PLAN_DETAILS: 'COP03',
    GET_PRODUCT_ID: 'COP04',
    CREATE_PLAN: 'COP05',
    UPDATE_PLAN: 'COP06',
    GET_USER: 'COP07'
  };

  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [planDetails, setPlanDetails] = useState({
    plan_id: '',
    plan_date: '',
    plan_tag: '',
    plan_data: [],
    refresh_id: 0
  });
  const [originalValue, setOriginalValue] = useState(planDetails);

  const readymadeDefaultSubObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    size_id: '',
    color_id: '',
    color_name: '',
    brand_name: '',
    min_price: '',
    max_price: '',
    quantity: ''
  };
  const readymadeDefaultObject = {
    id: '',
    cate_id: '',
    cate_name: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: [readymadeDefaultSubObject]
  };

  const silkPolyesterCutPieceDefaultSubObject = {
    id: '',
    min_price: '',
    max_price: '',
    quantity: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: ''
  };

  const silkPolyesterCutPieceDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: [silkPolyesterCutPieceDefaultSubObject]
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      if (response.data.mark_list != undefined && response.data.mark_list.length > 0) {
        const filteredMark = response.data.mark_list.filter((mark) => mark.shop_id === response.data.mark_list[0].shop_id);
        setMarkList(filteredMark);
      }
      raiseGetPlanListRequest(callbackValues.isCreateAllowed);
    } else if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST ) {
      if (response.data != undefined) {
        const updatedPlanList = [
          ...response.data,
          { plan_id: '-- NEW --', plan_date: moment().format( 'DD-MM-YYYY') }
        ];
        setPlanList(callbackValues.isCreateAllowed ? updatedPlanList : response.data);
        const filteredObject = callbackValues.isCreateAllowed ? updatedPlanList.find((obj) => obj.plan_id == '-- NEW --') : {};
        setPlanDetails({ ...planDetails,
          plan_id: validateStringForNull(filteredObject.plan_id) ? filteredObject.plan_id : '',
          plan_date: validateStringForNull(filteredObject.plan_date) ? filteredObject.plan_date : ' ',
          plan_tag: '',
          plan_data: []
        });
        setIsUpdateRequest(false);
      } else {
        showSnackBar('error', response.message ?? 'Unable to retrieve plan list');
      }
    } else if (apiData == APIData.getPlanDetails && apiToken == APIToken.GET_PLAN_DETAILS) {
      if (response.data != undefined) {
        setIsUpdateRequest(true);
        populatePlanData(response.data);
      } else {
        showSnackBar('error', response.message ?? 'Unable to retrieve plan details');
      }
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      setProductName(callbackValues.id, response.data);
    } else if (apiData == APIData.createPlan && apiToken == APIToken.CREATE_PLAN) {
      showSnackBar('success', response.message ?? 'Plan Created successfully');
      raiseGetPlanListRequest(callbackValues.isCreateAllowed);
    } else if (apiData == APIData.updatePlan && apiToken == APIToken.UPDATE_PLAN) {
      showSnackBar('success', response.message ?? 'Plan Updated Successfully');
      if (validateStringForNull(callbackValues.plan_id)) {
        raiseGetPlanRequest(callbackValues.plan_id);
      }
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'ORDERS');
        if (filtered != undefined && filtered.length > 0) {
          const isAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.VIEW_PLAN);
          const isCreateAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.CREATE_OR_UPDATE_PLAN);
          setIsViewAccessGranted(isAllowed);
          setIsCreateAccessGranted(isCreateAllowed);
          if (!isAllowed) {
            showSnackBar('error', 'You don\'t have permission to view Order Plan Details');
          } else if (validateStringForNull(purchaserId)) {
            raiseGetMarkListRequest(isCreateAllowed);
            return;
          }
        }
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST) {
      defaultMsg = 'Failed to retrieve mark list';
    } else if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST) {
      defaultMsg = 'Failed to retrieve plan list';
    } else if (apiData == APIData.getPlanDetails && apiToken == APIToken.GET_PLAN_DETAILS) {
      defaultMsg = 'Failed to Get plan details';
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      defaultMsg = 'Failed to retrieve Product Details';
    } else if (apiData == APIData.createPlan && apiToken == APIToken.CREATE_PLAN) {
      defaultMsg = 'Failed to create plan Details';
    } else if (apiData == APIData.updatePlan && apiToken == APIToken.UPDATE_PLAN) {
      defaultMsg = 'Failed to update plan Details';
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const raiseGetMarkListRequest = (isCreateAllowed) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getMarkListForOrders },
      APIToken.GET_MARK_LIST, processSuccess, processError, { isCreateAllowed: isCreateAllowed });
  };

  const raiseGetPlanListRequest = (isCreateAllowed) => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getPlanList, params: params },
      APIToken.GET_PLAN_LIST, processSuccess, processError, { isCreateAllowed: isCreateAllowed });
  };

  const raiseGetPlanRequest = (planId) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getPlanDetails, params: params },
      APIToken.GET_PLAN_DETAILS, processSuccess, processError);
  };

  const raiseGetProductDetailsRequest = (id, itemId, productId) => {
    setIsLoading(true);
    const params = 'item_id=' +itemId +'&prod_id=' + productId;
    Services.sendBackendRequest({ apiData: APIData.getProductId, params: params },
      APIToken.GET_PRODUCT_ID, processSuccess, processError, { id: id });
  };

  const populatePlanData = (data) => {
    const obj = {};

    data.plan_data.forEach((plan) => {
      const materialType = MATERIAL_TYPE[plan.material_type];
      if (materialType == 3 ) { // Readymade
        const key = `${plan.item_id}_${plan.cate_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: plan.cate_name,
            cate_id: plan.cate_id,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          model_name: plan.model_name,
          brand_name: plan.brand_name,
          model_id: plan.model_id,
          brand_id: plan.brand_id,
          min_price: plan.min_price,
          max_price: plan.max_price,
          quantity: plan.quantity,
          size_id: plan.size_id,
          color_id: plan.color_id,
          color_name: plan.color_name
        });
      } else { // Silk and Polyester & CutPiece
        const key = `${plan.item_id}_${plan.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: plan.prod_id,
            prod_name: plan.prod_name,
            item_id: plan.item_id,
            material_type: materialType,
            mark_name: plan.mark_name,
            is_expanded: true,
            sub_data: []
          };
        }
        obj[key].sub_data.push({
          id: obj[key].sub_data.length + 1,
          max_price: plan.max_price,
          min_price: plan.min_price,
          quantity: plan.quantity,
          model_name: plan.model_name,
          brand_name: plan.brand_name,
          model_id: plan.model_id,
          brand_id: plan.brand_id
        });
      }
    });

    const filteredData = Object.values(obj);
    const planObj = {
      plan_id: data.plan_id,
      plan_date: validateStringForNull(data.plan_date) ? data.plan_date.display : '',
      plan_tag: validateStringForNull(data.plan_tag) ? data.plan_tag : '',
      plan_data: filteredData,
      refresh_id: 0
    };

    setPlanDetails(planObj);
    setOriginalValue(cloneDeep(planObj));
  };

  const handleProductDetailsSearch = (id, itemId, prodId) => {
    if (validateStringForNull(prodId) && validateStringForNull(itemId) ) {
      raiseGetProductDetailsRequest(id, itemId, prodId);
    }
  };

  const notifyPlanDetailsChange = () => {
    setPlanDetails({ ...planDetails, refresh_id: planDetails.refresh_id + 1 });
  };

  const handlePlanChange = (event) => {
    const { name, value } = event.target;
    const filteredPlan = planList.find((plan) => plan.plan_id === value);
    if (filteredPlan) {
      setPlanDetails({ ...planDetails, [name]: value, plan_data: [], plan_date: moment().format( 'DD-MM-YYYY') });
      if (filteredPlan.plan_id != '-- NEW --') {
        raiseGetPlanRequest(value);
      } else {
        setIsUpdateRequest(false);
      }
    }
  };

  const setProductName = (id, response) => {
    const dataObj= planDetails.plan_data.find((item) => item.id === id);
    if (dataObj != undefined && response != undefined) {
      const { prod_id: prodId, prod_name: prodName } = response;
      dataObj.prod_id = prodId;
      dataObj.prod_name = prodName;
      notifyPlanDetailsChange();
    }
  };

  const handleAutoCompleteChange = (value, data, idField, nameField) => {
    data[idField] = value != null ? value[idField] : '';
    data[nameField] = value != null ? value[nameField] : '';
    if (idField === 'cate_id') {
      data.prod_id = '';
      data.prod_name = '';
    }
    notifyPlanDetailsChange();
  };

  const handleAddSubDataClick = (data) => {
    const lastId = data.sub_data[data.sub_data.length - 1].id;
    const newSubObj = data.material_type == 3 ? readymadeDefaultSubObject : silkPolyesterCutPieceDefaultSubObject;
    newSubObj.id = lastId + 1;
    data.sub_data.push(newSubObj);
    notifyPlanDetailsChange();
  };

  const handleRemoveSubDataClick = (data, subDataId) => {
    data.sub_data = data.sub_data.filter((subItem) => subItem.id !== subDataId);
    notifyPlanDetailsChange();
  };

  const handleAddMarkClick = (mark) => {
    const material = MATERIAL_TYPE[mark.material_type];
    const lastIdData = planDetails.plan_data.length > 0 ? planDetails.plan_data[planDetails.plan_data.length - 1].id : 0;
    const newSubObj = material == 3 ? readymadeDefaultSubObject : silkPolyesterCutPieceDefaultSubObject;
    newSubObj.id = 1;
    const newObj = material == 3 ? readymadeDefaultObject : silkPolyesterCutPieceDefaultObject;
    newObj.id = lastIdData + 1;
    newObj.sub_data = [newSubObj];
    newObj.item_id = mark.item_id;
    newObj.material_type = material;
    newObj.mark_name = mark.mark_name;
    planDetails.plan_data.push(newObj);
    notifyPlanDetailsChange();
  };

  const handleAddDataClick = (plan) => {
    const newSubObj = plan.material_type == 3 ? { ...readymadeDefaultSubObject } : { ...silkPolyesterCutPieceDefaultSubObject };
    newSubObj.id = 1;
    let newObj;

    if (plan.material_type == 3) {
      const defaultObj = cloneDeep(readymadeDefaultObject);
      newObj = {
        ...defaultObj,
        item_id: plan.item_id,
        material_type: plan.material_type,
        mark_name: plan.mark_name,
        prod_id: plan.prod_id,
        prod_name: plan.prod_name,
        cate_id: plan.cate_id,
        cate_name: plan.cate_name,
        id: planDetails.plan_data.length > 0 ? planDetails.plan_data[planDetails.plan_data.length - 1].id + 1 : 1,
        sub_data: [cloneDeep(newSubObj)]
      };
    } else {
      const defaultObj = cloneDeep(silkPolyesterCutPieceDefaultObject);
      newObj = {
        ...defaultObj,
        item_id: plan.item_id,
        material_type: plan.material_type,
        mark_name: plan.mark_name,
        prod_id: plan.prod_id,
        prod_name: plan.prod_name,
        id: planDetails.plan_data.length > 0 ? planDetails.plan_data[planDetails.plan_data.length - 1].id + 1 : 1,
        sub_data: [cloneDeep(newSubObj)]
      };
    }
    planDetails.plan_data.push(newObj);
    notifyPlanDetailsChange();
  };

  const handleRemoveDataClick = (id) => {
    planDetails.plan_data = planDetails.plan_data.filter((item) => item.id !== id);
    notifyPlanDetailsChange();
  };

  const handleProductIdChange = (event, data) => {
    const { name, value } = event.target;
    data[name] = value.toUpperCase();
    if (!validateStringForNull(value)) {
      data.prod_name = '';
    }
    notifyPlanDetailsChange();
  };

  const handleTextChange = (event) => {
    setPlanDetails({ ...planDetails, [event.target.name]: event.target.value.toUpperCase() });
  };

  const getProductNameSearchParams = (cateId) => {
    if (validateStringForNull(cateId)) {
      return 'cate_id='+ cateId;
    }
    return '';
  };

  const getModelNameSearchParams = (prodId, brandId) => {
    if (validateStringForNull(prodId) && validateStringForNull(brandId)) {
      return 'prod_id='+ prodId+'&brand_id='+ brandId;
    }
    return '';
  };

  const validatePlanDataArray = (params) => {
    const validatedPlans = params.plan_data.map((plan, index) => {
      if (plan.material_type == 3) {
        return validateReadyMadePlanParams(plan);
      } else {
        return validateSilkPolysterAndCutPiecePlanParams(plan);
      }
    });
    return validatedPlans;
  };

  const handleCreateOrUpdatePlanRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(planDetails);
      const filteredParamsArray = validatePlanDataArray(params).flat();

      const filteredParams = {
        ...validatePlanDetails(params),
        plan_data: filteredParamsArray
      };
      raiseCreateOrUpdatePlanRequest(filteredParams, isCreateAccessGranted);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Plan' : 'Failed to Create Plan'));
      setIsLoading(false);
    }
  };

  const validatePlanDetails = (params) => {
    const finalParams = {};
    if (isUpdateRequest) {
      if (!validateStringForNull(params.plan_id)) {
        throw new Error('Select valid PlanId');
      }
      finalParams.plan_id = params.plan_id;
    }
    if (!validateStringForNull(params.plan_date)) {
      throw new Error('Select valid PlanDate');
    }
    if (validateStringForNull(params.plan_tag)) {
      finalParams.plan_tag = params.plan_tag;
    }
    finalParams.plan_date = params.plan_date;

    return finalParams;
  };

  const validateReadyMadePlanParams = (plan) => {
    if (!validateStringForNull(plan.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(plan.cate_id)) {
      throw new Error('Select valid Category');
    }
    if (!validateStringForNull(plan.prod_id)) {
      throw new Error('Select valid Product');
    }
    const planDataArray = [];
    plan.sub_data.map((subData) => {
      if (!validateStringForNull(subData.min_price)) {
        throw new Error('Provide valid Min Price');
      }
      if (!validateStringForNull(subData.max_price)) {
        throw new Error('Provide valid Max Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Quantity');
      }
      const obj = {
        cate_id: plan.cate_id,
        prod_id: plan.prod_id,
        item_id: plan.item_id,
        min_price: parseFloat(subData.min_price),
        max_price: parseFloat(subData.max_price),
        quantity: parseInt(subData.quantity)
      };
      if (validateStringForNull(subData.model_id)) {
        obj.model_id = subData.model_id;
      }
      if (validateStringForNull(subData.brand_id)) {
        obj.brand_id = subData.brand_id;
      }
      if (validateStringForNull(subData.size_id)) {
        obj.size_id = subData.size_id;
      }
      if (validateStringForNull(subData.color_id)) {
        obj.color_id = subData.color_id;
      }
      planDataArray.push(obj);
    });

    return planDataArray;
  };

  const validateSilkPolysterAndCutPiecePlanParams = (plan) => {
    if (!validateStringForNull(plan.prod_id)) {
      throw new Error('Select valid Product');
    }
    if (!validateStringForNull(plan.item_id)) {
      throw new Error('Select valid Mark');
    }

    const planDataArray = [];
    plan.sub_data.map((subData) => {
      if (!validateStringForNull(subData.min_price)) {
        throw new Error('Provide valid Min Price');
      }
      if (!validateStringForNull(subData.max_price)) {
        throw new Error('Provide valid Max Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Quantity');
      }

      const obj = {
        prod_id: plan.prod_id,
        item_id: plan.item_id,
        min_price: parseFloat(subData.min_price),
        max_price: parseFloat(subData.max_price),
        quantity: parseInt(subData.quantity)
      };
      if (validateStringForNull(subData.model_id)) {
        obj.model_id = subData.model_id;
      }
      if (validateStringForNull(subData.brand_id)) {
        obj.brand_id = subData.brand_id;
      }
      planDataArray.push(obj);
    });

    return planDataArray;
  };

  const raiseCreateOrUpdatePlanRequest = (finalParams, isCreateAllowed) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updatePlan;
      apiToken = APIToken.UPDATE_PLAN;
    } else {
      apiData = APIData.createPlan;
      apiToken = APIToken.CREATE_PLAN;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError, { plan_id: finalParams.plan_id, isCreateAllowed: isCreateAllowed });
  };

  const handleShowPlanDetails = (data) => {
    data.is_expanded = !data.is_expanded;
    notifyPlanDetailsChange();
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setPlanDetails(cloned);
  };

  const handleSubDataNumberChange = (event, subData) => {
    const { name, value } = event.target;
    if (validateStringForNull(value) && !validateNumber(value) ) {
      return;
    }
    subData[name] = value == '' ? '' : Number(value);
    notifyPlanDetailsChange();
  };

  const handleSubDataTextChange = (event, subData) => {
    const { name, value } = event.target;
    subData[name] = value.toUpperCase();
    notifyPlanDetailsChange();
  };

  const getReadyMadeContent = (plan) => {
    return (
      <Grid item xs={12} key={plan.id}>
        <Card elevation={12}>
          <Grid key={plan.id} container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.5} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={8.5} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Category Name'}
                  isDisabled={!isCreateAccessGranted}
                  apiInfo={{
                    searchURI: APIData.getCategory,
                    searchField: 'cate_name',
                    idField: 'cate_id'
                  }}
                  defaultValue={{
                    cate_id: plan.cate_id,
                    cate_name: plan.cate_name
                  }}
                  onSelectionChange={(event, value) => handleAutoCompleteChange(value, plan, 'cate_id', 'cate_name')}
                />
              </Grid>
              <Grid item xs={8} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Product Name'}
                  isDisabled={!isCreateAccessGranted}
                  apiInfo={{
                    searchURI: APIData.getProductName,
                    searchField: 'prod_name',
                    idField: 'prod_id',
                    searchParams: getProductNameSearchParams(plan.cate_id)
                  }}
                  defaultValue={{
                    prod_id: plan.prod_id,
                    prod_name: plan.prod_name
                  }}
                  onSelectionChange={(event, value) => handleAutoCompleteChange(value, plan, 'prod_id', 'prod_name')}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton tabIndex={-1} disabled={!isCreateAccessGranted} onClick={() => (handleAddDataClick(plan))} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  disabled={!isCreateAccessGranted}
                  tabIndex={-1}
                  onClick={() => handleRemoveDataClick(plan.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton tabIndex={-1} color='primary' onClick={() => handleShowPlanDetails(plan)} >
                  {plan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {plan.is_expanded == true ? plan.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={3.5} lg={3.75}>
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Brand Name'}
                    apiInfo={{
                      searchURI: APIData.getBrand,
                      searchField: 'brand_name',
                      idField: 'brand_id'
                    }}
                    defaultValue={{
                      brand_id: planSubData.brand_id,
                      brand_name: planSubData.brand_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name')}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5} lg={3.75}>
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Model Name'}
                    apiInfo={{
                      searchURI: APIData.getModel,
                      searchField: 'model_name',
                      idField: 'model_id',
                      searchParams: getModelNameSearchParams(plan.prod_id, planSubData.brand_id)
                    }}
                    defaultValue={{
                      model_id: planSubData.model_id,
                      model_name: planSubData.model_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, planSubData, 'model_id', 'model_name')}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5} lg={3.5}>
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Color Name'}
                    apiInfo={{
                      searchURI: APIData.getColor,
                      searchField: 'color_name',
                      idField: 'color_id'
                    }}
                    defaultValue={{
                      color_id: planSubData.color_id,
                      color_name: planSubData.color_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, planSubData, 'color_id', 'color_name')}
                  />
                </Grid>
                <Grid item xs={5} sm={2} lg={2.5}>
                  <TextField
                    id="size"
                    label="Size"
                    name="size_id"
                    disabled={!isCreateAccessGranted}
                    autoComplete ='off'
                    value={planSubData.size_id}
                    variant='standard'
                    onChange={(event) => handleSubDataTextChange(event, planSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5} sm={3}>
                  <TextField
                    id="min_price"
                    disabled={!isCreateAccessGranted}
                    label="Min Price"
                    name="min_price"
                    autoComplete ='off'
                    variant='standard'
                    type='number'
                    value={planSubData.min_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5} sm={3}>
                  <TextField
                    id="max_price"
                    disabled={!isCreateAccessGranted}
                    label="Max Price"
                    name="max_price"
                    autoComplete ='off'
                    variant='standard'
                    type='number'
                    value={planSubData.max_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={5}sm={2.5}>
                  <TextField
                    id="quantity"
                    label="Quantity"
                    disabled={!isCreateAccessGranted}
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={planSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} sm={1.5} lg={1} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === plan.sub_data.length - 1 && (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddSubDataClick(plan)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {plan.sub_data.length > 1 ? (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleRemoveSubDataClick(plan, planSubData.id)}
                      color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getSilkPolyesterAndCutPieceContent = (plan) => {
    return (
      <Grid item xs={12} key={plan.id}>
        <Card elevation={12}>
          <Grid container>
            <Grid container item xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.5} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={plan.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295',
                    borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  disabled={!isCreateAccessGranted}
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={plan.prod_id}
                  onChange={(event) => handleProductIdChange(event, plan)}
                  size='small'
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(plan.id, plan.item_id, plan.prod_id)}
                />
              </Grid>
              <Grid item xs={8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={plan.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton tabIndex={-1} disabled={!isCreateAccessGranted} onClick={() => handleAddDataClick(plan)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  disabled={!isCreateAccessGranted}
                  tabIndex={-1}
                  onClick={() => handleRemoveDataClick(plan.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton tabIndex={-1} color='primary' onClick={() => handleShowPlanDetails(plan)}>
                  {plan.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {plan.is_expanded == true ? plan.sub_data.map((planSubData, j) => (
              <Grid container item xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={2.75} lg={2.5}>
                  <CustomAutocomplete
                    label={'Brand Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getBrand,
                      searchField: 'brand_name',
                      idField: 'brand_id'
                    }}
                    defaultValue={{
                      brand_id: planSubData.brand_id,
                      brand_name: planSubData.brand_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name')}
                  />
                </Grid>
                <Grid item xs={12} sm={2.75} lg={2.5}>
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Model Name'}
                    apiInfo={{
                      searchURI: APIData.getModel,
                      searchField: 'model_name',
                      idField: 'model_id',
                      searchParams: getModelNameSearchParams(plan.prod_id, planSubData.brand_id)
                    }}
                    defaultValue={{
                      model_id: planSubData.model_id,
                      model_name: planSubData.model_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleAutoCompleteChange(value, planSubData, 'model_id', 'model_name')}
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="min_price"
                    label="Min Price"
                    name="min_price"
                    disabled={!isCreateAccessGranted}
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.min_price}
                    type='number'
                    onChange={(event) => handleSubDataNumberChange(event, planSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <TextField
                    id="max_price"
                    label="Max Price"
                    disabled={!isCreateAccessGranted}
                    name="max_price"
                    autoComplete ='off'
                    variant='standard'
                    type='number'
                    value={planSubData.max_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={1.5}>
                  <TextField
                    id="quantity"
                    label="Quantity"
                    disabled={!isCreateAccessGranted}
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={planSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={1} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === plan.sub_data.length - 1 && (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddSubDataClick(plan)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {plan.sub_data.length > 1 ? (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleRemoveSubDataClick(plan, planSubData.id)}
                      color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getMaterialContent = (plan) => {
    switch (plan.material_type) {
    case 1:
    case 2:
    case 4:
      return getSilkPolyesterAndCutPieceContent(plan);
    case 3:
      return getReadyMadeContent(plan);
    default:
      return 'Invalid Material type';
    }
  };

  return (
    <React.Fragment>
      {isViewAccessGranted ? (
        <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
          <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant='h5' align='center'>
                Orders Planning
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth >
                <InputLabel size="small" id="plan-list-select-label"> Plan Id</InputLabel>
                <Select
                  labelId="plan-list-select-label"
                  id="plan-list-select"
                  label="Plan id"
                  name='plan_id'
                  value={planDetails.plan_id}
                  onChange={(event) => handlePlanChange(event)}
                  size="small"
                >
                  {planList.map((obj) => (
                    <MenuItem key={obj.plan_id} value={obj.plan_id}>
                      {obj.plan_id} {obj.plan_tag ? `- ${obj.plan_tag}` : ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="Plan Date"
                label="Plan Date"
                name="plan_date"
                autoComplete ='off'
                value={planDetails.plan_date}
                size='small'
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="Plan tag"
                label="Plan Tag"
                name="plan_tag"
                autoComplete ='off'
                value={planDetails.plan_tag}
                disabled={!isCreateAccessGranted}
                size='small'
                fullWidth
                onChange={(event) => {handleTextChange(event);}}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {planDetails.plan_data.length > 0 ?
              planDetails.plan_data.map((plan, index) => (
                <React.Fragment key={index}>
                  {getMaterialContent(plan)}
                </React.Fragment>
              )) : '' }
            {isCreateAccessGranted ? (
              <Grid item container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, mb: { xs: 1 } }}>
                  <Typography>
                    {planDetails.plan_data.length > 0 ? 'You can also add' : 'Select Mark'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {markList.map((mark) => (
                    <Chip
                      sx={{ m: 1 }}
                      key={mark.item_id}
                      label={mark.mark_name}
                      onClick={() => handleAddMarkClick(mark)}
                    />
                  ))}
                </Grid>
                {planDetails.plan_data.length > 0 ? (
                  <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                      type='submit'
                      variant="text"
                      onClick={handleResetClick}
                      sx={{ mr: 2 }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      variant='contained'
                      onClick={handleCreateOrUpdatePlanRequest}
                    >
                      {isUpdateRequest == true ? 'Update' : 'Create' }
                    </Button>
                  </Grid>
                ) : ''}
              </Grid>
            ) : ''}
          </Grid>
        </Container>
      ) : ''}
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </React.Fragment>
  );
};

export default OrdersPlanning;
