import React, { useState } from 'react';
import APIData from '../../../utils/APIData';
import { useEffect } from 'react';
import Services from '../../../utils/Services';
import { CookieUtils, Utils } from '../../../utils/UtilFunctions';
import CustomSnackbar from '../../../common/components/CustomSnackbar';
import { Backdrop, Box, Container, CircularProgress,
  FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Typography,
  Tab,
  Tabs,
  Button
} from '@mui/material';
import { validateStringForNull } from '../../../utils/FieldValidations';
import { APPROVALS_FEATURES, validateFeature } from '../../../utils/FeatureValidator';
import { Helmet } from 'react-helmet';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { node, value, index } = props;
  if (value == index) {
    return (node);
  } else {
    return <React.Fragment/>;
  }
}

TabPanel.propTypes = {
  node: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const Approvals = () => {
  const pageTitle = 'Approvals | Rajmahal Apps';
  const [isLoading, setIsLoading] = useState(false);
  const [approvalsModuleList, setApprovalsModuleList] = useState([]);
  const [approvalsModulesMetadata, setApprovalsModulesMetadata] = useState({});
  const [approvalsModulesUpdateMetadata, setApprovalsModulesUpdateMetadata] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [allowedFeatures, setAllowedFeatures] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedModuleDetails, setSelectedModuleDetails] = useState({
    module_id: '',
    module_name: ''
  });
  const [rows, setRows] = useState({
    yetToBeApproved: [],
    alreadyApproved: []
  });
  const APIToken = {
    GET_APPROVAL_MODULES_LIST: 'AP01',
    GET_APPROVAL_LIST: 'AP02',
    ACCEPT_APPROVAL: 'AP03',
    CANCEL_APPROVAL: 'AP04',
    GET_USER: 'AP05',
    GET_APPROVAL_METADATA: 'AP06'
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    };
  }

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };

  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );
  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getApprovalModulesList && apiToken == APIToken.GET_APPROVAL_MODULES_LIST ) {
      setApprovalsModuleList(response.data.modules);
      const obj = {};
      response.data.modules.map((module) => obj[module.module_id] = []);
      setApprovalsModulesMetadata(obj);
      setApprovalsModulesUpdateMetadata(obj);
    } else if (apiData == APIData.getApprovalMetadata && apiToken == APIToken.GET_APPROVAL_METADATA ) {
      setApprovalsModulesMetadata({ ...approvalsModulesMetadata, [callbackValues.moduleId]: getColumnConfig(response.data.display_columns) });
      setApprovalsModulesUpdateMetadata({ ...approvalsModulesUpdateMetadata, [callbackValues.moduleId]: response.data.update_columns ?? [] });
      raiseGetSelectedApprovalList(callbackValues.moduleId);
      return;
    } else if (apiData == APIData.getApprovalList && apiToken == APIToken.GET_APPROVAL_LIST ) {
      const addIdToRows = (data, approvalListId) => {
        if (data == undefined) {
          return [];
        } else {
          return data.map((row, index) => ({ id: `${approvalListId}_${index}`, ...row }));
        }
      };

      const yetToBeApprovedRows = addIdToRows(response.data.yet_to_be_approved, 'yetToBeApproved');
      const alreadyApprovedRows = addIdToRows(response.data.already_approved, 'alreadyApproved');

      setRows({
        yetToBeApproved: yetToBeApprovedRows,
        alreadyApproved: alreadyApprovedRows
      });
    } else if ((apiData == APIData.acceptApproval && apiToken == APIToken.ACCEPT_APPROVAL) ||
       (apiData == APIData.cancelApproval && apiToken == APIToken.CANCEL_APPROVAL) ) {
      showSnackBar('success', 'Approval Details Updated successfully');
      setSelectedRows([]);
      if (callbackValues.moduleId != undefined) {
        raiseGetSelectedApprovalList(callbackValues.moduleId);
      }
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'APPROVALS');
        if (filtered != undefined && filtered.length > 0) {
          setAllowedFeatures(filtered[0].available_features);
          if (!validateFeature(filtered[0].available_features, APPROVALS_FEATURES.LIST_APPROVALS)) {
            showSnackBar('error', 'You don\'t have permission to view approval list');
          } else {
            raiseGetApprovalsListRequest();
          }
        }
      }
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getApprovalModulesList && apiToken == APIToken.GET_APPROVAL_MODULES_LIST ) {
      defaultMsg = 'Failed to get Approvals Modules List';
    } else if (apiData == APIData.getApprovalMetadata && apiToken == APIToken.GET_APPROVAL_METADATA ) {
      defaultMsg = 'Failed to get Approvals Module metadata';
      setRows({
        yetToBeApproved: [],
        alreadyApproved: []
      });
    } else if (apiData == APIData.getApprovalList && apiToken == APIToken.GET_APPROVAL_LIST ) {
      defaultMsg = 'Failed to get Approval List';
      setRows({
        yetToBeApproved: [],
        alreadyApproved: []
      });
    } else if ((apiData == APIData.acceptApproval && apiToken == APIToken.ACCEPT_APPROVAL) ||
    (apiData == APIData.cancelApproval && apiToken == APIToken.CANCEL_APPROVAL) ) {
      defaultMsg = 'Failed to update Approval Details';
      if (callbackValues.moduleId != undefined) {
        raiseGetSelectedApprovalList(callbackValues.moduleId);
      }
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const raiseGetApprovalsListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getApprovalModulesList },
      APIToken.GET_APPROVAL_MODULES_LIST, processSuccess, processError);
  };

  const raiseGetSelectedApprovalList = (moduleId) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getApprovalList, uriValues: [moduleId] },
      APIToken.GET_APPROVAL_LIST, processSuccess, processError);
  };

  const raiseGetSelectedApprovalMetadata = (moduleId) => {
    setIsLoading(true);
    if (approvalsModulesMetadata[moduleId].length > 0) {
      raiseGetSelectedApprovalList(moduleId);
    } else {
      Services.sendBackendRequest({ apiData: APIData.getApprovalMetadata, uriValues: [moduleId] },
        APIToken.GET_APPROVAL_METADATA, processSuccess, processError, { moduleId: moduleId });
    }
  };

  const raiseAcceptApprovalRequest = (params) => {
    Services.sendBackendRequest({ apiData: APIData.acceptApproval, uriValues: [selectedModuleDetails.module_id], params: params },
      APIToken.ACCEPT_APPROVAL, processSuccess, processError, { moduleId: selectedModuleDetails.module_id });
  };

  const raiseCancelApprovalRequest = (params) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.cancelApproval, uriValues: [selectedModuleDetails.module_id], params: params },
      APIToken.CANCEL_APPROVAL, processSuccess, processError, { moduleId: selectedModuleDetails.module_id });
  };

  const handleModuleClick = (event) => {
    setSelectedRows([]);
    const selectedModule = approvalsModuleList.find((module) => module.module_id === event.target.value);
    setSelectedModuleDetails(selectedModule);
    if (validateStringForNull(selectedModule.module_id)) {
      raiseGetSelectedApprovalMetadata(selectedModule.module_id);
    }
  };

  const validateParams = (params) => {
    const finalParams = {};
    const moduleId = selectedModuleDetails.module_id;
    const requiredParams = approvalsModulesUpdateMetadata[moduleId];

    if (!requiredParams || requiredParams.length <= 0) {
      throw new Error('Invalid Module');
    }

    requiredParams.forEach((key) => {
      finalParams[key] = params[key].value != undefined ? params[key].value : params[key];
    });
    return finalParams;
  };

  const getColumnConfig = (data) => {
    const arr = [];
    if (data == undefined) {
      return arr;
    }
    data.map((field) => {
      arr.push({
        field: field.value,
        headerName: field.display,
        hideable: false, minWidth: 120,
        valueGetter: (params) => {
          if (params.row[field.value] == undefined) {
            return '';
          } else if (params.row[field.value].display != undefined) {
            return params.row[field.value].display;
          } else {
            return params.row[field.value];
          }
        }
      });
    });
    return arr;
  };

  const handleApprovalAcceptClick = (apprDetails) => {
    setIsLoading(true);
    try {
      const params = validateParams(apprDetails);
      raiseAcceptApprovalRequest([params]);
    } catch (err) {
      showSnackBar('error', 'Failed update Approval details');
      setIsLoading(false);
    }
  };

  const handleApprovalCancelClick = (apprDetails) => {
    setIsLoading(true);
    try {
      const params = validateParams(apprDetails);
      raiseCancelApprovalRequest([params]);
    } catch (err) {
      showSnackBar('error', 'Failed update Approval details');
      setIsLoading(false);
    }
  };

  const validateSelectedParams = (dataKey) => {
    const params = [];
    selectedRows.map((id) => {
      const selectedRow = rows[dataKey].find((row) => row.id == id);
      if (selectedRow != undefined) {
        params.push(validateParams(selectedRow));
      }
    });
    return params;
  };

  const handleSelectedApprovalAcceptClick = () => {
    if (selectedRows.length == 0) {
      showSnackBar('warning', 'No rows selected');
      return;
    }
    setIsLoading(true);
    try {
      const params = validateSelectedParams('yetToBeApproved');
      raiseAcceptApprovalRequest(params);
    } catch (err) {
      showSnackBar('error', 'Failed update Approval details');
      setIsLoading(false);
    }
  };

  const handleSelectedApprovalsCancelClick = () => {
    if (selectedRows.length == 0) {
      showSnackBar('warning', 'No rows selected');
      return;
    }
    setIsLoading(true);
    try {
      const params = validateSelectedParams('alreadyApproved');
      raiseCancelApprovalRequest(params);
    } catch (err) {
      showSnackBar('error', 'Failed update Approval details');
      setIsLoading(false);
    }
  };

  const handleTabChange = (value) => {
    setSelectedRows([]);
    setCurrentTab(value);
  };

  const getDataGridToolBar = () => {
    return (
      selectedRows.length > 0 ? (
        <GridToolbarContainer>
          {currentTab == 0 ?
            <Button
              variant='contained'
              color='success'
              size='small'
              startIcon={<CheckCircleIcon/>}
              onClick={() => handleSelectedApprovalAcceptClick()}
            >
              Approve Selected
            </Button> : '' }
          {currentTab == 1 ?
            <Button
              variant='contained'
              color='error'
              size='small'
              startIcon={<CancelIcon/>}
              onClick={() => handleSelectedApprovalsCancelClick()}
            >
              Revoke Selected
            </Button> : '' }
        </GridToolbarContainer>
      ) : ''
    );
  };

  const renderDataGrid = (dataKey, isYetToBeApproved) => {
    if (rows[dataKey] != undefined) {
      const moduleId = selectedModuleDetails.module_id;
      const baseColumns = approvalsModulesMetadata[moduleId] || [];
      const actionColumns = [];
      if (baseColumns.length > 0 && validateFeature(allowedFeatures, APPROVALS_FEATURES.GRANT_REVOKE_APPROVALS)) {
        actionColumns.push(
          {
            headerName: 'Actions',
            minWidth: 120,
            renderCell: (params) => (
              <React.Fragment>
                {isYetToBeApproved ? (
                  <IconButton onClick={() => handleApprovalAcceptClick(params.row)}>
                    <CheckCircleIcon color='success'/>
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleApprovalCancelClick(params.row)}>
                    <CancelIcon color='error' />
                  </IconButton>
                )}
              </React.Fragment>
            )
          }
        );
      }
      const columns = [...actionColumns, ...baseColumns];
      return (
        <Paper sx={{ marginBottom: 2 }}>
          <div style={{ minHeight: 400, maxHeight: '40vh', width: '100%' }}>
            {rows[dataKey].length != 0 ? (
              <DataGrid
                rows={rows[dataKey]}
                columns={columns}
                disableColumnSelector
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newSelectedRow) => {
                  setSelectedRows(newSelectedRow);
                }}
                rowSelectionModel={selectedRows}
                sx={{ minHeight: 'inherit', maxHeight: 'inherit' }}
                hideable={false}
                getRowId={(row) => row.id}
                slots={{
                  toolbar: getDataGridToolBar
                }}
              />
            ) : <Typography variant='h6' textAlign='center' sx={{ pt: 20 }}>
              {isLoading ? 'Loading....' : 'No Data Available'}
            </Typography>
            }
          </div>
        </Paper>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box sx={{
        backgroundColor: 'background.default',
        minHeight: '100vh',
        pb: 10,
        pt: 2,
        justifyContent: 'center',
        alignItems: 'flex-start',
        display: 'flex'
      }}>
        <Container>
          <Grid container>
            <Grid item xs={ Utils.isMobile() ? 12 : 4}>
              <FormControl fullWidth size='small'>
                <InputLabel id="module-select-label">Module</InputLabel>
                <Select
                  labelId="module-select-label"
                  value={selectedModuleDetails ? selectedModuleDetails.module_id : ''}
                  onChange={handleModuleClick}
                  label="Module"
                >
                  {approvalsModuleList.map((module) => (
                    <MenuItem key={module.module_id} value={module.module_id}>
                      {module.module_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {validateStringForNull(selectedModuleDetails.module_id) ? (
            <Box
              sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                pt: 1
              }}>
              <Tabs value={currentTab}
                sx={{ backgroundColor: 'white', width: '100%', border: '1px', mb: 3 }}
                onChange={(e, newValue) => handleTabChange(newValue)}
                scrollButtons
                variant="fullWidth"
                allowScrollButtonsMobile
                indicatorColor="primary">
                <Tab key={0}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Yet To Be Approved
                    </Box>
                  } {...a11yProps(0)} value={0}/>
                <Tab key={1}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Already Approved
                    </Box>
                  } {...a11yProps(1)} value={1}/>
              </Tabs>
              <TabPanel key={0} value={currentTab} index={0} node={renderDataGrid('yetToBeApproved', true)}/>
              <TabPanel key={1} value={currentTab} index={1} node={renderDataGrid('alreadyApproved', false)}/>
            </Box>
          ) : ''}
        </Container>
      </Box>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default Approvals;
