import { CookieUtils } from './UtilFunctions';

// eslint-disable-next-line no-extend-native
String.prototype.format = function(...args) {
  let formatted = this;
  for (let i = 0; i < args[0].length; i++) {
    const regexp = new RegExp('\\{'+i+'\\}', 'gi');
    formatted = formatted.replace(regexp, args[0][i]);
  }
  return formatted.toString();
};

class APIError extends Error {
  constructor(status, success, message, data) {
    super(message);
    this.status = status;
    this.data = data;
    this.success = success;
  }
}

class Services {
  static baseURI = process.env.REACT_APP_BASE_URI;

  static getAuthHeaders(isHeadersNeeded) {
    if (isHeadersNeeded) {
      const loginhash = CookieUtils.getLoginhash();
      return { login_hash: loginhash };
    }
    return {};
  };

  static sendRequestInternal(uri, requestOptions, apiData, apiToken, callbackValues, processSuccess, processError) {
    const fullURI = `${Services.baseURI}${uri}`;
    fetch(fullURI, requestOptions)
      .then(async (res) => {
        const data = await res.json();
        if (res.ok && data.success === 1) {
          processSuccess(apiData, apiToken, callbackValues, data);
        } else {
          throw new APIError(res.status, data.success, data.message, data.data);
        }
      })
      .catch((err) => {
        let errObj = err;
        console.log(err);
        if (err instanceof TypeError) {
          errObj = new Error('Unexpected failure! Check your internet connection');
        } else if (err instanceof DOMException) {
          if (callbackValues == undefined) {
            callbackValues = {};
          }
          callbackValues.suppressSnackBar = true;
          console.log(errObj);
        } else {
          console.log(errObj);
        }
        processError(apiData, apiToken, callbackValues, errObj);
      });
  };

  static sendBackendRequest(requestObject, apiToken, processSuccess, processError, callbackValues) {
    const requestOptions = {};
    requestOptions.method = requestObject.apiData.getMethod();
    let finalURI = requestObject.apiData.getAPIPath();
    if (requestObject.uriValues !== undefined) {
      finalURI = finalURI.format(requestObject.uriValues);
    }
    requestOptions.headers = Services.getAuthHeaders(requestObject.apiData.getIsAuthRequired());
    if (requestObject.params != undefined) {
      if (requestObject.apiData.getMethod() == 'GET') {
        finalURI+='?'+requestObject.params;
      } else {
        requestOptions.body = JSON.stringify(requestObject.params);
        requestOptions.headers['Content-Type'] = 'application/json';
      }
    }
    Services.sendRequestInternal(finalURI, requestOptions, requestObject.apiData, apiToken, callbackValues, processSuccess, processError);
  };

  static sendMultipartRequest(requestObject, apiToken, processSuccess, processError, callbackValues) {
    const requestOptions = {};
    requestOptions.method = requestObject.apiData.getMethod();
    let finalURI = requestObject.apiData.getAPIPath();
    if (requestObject.uriValues !== undefined) {
      finalURI = finalURI.format(requestObject.uriValues);
    }
    requestOptions.headers = Services.getAuthHeaders(requestObject.apiData.getIsAuthRequired());
    if (requestObject.params != undefined) {
      if (requestObject.apiData.getMethod() == 'POST') {
        requestOptions.body = requestObject.params;
      }
    }
    Services.sendRequestInternal(finalURI, requestOptions, requestObject.apiData, apiToken, callbackValues, processSuccess, processError);
  };

  static downloadFile(uri, requestOptions, apiData, apiToken, processSuccess, processError, callbackValues) {
    const fullURI = `${Services.baseURI}${uri}`;

    fetch(fullURI, requestOptions)
      .then(async (res) => {
        if (res.ok) {
          const blob = await res.blob();

          const contentDisposition = res.headers.get('Content-Disposition');
          let filename = 'PO-ORDER.pdf';

          if (contentDisposition != undefined && contentDisposition.includes('filename=') !== -1) {
            const match = contentDisposition.match(/filename="?(.+)"?/);
            if (match != undefined && match[1]) {
              filename = match[1];
            }
          }

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();

          processSuccess(apiData, apiToken, callbackValues, null);
        } else {
          const errorData = await res.json();
          throw new APIError(res.status, errorData.success, errorData.message ?? 'Unable to retrieve PDF', undefined);
        }
      })
      .catch((err) => {
        let errObj = err;
        console.log(err);
        if (err instanceof TypeError) {
          errObj = new Error('Unexpected failure! Check your internet connection.');
        } else if (err instanceof DOMException) {
          if (callbackValues == undefined) {
            callbackValues = {};
          }
          callbackValues.suppressSnackBar = true;
        }
        processError(apiData, apiToken, callbackValues, errObj);
      });
  }

  static sendPDFDownloadRequest(requestObject, apiToken, processSuccess, processError, callbackValues) {
    const requestOptions = {};
    requestOptions.method = requestObject.apiData.getMethod();
    let finalURI = requestObject.apiData.getAPIPath();
    if (requestObject.uriValues !== undefined) {
      finalURI = finalURI.format(requestObject.uriValues);
    }
    requestOptions.headers = Services.getAuthHeaders(requestObject.apiData.getIsAuthRequired());

    if (requestObject.params !== undefined) {
      if (requestObject.apiData.getMethod() === 'GET') {
        finalURI += '?' + new URLSearchParams(requestObject.params).toString();
      } else {
        requestOptions.body = JSON.stringify(requestObject.params);
        requestOptions.headers['Content-Type'] = 'application/json';
      }
    }

    Services.downloadFile(finalURI, requestOptions, requestObject.apiData, apiToken, processSuccess, processError, callbackValues);
  };
}
export default Services;
