import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import OrdersJewelleryBooking from '../components/jewellery/OrdersJewelleryBooking';

const JewelleryBooking = () => {
  const pageTitle = 'Orders Jewellery Booking | Rajmahal Apps';
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: 'calc(94vh - 64px)',
          alignItems: 'flex-start',
          pb: 5,
          display: 'flex'
        }}
      >
        <OrdersJewelleryBooking />
      </Box>
    </React.Fragment>
  );
};

export default JewelleryBooking;
