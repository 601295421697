import { AppBar, Backdrop, Badge, Box, Button, Card, Chip, CircularProgress, Dialog, DialogActions, Divider, FormControl, Grid,
  IconButton, InputLabel, MenuItem, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateNumber, validateStringForNull } from '../../../../utils/FieldValidations';
import Services from '../../../../utils/Services';
import APIData from '../../../../utils/APIData';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomAutocomplete from '../../../../common/components/CustomAutocomplete';
import { ATTACHMENT_STATUS, MATERIAL_TYPE } from '../../../../utils/EnumDefinitions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import ViewPlanDetails from '../planning/ViewPlanDetails';
import Slide from '@mui/material/Slide';
import moment from 'moment';
import TSCamera from '../../../../common/components/TSCamera.js';
import TSGallery from '../../../../common/components/TSGallery.js';
import WarningIcon from '@mui/icons-material/Warning';
import { ORDERS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterPlanDetails from '../planning/FilterPlanDetails.js';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import JumpingPrice from './JumpingPrice.js';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import PlaylistAddCircleRoundedIcon from '@mui/icons-material/PlaylistAddCircleRounded';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OrdersBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [planList, setPlanList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [markList, setMarkList] = useState([]);
  const [isViewPlanDialogOpen, setIsViewPlanDialogOpen] = useState(false);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [cameraParams, setCameraParams] = useState({});
  const [planProductQtyDetails, setPlanProductQtyDetails] = useState({});
  const [isViewAccessGranted, setIsViewAccessGranted] = useState(false);
  const [isCreateAccessGranted, setIsCreateAccessGranted] = useState(false);
  const [createdOrderDetails, setCreatedOrderDetails] = useState({
    created_order_id: '',
    ac_email: '',
    agent_email: '',
    created_plan_id: '',
    need_email: false,
    is_create: true
  });
  const [pdfDialogMessage, setPdfDialogMessage] = useState('');
  const [isFilterPlanDialogOpen, setIsFilterPlanDialogOpen] = useState(false);
  const [isSharePdfDialogOpen, setIsSharePdfDialogOpen] = useState(false);
  const [selectedMarkDetails, setSelectedMarkDetails] = useState({});
  const [originalPlanValue, setOriginalPlanValue] = useState({});

  const [isCreateJumpingRangeDialogOpen, setIsCreateJumpingRangeDialogOpen] = useState(false);
  const [selectedJumpingPriceData, setSelectedJumpingData] = useState({
    data: undefined,
    refresh_id: 0
  });
  const [jumpingPriceCache, setJumpingPriceCache] = useState({});

  const [filteredPlanByMark, setFilteredPlanByMark] = useState({
    filter_plan_data: {},
    refresh_id: 0
  });
  const [orderDetails, setOrderDetails] = useState({
    plan_id: '',
    order_id: '',
    ac_name: '',
    ac_id: '',
    agent_id: '',
    agent_name: '',
    tran_id: '',
    tran_name: '',
    ac_email: '',
    agent_email: '',
    remarks: '',
    order_date: moment().format( 'DD-MM-YYYY'),
    order_data: [],
    refresh_id: 0
  });
  const [originalValue, setOriginalValue] = useState(orderDetails);
  const [allowedFeatures, setAllowedFeatures] = useState([]);

  const readyMadeSubDataDefaultObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    size_id: '',
    color_id: '',
    color_name: '',
    buy_price: '',
    bought_quantity: '',
    quantity: '',
    jumping_price_id: undefined,
    jumping_price_sub_data_id: undefined,
    attachments: {}
  };

  const readyMadeDefaultObject = {
    id: '',
    cate_id: '',
    cate_name: '',
    prod_id: '',
    prod_name: '',
    item_id: '',
    material_type: '',
    mark_name: '',
    is_expanded: true,
    sub_data: []
  };

  const silkAndCutPieceSubDataDefaultObject = {
    id: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    buy_price: '',
    description: '',
    quantity: '',
    attachments: {}
  };

  const silkAndCutPieceDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    item_id: '',
    material_type: '',
    mark_name: '',
    sub_data: []
  };

  const polyesterSubDataDefaultObject = {
    id: '',
    buy_price: '',
    description: '',
    quantity: '',
    model_id: '',
    model_name: '',
    brand_id: '',
    brand_name: '',
    qty_per_set: '6',
    no_of_sets: '',
    attachments: {}
  };

  const polyesterDefaultObject = {
    id: '',
    prod_id: '',
    prod_name: '',
    is_expanded: true,
    item_id: '',
    material_type: '',
    mark_name: '',
    sub_data: []
  };

  const APIToken = {
    GET_PLAN_LIST: 'COB01',
    GET_BOOKING_ORDER_LIST: 'COB02',
    GET_BOOKING_ORDER: 'COB03',
    GET_ORDER_QTY: 'COB04',
    GET_PRODUCT_ID: 'COB05',
    CREATE_BOOKING_ORDER: 'COB06',
    UPDATE_BOOKING_ORDER: 'COB07',
    GET_MARK_LIST: 'COB08',
    UPLOAD_ATTACHMENTS: 'COB09',
    DOWNLOAD_ATTACHMENT: 'COB10',
    DOWNLOAD_ATTACHMENTS: 'COB11',
    GET_USER: 'COB12',
    DOWNLOAD_ORDER_BOOKING_PDF: 'COB13',
    VIEW_PLAN_DETAILS: 'COB14'
  };

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      if (response.data.mark_list != undefined && response.data.mark_list.length > 0) {
        const filteredMark = response.data.mark_list.filter((mark) => mark.shop_id === response.data.mark_list[0].shop_id);
        setMarkList(filteredMark);
      }
      raiseGetPlanListRequest();
    } else if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST ) {
      if (response.data.length == 0) {
        showSnackBar('warning', 'No active plans exist');
      }
      setPlanList(response.data);
    } else if (apiData == APIData.getBookingOrderList && apiToken == APIToken.GET_BOOKING_ORDER_LIST) {
      if (response.data != undefined) {
        const updatedOrderList = [
          ...response.data,
          { order_id: '-- NEW --', order_date: moment().format( 'DD-MM-YYYY') }
        ];
        setOrderList(isCreateAccessGranted ? updatedOrderList : response.data);
        const filteredOrder = isCreateAccessGranted ? updatedOrderList.find((obj) => obj.order_id == '-- NEW --') : {};
        orderDetails.order_id = validateStringForNull(filteredOrder.order_id) ? filteredOrder.order_id : '';
        orderDetails.order_date = validateStringForNull(filteredOrder.order_date) ? filteredOrder.order_date : '';
        orderDetails.plan_id = validateStringForNull(callbackValues.planId) ? callbackValues.planId : '';
        orderDetails.ac_name = '',
        orderDetails.ac_id = '',
        orderDetails.agent_id = '',
        orderDetails.agent_name = '',
        orderDetails.tran_id = '',
        orderDetails.tran_name = '',
        orderDetails.ac_email = '';
        orderDetails.agent_email = '',
        orderDetails.remarks = '',
        orderDetails.order_data = [];
        notifyOrderDetailsChange();
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order list');
      }
      setIsUpdateRequest(false);
    } else if (apiData == APIData.getBookingOrder && apiToken == APIToken.GET_BOOKING_ORDER) {
      if (response.data != undefined) {
        setIsUpdateRequest(true);
        populateOrderData(response.data, callbackValues.showShareDialog, callbackValues.isCreateOrder);
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order details');
      }
    } else if (apiData == APIData.createBookingOrder && apiToken == APIToken.CREATE_BOOKING_ORDER) {
      setPdfDialogMessage('Order Created successfully! You can now email/download PO');
      setPlanProductQtyDetails({});
      const cloned = cloneDeep(orderList);
      cloned.splice(cloned.length-1, 0, { order_id: response.data.order_id, order_date: moment().format( 'DD-MM-YYYY') });
      setOrderList(cloned);
      raiseGetOrderRequest(response.data.order_id, true, true);
    } else if (apiData == APIData.updateBookingOrder && apiToken == APIToken.UPDATE_BOOKING_ORDER) {
      setPdfDialogMessage('Order Updated Successfully! You can now email/download PO');
      setPlanProductQtyDetails({});
      raiseGetOrderRequest(callbackValues.orderId, true, false);
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      setProductName(callbackValues.id, response.data);
    } else if (apiData == APIData.uploadAttachments && apiToken == APIToken.UPLOAD_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachmentId = response.data.attachments[callbackValues.imageKey];
      if (attachmentId == undefined) {
        subData.attachments[callbackValues.imageKey].attachment_id = undefined;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
        if (callbackValues.showSnackBar == true) {
          showSnackBar('error', 'Image upload failed. Try again. ' + (response.message != undefined ? `[Error: + ${response.message}] ` : ''));
        }
      } else {
        subData.attachments[callbackValues.imageKey].attachment_id = attachmentId;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadAttachment && apiToken == APIToken.DOWNLOAD_ATTACHMENT) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachment = response.data.attachment;
      const attachmentId = response.data.attachment_id;
      let showSB = false;
      if (attachmentId == subData.attachments[callbackValues.imageKey].attachment_id) {
        try {
          subData.attachments[callbackValues.imageKey].data = Utils.base64ToFile(attachment, callbackValues.imageKey);
          subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
        } catch (err) {
          showSB = true;
          subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
        }
      } else {
        showSB = true;
        subData.attachments[callbackValues.imageKey].data = undefined;
        subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      }
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Image download failed. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadAttachments && apiToken == APIToken.DOWNLOAD_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      const attachments = response.data;
      let showSB = false;
      callbackValues.imageKeys.map((key) => {
        const aId = subData.attachments[key].attachment_id;
        const arr = attachments.find((obj) => obj.attachment_id == aId);
        if (arr != undefined && arr != null) {
          const attachment = arr.attachment;
          try {
            subData.attachments[key].data = Utils.base64ToFile(attachment, key);
            subData.attachments[key].status = ATTACHMENT_STATUS.DONE;
          } catch (err) {
            showSB = true;
            subData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
          }
        }
      });
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (response.message != undefined ? `[Error: ${response.message}]` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.getOrderQuantity && apiToken == APIToken.GET_ORDER_QTY) {
      if (validateStringForNull(callbackValues.prodId) && validateStringForNull(callbackValues.itemId)) {
        const key = generateQtyObjKey(callbackValues.itemId, callbackValues.prodId);
        const value = response.data;
        if (planProductQtyDetails[key] == undefined) {
          setPlanProductQtyDetails((prevState) => ({
            ...prevState,
            [key]: value
          }));
        }
      }
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'ORDERS');
        if (filtered != undefined && filtered.length > 0) {
          setAllowedFeatures(filtered[0].available_features);
          const isAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.VIEW_PLAN) &&
          validateFeature(filtered[0].available_features, ORDERS_FEATURES.VIEW_ORDERS);
          const isCreateAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.CREATE_OR_UPDATE_ORDERS);
          setIsViewAccessGranted(isAllowed);
          setIsCreateAccessGranted(isCreateAllowed);
          if (!isAllowed) {
            showSnackBar('error', 'You don\'t have permission to view order details');
          } else if (validateStringForNull(purchaserId)) {
            raiseGetMarkListRequest();
            return;
          }
        }
      }
    } else if (apiData == APIData.downloadOrderBookingPdf && apiToken == APIToken.DOWNLOAD_ORDER_BOOKING_PDF) {
      showSnackBar('success', 'PDF downloaded successfully');
      if (callbackValues.orderDetails.need_email) {
        sendPOInEmail(callbackValues.orderDetails);
      }
    } else if (apiData == APIData.getPlanDetails && apiToken == APIToken.VIEW_PLAN_DETAILS ) {
      if (response.data != undefined) {
        setOriginalPlanValue(response.data);
        const filteredPlanData = getFilteredPlanData(response.data.plan_data, callbackValues.mark.mark_name);
        if (filteredPlanData != undefined && filteredPlanData.length > 0) {
          const filterPlanObj = {
            plan_tag: response.data.plan_tag,
            is_active: response.data.is_active,
            purchaser_id: response.data.purchaser_id,
            plan_data: filteredPlanData,
            plan_id: orderDetails.plan_id,
            ac_year: response.data.ac_year,
            plan_date: response.data.plan_date
          };
          filteredPlanByMark.filter_plan_data = filterPlanObj;
          notifyFilteredPlanDetailsChange();
          setIsFilterPlanDialogOpen(true);
        } else {
          createNewOrderDetails(callbackValues.mark);
        }
      }
    }
    setIsLoading(false);
  };

  const getFilteredPlanData = (planData, markName) => {
    return planData.filter((plan) => plan.mark_name == markName);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getMarkListForOrders && apiToken == APIToken.GET_MARK_LIST ) {
      defaultMsg = 'Failed to retrieve mark list';
    } else if (apiData == APIData.getPlanList && apiToken == APIToken.GET_PLAN_LIST) {
      defaultMsg = 'Failed to retrieve Plan list';
    } else if (apiData == APIData.getBookingOrderList && apiToken == APIToken.GET_BOOKING_ORDER_LIST) {
      defaultMsg = 'Failed to retrieve Order list';
    } else if (apiData == APIData.getBookingOrder && apiToken == APIToken.GET_BOOKING_ORDER) {
      defaultMsg = 'Failed to retrieve Order Details';
    } else if (apiData == APIData.createBookingOrder && apiToken == APIToken.CREATE_BOOKING_ORDER) {
      defaultMsg = 'Failed to Create order';
    } else if (apiData == APIData.updateBookingOrder && apiToken == APIToken.UPDATE_BOOKING_ORDER) {
      defaultMsg = 'Failed to Update order';
    } else if (apiData == APIData.getProductId && apiToken == APIToken.GET_PRODUCT_ID) {
      defaultMsg = 'Failed to retrieve product details';
    } else if (apiData == APIData.uploadAttachments && apiToken == APIToken.UPLOAD_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      subData.attachments[callbackValues.imageKey].attachment_id = undefined;
      subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Image upload failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}]` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadAttachment && apiToken == APIToken.DOWNLOAD_ATTACHMENT) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      subData.attachments[callbackValues.imageKey].data = undefined;
      subData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      if (callbackValues.showSnackBar == true ) {
        showSnackBar('error', 'Image download failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadAttachments && apiToken == APIToken.DOWNLOAD_ATTACHMENTS) {
      const subData = findSubData(callbackValues.dataId, callbackValues.subdataId);
      callbackValues.imageKeys.map((key) => {
        subData.attachments[key].data = undefined;
        subData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      });
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.getOrderQuantity && apiToken == APIToken.GET_ORDER_QTY) {
      defaultMsg = 'Failed to retrieve plan/bought qty for the given product';
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    } else if (apiData == APIData.downloadOrderBookingPdf && apiToken == APIToken.DOWNLOAD_ORDER_BOOKING_PDF) {
      defaultMsg = 'Failed to Download PDF';
    } else if (apiData == APIData.getPlanDetails && apiToken == APIToken.VIEW_PLAN_DETAILS) {
      defaultMsg = 'Failed to retrieve plan list';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const raiseGetMarkListRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getMarkListForOrders },
      APIToken.GET_MARK_LIST, processSuccess, processError);
  };

  const raiseGetPlanListRequest = () => {
    setIsLoading(true);
    const params = 'purchaser_id=' + purchaserId;
    Services.sendBackendRequest({ apiData: APIData.getPlanList, params: params },
      APIToken.GET_PLAN_LIST, processSuccess, processError);
  };

  const populateOrderAttachments = (attachmentIds, dataId, subdataId) => {
    const attachments = {};
    if (attachmentIds == undefined) return attachments;
    attachmentIds.map((id, i) => {
      const key = dataId.toString() + subdataId.toString() + moment().valueOf().toString() + i + '.png';
      const obj = {
        attachment_id: id,
        status: ATTACHMENT_STATUS.NOT_DOWNLOADED
      };
      attachments[key]= obj;
    });
    return attachments;
  };

  const populateOrderData = (data, showShareDialog, isCreateOrder) => {
    const obj = {};
    const qtyDetailsMap = {};
    data.order_data.forEach((order) => {
      const materialType = MATERIAL_TYPE[order.material_type];
      const key = generateQtyObjKey(order.item_id, order.prod_id);
      if (qtyDetailsMap[key] == undefined) {
        qtyDetailsMap[key] = [];
      }
      const productQtyDetails = {
        bought_quantity: order.bought_quantity,
        planned_quantity: order.planned_quantity,
        min_price: order.min_price,
        max_price: order.max_price,
        serial: order.plan_serial,
        model_id: validateStringForNull(order.model_id) ? order.model_id : undefined,
        brand_id: validateStringForNull(order.brand_id) ? order.brand_id : undefined,
        size_id: validateStringForNull(order.size_id) ? order.size_id : undefined,
        color_id: validateStringForNull(order.color_id) ? order.color_id : undefined
      };
      qtyDetailsMap[key].push(productQtyDetails);
      if (materialType == 3) {
        const key = `${order.item_id}_${order.cate_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            cate_name: order.cate_name,
            cate_id: order.cate_id,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            is_expanded: true,
            sub_data: []
          };
        }
        const readymadeObj = {
          id: obj[key].sub_data.length + 1,
          model_name: validateStringForNull(order.model_name) ? order.model_name : '',
          brand_name: validateStringForNull(order.brand_name) ? order.brand_name : '',
          model_id: validateStringForNull(order.model_id) ? order.model_id : '',
          brand_id: validateStringForNull(order.brand_id) ? order.brand_id : '',
          buy_price: order.buy_price,
          size_id: validateStringForNull(order.size_id) ? order.size_id : '',
          quantity: order.quantity,
          description: validateStringForNull(order.description) ? order.description : '',
          bought_quantity: order.bought_quantity,
          planned_quantity: order.planned_quantity,
          plan_serial: validateStringForNull(order.plan_serial) ? order.plan_serial : -1,
          serial: order.serial,
          color_id: validateStringForNull(order.color_id) ? order.color_id : '',
          color_name: validateStringForNull(order.color_name) ? order.color_name : ''
        };
        readymadeObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, readymadeObj.id);
        obj[key].sub_data.push(readymadeObj);
      } else if (materialType == 2 || materialType == 4) {
        const key = `${order.item_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            is_expanded: true,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            sub_data: []
          };
        }
        const silkObj = {
          id: obj[key].sub_data.length + 1,
          buy_price: order.buy_price,
          quantity: order.quantity,
          description: validateStringForNull(order.description) ? order.description : '',
          bought_quantity: order.bought_quantity,
          planned_quantity: order.planned_quantity,
          plan_serial: validateStringForNull(order.plan_serial) ? order.plan_serial : -1,
          serial: order.serial,
          model_name: validateStringForNull(order.model_name) ? order.model_name : '',
          brand_name: validateStringForNull(order.brand_name) ? order.brand_name : '',
          model_id: validateStringForNull(order.model_id) ? order.model_id : '',
          brand_id: validateStringForNull(order.brand_id) ? order.brand_id : ''
        };
        silkObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, silkObj.id);
        obj[key].sub_data.push(silkObj);
      } else {
        const key = `${order.item_id}_${order.prod_id}`;
        if (obj[key] == undefined) {
          obj[key] = {
            id: Object.keys(obj).length + 1,
            prod_id: order.prod_id,
            prod_name: order.prod_name,
            is_expanded: true,
            item_id: order.item_id,
            material_type: materialType,
            mark_name: order.mark_name,
            sub_data: []
          };
        }
        const polyesterObj = {
          id: obj[key].sub_data.length + 1,
          buy_price: order.buy_price,
          quantity: order.quantity,
          description: validateStringForNull(order.description) ? order.description : '',
          bought_quantity: order.bought_quantity,
          planned_quantity: order.planned_quantity,
          no_of_sets: parseInt(order.quantity / 6),
          qty_per_set: 6,
          plan_serial: validateStringForNull(order.plan_serial) ? order.plan_serial : -1,
          serial: order.serial,
          model_name: validateStringForNull(order.model_name) ? order.model_name : '',
          brand_name: validateStringForNull(order.brand_name) ? order.brand_name : '',
          model_id: validateStringForNull(order.model_id) ? order.model_id : '',
          brand_id: validateStringForNull(order.brand_id) ? order.brand_id : ''
        };
        polyesterObj.attachments = populateOrderAttachments(order.attachment_id, obj[key].id, polyesterObj.id);
        obj[key].sub_data.push(polyesterObj);
      }
    });
    setPlanProductQtyDetails(qtyDetailsMap);
    const filteredData = Object.values(obj);
    const filteredOrderObj = {
      plan_id: data.plan_id,
      order_id: data.order_id,
      order_date: validateStringForNull(data.order_date) ? data.order_date.display : '',
      ac_id: validateStringForNull(data.ac_id) ? data.ac_id: '',
      ac_name: validateStringForNull(data.ac_name) ? data.ac_name: '',
      agent_id: validateStringForNull(data.agent_id) ? data.agent_id: '',
      agent_name: validateStringForNull(data.agent_name) ? data.agent_name: '',
      tran_id: validateStringForNull(data.tran_id) ? data.tran_id: '',
      tran_name: validateStringForNull(data.tran_name) ? data.tran_name: '',
      remarks: validateStringForNull(data.remarks) ? data.remarks : '',
      ac_email: validateStringForNull(data.ac_email) ? data.ac_email : '',
      agent_email: validateStringForNull(data.agent_email) ? data.agent_email : '',
      order_data: filteredData,
      refresh_id: 0
    };
    setOrderDetails(filteredOrderObj);
    setCreatedOrderDetails({ ...createdOrderDetails,
      ac_email: filteredOrderObj.ac_email,
      agent_email: filteredOrderObj.agent_email,
      created_order_id: filteredOrderObj.order_id,
      created_plan_id: filteredOrderObj.plan_id,
      is_create: isCreateOrder
    });
    if (showShareDialog) {
      setIsSharePdfDialogOpen(true);
    }
    setOriginalValue(cloneDeep(filteredOrderObj));
  };

  const raiseGetOrderListRequest = (planId) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getBookingOrderList, params: params },
      APIToken.GET_BOOKING_ORDER_LIST, processSuccess, processError, { planId: planId });
  };

  const raiseGetOrderRequest = (orderId, showShareDialog, isCreateOrder) => {
    setIsLoading(true);
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getBookingOrder, params: params },
      APIToken.GET_BOOKING_ORDER, processSuccess, processError, { showShareDialog: showShareDialog, isCreateOrder: isCreateOrder });
  };

  const raiseGetOrderQuantity = (prodId, itemId) => {
    setIsLoading(true);
    const params = 'plan_id=' + orderDetails.plan_id + '&prod_id=' + prodId + '&item_id=' + itemId;
    Services.sendBackendRequest({ apiData: APIData.getOrderQuantity, params: params },
      APIToken.GET_ORDER_QTY, processSuccess, processError, { prodId: prodId, itemId: itemId });
  };

  const raiseCreateOrUpdateOrderRequest = (finalParams) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updateBookingOrder;
      apiToken = APIToken.UPDATE_BOOKING_ORDER;
    } else {
      apiData = APIData.createBookingOrder;
      apiToken = APIToken.CREATE_BOOKING_ORDER;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError, { plan_id: finalParams.plan_id, orderId: finalParams.order_id });
  };

  const raiseGetProductDetailsRequest = (id, itemId, prodId) => {
    setIsLoading(true);
    const params = 'item_id=' +itemId +'&prod_id=' + prodId;
    Services.sendBackendRequest({ apiData: APIData.getProductId, params: params },
      APIToken.GET_PRODUCT_ID, processSuccess, processError, { id: id });
  };

  const handlePlanChange = (event) => {
    const { name, value } = event.target;
    setOrderDetails({ ...orderDetails,
      [name]: value,
      order_id: '',
      order_data: [],
      ac_id: '',
      ac_name: '',
      agent_id: '',
      agent_name: '',
      tran_id: '',
      tran_name: '',
      ac_email: '',
      agent_email: '',
      remarks: ''
    });
    setOriginalPlanValue({});
    setJumpingPriceCache({});
    setFilteredPlanByMark({
      filter_plan_data: {},
      refresh_id: 0
    });
    setCreatedOrderDetails({ ...createdOrderDetails,
      created_order_id: '',
      created_plan_id: '',
      ac_email: '',
      agent_email: '',
      need_email: false
    });
    setIsUpdateRequest(false);
    raiseGetOrderListRequest(value);
    setPlanProductQtyDetails({});
  };

  const handleViewPlanClick = () => {
    if (validateStringForNull(orderDetails.plan_id)) {
      setIsViewPlanDialogOpen(true);
    }
  };

  const sendPOInEmail = (orderData) => {
    if (!orderData || !orderData.ac_email) {
      throw new Error('Missing required email details in orderDetails.');
    }

    const toEmail = orderData.ac_email;
    const subject = encodeURIComponent('Purchase Order Details');
    const body = encodeURIComponent('Please find the attached purchase order details.');

    let mailtoLink = `mailto:${toEmail}?subject=${subject}&body=${body}`;
    if (validateStringForNull(orderData.agent_email)) {
      mailtoLink += `&cc=${orderData.agent_email}`;
    }
    window.location.href = mailtoLink;
  };

  const handleProductDetailsSearch = (id, prodId, itemId) => {
    if (validateStringForNull(prodId) && validateStringForNull(itemId) ) {
      raiseGetProductDetailsRequest(id, itemId, prodId);
      const key = generateQtyObjKey(itemId, prodId);
      if (planProductQtyDetails[key] == undefined) {
        raiseGetOrderQuantity(prodId, itemId);
      }
    }
  };

  const handleOrderChange = (event) => {
    const { name, value } = event.target;
    const filteredOrder = orderList.find((order) => order.order_id === value);
    setOrderDetails({ ...orderDetails, [name]: value, ac_name: '', ac_id: '', agent_id: '', remarks: '', ac_email: '',
      agent_email: '', agent_name: '', tran_id: '', tran_name: '', order_data: [], order_date: moment().format( 'DD-MM-YYYY') });
    setCreatedOrderDetails({ ...createdOrderDetails,
      created_order_id: '',
      created_plan_id: '',
      ac_email: '',
      agent_email: '',
      need_email: false
    });
    if (filteredOrder.order_id != '-- NEW --') {
      raiseGetOrderRequest(value, false, false);
    } else {
      setIsUpdateRequest(false);
    }
    setPlanProductQtyDetails({});
    setJumpingPriceCache({});
  };

  const handleOrderDetailsAutoCompleteChange = (idField, nameField, value) => {
    if (value != undefined) {
      const idValue = idField === 'agent_id' || idField === 'ac_id' ? value.ac_id : value[idField];
      const nameValue = idField === 'agent_id' || idField === 'ac_id' ? value.ac_name : value[nameField];
      setOrderDetails({
        ...orderDetails,
        [idField]: idValue,
        [nameField]: nameValue
      });
    } else {
      setOrderDetails({
        ...orderDetails,
        [idField]: '',
        [nameField]: ''
      });
    }
  };

  const setProductName = (id, response) => {
    const dataObj= orderDetails.order_data.find((item) => item.id === id);
    if (dataObj != undefined && response != undefined) {
      const { prod_id: prodId, prod_name: prodName } = response;
      dataObj.prod_id = prodId;
      dataObj.prod_name = prodName;
      notifyOrderDetailsChange();
    }
  };

  const createNewOrderDetails = (markDetails) => {
    const material = MATERIAL_TYPE[markDetails.material_type];
    const lastIdData = orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id : 0;
    let newSubObj = {};
    let newObj = {};
    if (material == 3) {
      newObj = readyMadeDefaultObject;
      newSubObj = readyMadeSubDataDefaultObject;
    } else if (material == 2 || material == 4) {
      newObj = silkAndCutPieceDefaultObject;
      newSubObj = silkAndCutPieceSubDataDefaultObject;
    } else if (material == 1) {
      newObj = polyesterDefaultObject;
      newSubObj = polyesterSubDataDefaultObject;
    }
    newSubObj.id = 1;
    newObj.id = lastIdData + 1;
    newObj.sub_data = [newSubObj];
    newObj.item_id = markDetails.item_id;
    newObj.material_type = material;
    newObj.mark_name = markDetails.mark_name;
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const handleAddMarkClick = (mark) => {
    setSelectedMarkDetails(mark);
    if (Object.keys(originalPlanValue).length == 0) {
      raiseGetPlanRequest(orderDetails.plan_id, mark);
    } else {
      const filteredPlanData = getFilteredPlanData(originalPlanValue.plan_data, mark.mark_name);
      if (filteredPlanData != undefined && filteredPlanData.length > 0) {
        const filterPlanObj = {
          plan_tag: originalPlanValue.plan_tag,
          is_active: originalPlanValue.is_active,
          purchaser_id: originalPlanValue.purchaser_id,
          plan_data: filteredPlanData,
          plan_id: orderDetails.plan_id,
          ac_year: originalPlanValue.ac_year,
          plan_date: originalPlanValue.plan_date
        };
        filteredPlanByMark.filter_plan_data = filterPlanObj;
        notifyFilteredPlanDetailsChange();
        setIsFilterPlanDialogOpen(true);
      } else {
        createNewOrderDetails(mark);
      }
    }
  };

  const notifyFilteredPlanDetailsChange = () => {
    setFilteredPlanByMark({ ...filteredPlanByMark, refresh_id: filteredPlanByMark.refresh_id + 1 });
  };

  const getPlannedPriceRange = (minPrice, maxPrice) => {
    if (validateStringForNull(minPrice) && (validateStringForNull(maxPrice))) {
      return `${minPrice} - ${maxPrice}`;
    } else {
      return '';
    }
  };

  const handleAddDataClick = (order) => {
    let newSubObj;

    if (order.material_type == 1) {
      newSubObj = polyesterSubDataDefaultObject;
    } else if (order.material_type == 2 || order.material_type == 4) {
      newSubObj = silkAndCutPieceSubDataDefaultObject;
    } else {
      newSubObj = readyMadeSubDataDefaultObject;
    }
    newSubObj.id = 1;
    let newObj;

    if (order.material_type == 3) {
      const defaultObj = cloneDeep(readyMadeDefaultObject);
      newObj = {
        ...defaultObj,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        cate_id: order.cate_id,
        cate_name: order.cate_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [cloneDeep(newSubObj)]
      };
    } else if (order.material_type == 2 || order.material_type == 4) {
      const defaultObj = cloneDeep(silkAndCutPieceDefaultObject);
      newObj = {
        ...defaultObj,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [cloneDeep(newSubObj)]
      };
    } else {
      const defaultObj = cloneDeep(polyesterDefaultObject);
      newObj = {
        ...defaultObj,
        item_id: order.item_id,
        material_type: order.material_type,
        mark_name: order.mark_name,
        prod_id: order.prod_id,
        prod_name: order.prod_name,
        id: orderDetails.order_data.length > 0 ? orderDetails.order_data[orderDetails.order_data.length - 1].id + 1 : 1,
        sub_data: [newSubObj]
      };
    }
    orderDetails.order_data.push(newObj);
    notifyOrderDetailsChange();
  };

  const handleAutoCompleteChange = (value, data, idField, nameField) => {
    const oldValue = data[idField];
    data[idField] = value != null ? value[idField] : '';
    data[nameField] = value != null ? value[nameField] : '';
    if (idField === 'cate_id') {
      data.prod_id = '';
      data.prod_name = '';
    }
    if (oldValue != data[idField]) {
      const jumpingPriceIdArray = [];
      data.sub_data.forEach((subData) => {
        if (!jumpingPriceIdArray.includes(subData.jumping_price_id)) {
          jumpingPriceIdArray.push(subData.jumping_price_id);
        }
        subData.jumping_price_id = undefined;
        subData.jumping_price_sub_data_id = undefined;
      });
      if (jumpingPriceIdArray.length > 0) {
        const updatedCache = cloneDeep(jumpingPriceCache);
        jumpingPriceIdArray.forEach((id) => {
          delete updatedCache[id];
        });
        setJumpingPriceCache(updatedCache);
      }
    }
    notifyOrderDetailsChange();
    if (value != null) {
      if (idField == 'prod_id' && planProductQtyDetails[generateQtyObjKey(data.item_id, value[idField])] == undefined) {
        raiseGetOrderQuantity(value[idField], data.item_id);
      }
    }
  };

  const handleSubdataAutoCompleteChange = (value, subData, idField, nameField, order) => {
    const oldQtyValue = subData['quantity'];
    const oldValue = subData[idField];
    const oldSerial = getPlanSerial(order.prod_id, order.item_id, subData);
    subData[idField] = value != null ? value[idField] : '';
    subData[nameField] = value != null ? value[nameField] : '';
    notifyOrderDetailsChange();
    if (oldValue != subData[idField]) {
      removeJumpingPriceCache(subData.jumping_price_id, order);
    }
    updateQtyCache(subData, order.prod_id, order.item_id, oldSerial, oldQtyValue);
  };

  const handleAddSubDataClick = (data) => {
    const lastId = data.sub_data[data.sub_data.length - 1].id;
    let newSubObj = {};
    if (data.material_type == 3) {
      newSubObj = readyMadeSubDataDefaultObject;
    } else if (data.material_type == 2 || data.material_type == 4) {
      newSubObj = silkAndCutPieceSubDataDefaultObject;
    } else if (data.material_type == 1) {
      newSubObj = polyesterSubDataDefaultObject;
    }
    newSubObj.id = lastId + 1;
    data.sub_data.push(newSubObj);
    notifyOrderDetailsChange();
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setOrderDetails(cloned);
  };

  const getPlanBoughtQty = (itemId, prodId, subData) => {
    const obj = getQtyObjFromCacheUsingPrice(prodId, itemId, subData);
    if (obj != undefined && obj != null) {
      let quantity = subData.quantity;
      if (!validateNumber(quantity)) {
        quantity = 0;
      }
      const newQtyValue = obj.bought_quantity;
      return newQtyValue + '/' + obj.planned_quantity;
    }
    return 'NA';
  };

  const notifyOrderDetailsChange = () => {
    setOrderDetails({ ...orderDetails, refresh_id: orderDetails.refresh_id + 1 });
  };

  const handleRemoveSubDataClick = (data, subData) => {
    const subDataToRemove = data.sub_data.find((subItem) => subItem.id === subData.id);
    if (subDataToRemove != undefined) {
      const obj = getQtyObjFromCacheUsingPrice(data.prod_id, data.item_id, subDataToRemove);
      if (obj !== undefined) {
        obj.bought_quantity -= subDataToRemove.quantity;
      }
    }
    data.sub_data = data.sub_data.filter((subItem) => subItem.id !== subData.id);
    removeJumpingPriceCache(subData.jumping_price_id, data);
    notifyOrderDetailsChange();
  };

  const handleRemoveDataClick = (id) => {
    const orderDataToRemove = orderDetails.order_data.find((item) => item.id === id);
    const jumpingPriceIdArray = [];
    if (orderDataToRemove != undefined) {
      orderDataToRemove.sub_data.forEach((subData) => {
        if (!jumpingPriceIdArray.includes(subData.jumping_price_id)) {
          jumpingPriceIdArray.push(subData.jumping_price_id);
        }
        const obj = getQtyObjFromCacheUsingPrice(orderDataToRemove.prod_id, orderDataToRemove.item_id, subData);
        if (obj !== undefined) {
          obj.bought_quantity -= subData.quantity;
        }
      });
      if (jumpingPriceIdArray.length > 0) {
        const updatedCache = cloneDeep(jumpingPriceCache);
        jumpingPriceIdArray.forEach((id) => {
          delete updatedCache[id];
        });
        setJumpingPriceCache(updatedCache);
      }
    }
    orderDetails.order_data = orderDetails.order_data.filter((item) => item.id !== id);
    notifyOrderDetailsChange();
  };

  const handleProductIdChange = (event, data) => {
    const { name, value } = event.target;
    data[name] = value.toUpperCase();
    if (!validateStringForNull(value)) {
      data.prod_name = '';
    }
    notifyOrderDetailsChange();
  };

  const handleSubDataTextChange = (event, subData, order) => {
    const { name, value } = event.target;
    if (name === 'size_id') {
      const oldQtyValue = subData['quantity'];
      const oldSerial = getPlanSerial(order.prod_id, order.item_id, subData);
      subData[name] = value.toUpperCase();
      updateQtyCache(subData, order.prod_id, order.item_id, oldSerial, oldQtyValue);
      removeJumpingPriceCache(subData.jumping_price_id, order);
    } else {
      subData[name] = value.toUpperCase();
    }
    notifyOrderDetailsChange();
  };

  const removeJumpingPriceCache = (jumpingPriceId, orderData) => {
    if (!validateStringForNull(jumpingPriceId) || orderData.material_type != 3) {
      return;
    }
    const updatedCache = cloneDeep(jumpingPriceCache);
    delete updatedCache[jumpingPriceId];
    setJumpingPriceCache(updatedCache);

    if (orderData != undefined) {
      orderData.sub_data.forEach((subData) => {
        if (subData.jumping_price_id === jumpingPriceId) {
          subData.jumping_price_id = undefined;
          subData.jumping_price_sub_data_id = undefined;
        }
      });
    }
    notifyOrderDetailsChange();
  };

  const handleSubDataNumberChange = (event, subData, order) => {
    const { name, value } = event.target;
    if (validateStringForNull(value) && !validateNumber(value) ) {
      return;
    }
    removeJumpingPriceCache(subData.jumping_price_id, order);
    const oldQtyValue = subData['quantity'];
    const oldSerial = getPlanSerial(order.prod_id, order.item_id, subData);
    if (name === 'no_of_sets') {
      if (validateNumber(value)) {
        subData.no_of_sets = value;
        if (validateNumber(subData.qty_per_set)) {
          subData.quantity = value * subData.qty_per_set;
        } else {
          subData.quantity = 0;
        }
      } else {
        subData.quantity = 0;
      }
    } else if (name === 'qty_per_set') {
      if (validateNumber(value)) {
        subData.qty_per_set = value;
        if (validateNumber(subData.no_of_sets)) {
          subData.quantity = value * subData.no_of_sets;
        } else {
          subData.quantity = 0;
        }
      } else {
        subData.quantity = 0;
      }
    } else {
      subData[name] = value == '' ? '' : Number(value);
    }
    notifyOrderDetailsChange();
    updateQtyCache(subData, order.prod_id, order.item_id, oldSerial, oldQtyValue);
  };

  const updateQtyCache = (subData, prodId, itemId, oldSerial, oldQtyValue) => {
    let oldObj = undefined;
    if (oldSerial != -1) {
      oldObj = getQtyObjFromCacheUsingSerial(prodId, itemId, oldSerial);
    }
    const newSerial = getPlanSerial(prodId, itemId, subData);
    let newObj = undefined;
    if (newSerial != -1 ) {
      newObj = getQtyObjFromCacheUsingSerial(prodId, itemId, newSerial);
    }
    if (newSerial == oldSerial) {
      if (validateNumber(oldQtyValue) && newObj != undefined) {
        newObj.bought_quantity -= oldQtyValue;
      }
    } else {
      if (validateNumber(oldQtyValue) && oldObj != undefined) {
        oldObj.bought_quantity -= oldQtyValue;
      }
    }
    if (validateNumber(subData.quantity) && newObj != undefined) {
      newObj.bought_quantity += subData.quantity;
    }
  };

  const getProductNameSearchParams = (cateId) => {
    if (validateStringForNull(cateId)) {
      return 'cate_id='+ cateId;
    }
    return '';
  };

  const validateOrderDataArray = (params) => {
    const validatedOrders = params.order_data.map((plan, index) => {
      if (plan.material_type == 1) {
        return validatePolyesterOrderData(plan);
      } else if (plan.material_type == 2 || plan.material_type == 4) {
        return validateSilkAndCutPieceOrderData(plan);
      } else if (plan.material_type == 3) {
        return validateReadyMadeOrderData(plan);
      }
    });
    return validatedOrders;
  };

  const handleCreateOrUpdateOrderRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(orderDetails);
      const filteredParamsArray = validateOrderDataArray(params).flat();
      const filteredParams = {
        ...validateOrderDetails(params),
        order_data: filteredParamsArray
      };
      raiseCreateOrUpdateOrderRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Order' : 'Failed to Create Order'));
      setIsLoading(false);
    }
  };

  const validateAndGetAttachmentsForCreateUpdate = (attachments) => {
    const imgs = [];
    if (attachments == undefined) return imgs;
    Object.keys(attachments).map((key) => {
      if (!validateStringForNull(attachments[key].attachment_id)) {
        let err = 'Please wait until the attachments finish uploading.';
        err+= ' For rows with a warning sign, click the upload button again for each image to retry uploading';
        throw new Error(err);
      }
      imgs.push(attachments[key].attachment_id);
    });
    return imgs;
  };

  const validateOrderDetails = (params) => {
    const finalParams ={};
    if (isUpdateRequest) {
      if (!validateStringForNull(params.order_id)) {
        throw new Error('Select valid Order Id');
      }
      finalParams.order_id = params.order_id;
    }
    if (!validateStringForNull(params.plan_id)) {
      throw new Error('Select valid Plan Id');
    }
    if (!validateStringForNull(params.ac_id)) {
      throw new Error('Select valid Supplier Name');
    }
    if (validateStringForNull(params.agent_id)) {
      finalParams.agent_id = params.agent_id;
    }
    if (validateStringForNull(params.tran_id)) {
      finalParams.tran_id = params.tran_id;
    }
    if (validateStringForNull(orderDetails.remarks)) {
      finalParams.remarks = orderDetails.remarks;
    }
    finalParams.plan_id = params.plan_id;
    finalParams.ac_id = params.ac_id;

    return finalParams;
  };

  const validateSilkAndCutPieceOrderData = (plan) => {
    if (!validateStringForNull(plan.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(plan.prod_id)) {
      throw new Error('Select valid Product');
    }
    const orderDataArray = [];
    plan.sub_data.map((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const orderDataObj = {
        prod_id: plan.prod_id,
        item_id: plan.item_id,
        plan_serial: getPlanSerial(plan.prod_id, plan.item_id, subData),
        buy_price: parseFloat(subData.buy_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        orderDataObj.attachments = imgs;
      }
      if (validateStringForNull(subData.brand_id)) {
        orderDataObj.brand_id = subData.brand_id;
      }
      if (validateStringForNull(subData.model_id)) {
        orderDataObj.model_id = subData.model_id;
      }
      if (validateStringForNull(subData.description)) {
        orderDataObj.description = subData.description;
      }
      if (orderDataObj.plan_serial == -1) {
        delete orderDataObj.plan_serial;
      }
      orderDataArray.push(orderDataObj);
    });

    return orderDataArray;
  };

  const validateReadyMadeOrderData = (plan) => {
    if (!validateStringForNull(plan.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(plan.cate_id)) {
      throw new Error('Select valid Category');
    }
    if (!validateStringForNull(plan.prod_id)) {
      throw new Error('Select valid Product');
    }
    const orderDataArray = [];
    plan.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const obj = {
        item_id: plan.item_id,
        cate_id: plan.cate_id,
        prod_id: plan.prod_id,
        plan_serial: getPlanSerial(plan.prod_id, plan.item_id, subData),
        buy_price: parseFloat(subData.buy_price),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        obj.attachments = imgs;
      }
      if (validateStringForNull(subData.size_id)) {
        obj.size_id = subData.size_id;
      }
      if (validateStringForNull(subData.model_id)) {
        obj.model_id = subData.model_id;
      }
      if (validateStringForNull(subData.brand_id)) {
        obj.brand_id = subData.brand_id;
      }
      if (validateStringForNull(subData.color_id)) {
        obj.color_id = subData.color_id;
      }
      if (obj.plan_serial == -1) {
        delete obj.plan_serial;
      }
      orderDataArray.push(obj);
    });

    return orderDataArray;
  };

  const validatePolyesterOrderData = (plan) => {
    if (!validateStringForNull(plan.item_id)) {
      throw new Error('Select valid Mark');
    }
    if (!validateStringForNull(plan.prod_id)) {
      throw new Error('Select valid Product');
    }
    const orderDataArray = [];
    plan.sub_data.forEach((subData) => {
      if (!validateStringForNull(subData.buy_price)) {
        throw new Error('Provide valid Buy Price');
      }
      if (!validateStringForNull(subData.no_of_sets)) {
        throw new Error('Provide valid No Of Sets');
      }
      if (!validateStringForNull(subData.qty_per_set)) {
        throw new Error('Provide valid Quan Per Sets');
      }
      if (!validateStringForNull(subData.quantity)) {
        throw new Error('Provide valid Buy Quantity');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(subData.attachments);
      const obj = {
        item_id: plan.item_id,
        prod_id: plan.prod_id,
        plan_serial: getPlanSerial(plan.prod_id, plan.item_id, subData),
        buy_price: parseFloat(subData.buy_price),
        no_of_sets: parseInt(subData.no_of_sets),
        qty_per_set: parseInt(subData.qty_per_set),
        quantity: parseInt(subData.quantity)
      };
      if (imgs.length > 0) {
        obj.attachments = imgs;
      }
      if (obj.plan_serial == -1) {
        delete obj.plan_serial;
      }
      if (validateStringForNull(subData.brand_id)) {
        obj.brand_id = subData.brand_id;
      }
      if (validateStringForNull(subData.model_id)) {
        obj.model_id = subData.model_id;
      }
      orderDataArray.push(obj);
    });

    return orderDataArray;
  };

  const getPlanSerial = (prodId, itemId, subData) => {
    const obj = getQtyObjFromCacheUsingPrice(prodId, itemId, subData);
    if (obj != undefined && obj != null ) {
      return obj.serial;
    }
    return -1;
  };

  const generateQtyObjKey = (itemId, prodId) => {
    return `${itemId}-${prodId}`;
  };

  const getQtyObjFromCacheUsingPrice = (prodId, itemId, subData) => {
    const key = generateQtyObjKey(itemId, prodId);
    const price = subData.buy_price;
    let modelId = subData.model_id;
    let brandId = subData.brand_id;
    let colorId = subData.color_id;
    let sizeId = subData.size_id;
    if (!validateStringForNull(price)) {
      return undefined;
    }
    if (!validateStringForNull(modelId)) {
      modelId = undefined;
    }
    if (!validateStringForNull(brandId)) {
      brandId = undefined;
    }
    if (!validateStringForNull(colorId)) {
      colorId = undefined;
    }
    if (!validateStringForNull(sizeId)) {
      sizeId = undefined;
    }
    if (planProductQtyDetails[key] != undefined) {
      const obj = planProductQtyDetails[key].find((item) => {
        const priceMatch = (price >= item.min_price && price <= item.max_price);
        const modelMatch = modelId === item.model_id;
        const brandMatch = brandId === item.brand_id;
        const colorMatch = colorId === item.color_id;
        const sizeMatch = sizeId === item.size_id;

        return priceMatch && modelMatch && brandMatch && colorMatch && sizeMatch;
      });
      return obj;
    }
    return undefined;
  };

  const getQtyObjFromCacheUsingSerial = (prodId, itemId, serial) => {
    const key = generateQtyObjKey(itemId, prodId);
    if (planProductQtyDetails[key] != undefined) {
      const obj = planProductQtyDetails[key].find((item) => serial == item.serial);
      return obj;
    }
    return undefined;
  };

  const handleSharePOClick = () => {
    setPdfDialogMessage('Your PO is ready to email/download click on below button to proceed');
    setIsSharePdfDialogOpen(true);
  };

  const raiseGetPlanRequest = (planId, mark) => {
    setIsLoading(true);
    const params = 'plan_id=' + planId;
    Services.sendBackendRequest({ apiData: APIData.getPlanDetails, params: params },
      APIToken.VIEW_PLAN_DETAILS, processSuccess, processError, { mark: mark });
  };

  const raiseUploadImage = (dataId, subdataId, img, showSnackBar) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    const subdata = data.sub_data.find((obj) => obj.id == subdataId);
    subdata.attachments[img.name].status = ATTACHMENT_STATUS.UPLOADING;
    notifyOrderDetailsChange();
    const formData = new FormData();
    formData.append('item_id', data.item_id);
    formData.append('attachments', img);
    Services.sendMultipartRequest({ apiData: APIData.uploadAttachments, params: formData },
      APIToken.UPLOAD_ATTACHMENTS, processSuccess, processError,
      { dataId: dataId, subdataId: subdataId, imageKey: img.name, showSnackBar: showSnackBar });
  };

  const raiseDownloadImage = (dataId, subdataId, imgKey, showSnackBar) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    const subdata = data.sub_data.find((obj) => obj.id == subdataId);
    subdata.attachments[imgKey].status = ATTACHMENT_STATUS.DOWNLOADING;
    notifyOrderDetailsChange();
    const params = 'order_id=' + orderDetails.order_id + '&serial=' + subdata.serial;
    Services.sendBackendRequest({ apiData: APIData.downloadAttachment, uriValues: [subdata.attachments[imgKey].attachment_id], params: params },
      APIToken.DOWNLOAD_ATTACHMENT, processSuccess, processError,
      { dataId: dataId, subdataId: subdataId, imageKey: imgKey, showSnackBar: showSnackBar });
  };

  const raiseDownloadPdfRequest = (orderDetails, isEmailPDF) => {
    const params = 'email_pdf=' + isEmailPDF;
    Services.sendPDFDownloadRequest({ apiData: APIData.downloadOrderBookingPdf, uriValues: [orderDetails.created_order_id], params: params },
      APIToken.DOWNLOAD_ORDER_BOOKING_PDF, processSuccess, processError, { orderDetails: orderDetails });
  };

  // const raiseDownloadImages = (dataId, subdataId, imgKeys, showSnackBar) => {
  //   const data = orderDetails.order_data.find((obj) => obj.id == dataId);
  //   const subdata = data.sub_data.find((obj) => obj.id == subdataId);
  //   const attachmentIds = [];
  //   imgKeys.map((key) => {
  //     subdata.attachments[key].status = ATTACHMENT_STATUS.DOWNLOADING;
  //     attachmentIds.push(subdata.attachments[key].attachment_id);
  //   });
  //   notifyOrderDetailsChange();
  //   const params = 'attachment_ids=' + attachmentIds.join(',') + '&order_id=' + orderDetails.order_id + '&serial=' + subdata.serial;
  //   Services.sendBackendRequest({ apiData: APIData.downloadAttachments, params: params },
  //     APIToken.DOWNLOAD_ATTACHMENTS, processSuccess, processError,
  // { dataId: dataId, subdataId: subdataId, imageKeys: imgKeys, showSnackBar: showSnackBar });
  // };

  const handleShowPlanDetails = (data) => {
    data.is_expanded = !data.is_expanded;
    notifyOrderDetailsChange();
  };

  const handleAddPhotoClick = (dataId, subdataId) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, subdataId: subdataId, images: sdata.attachments });
      setIsCameraDialogOpen(true);
    }
  };

  const findSubData = (dataId, subdataId) => {
    const data = orderDetails.order_data.find((obj) => obj.id == dataId);
    if (data != null && data != undefined) {
      const subdata = data.sub_data.find((obj) => obj.id == subdataId);
      return subdata;
    }
    return undefined;
  };

  const handleGalleryClick = (dataId, subdataId) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, subdataId: subdataId, images: sdata.attachments });
      setIsGalleryOpen(true);
      // const ids= [];
      Object.keys(sdata.attachments).map((key) => {
        if (sdata.attachments[key].data == undefined) {
          // ids.push(key);
          handleDownloadPic(dataId, subdataId, key, false);
        }
      });
      // if (ids.length > 0) {
      //   try {
      //     raiseDownloadImages(dataId, subdataId, ids);
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
    }
  };

  const handleDownloadPic = (dataId, subdataId, imgKey, showSnackBar) => {
    if (imgKey != undefined) {
      try {
        raiseDownloadImage(dataId, subdataId, imgKey, showSnackBar);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleError = (status, message) => {
    showSnackBar(status, message);
  };

  const handleImageCapture = (img) => {
    const sdata = findSubData(cameraParams.dataId, cameraParams.subdataId);
    if (sdata != null && sdata != undefined) {
      const key = cameraParams.dataId.toString() + cameraParams.subdataId.toString() + moment().valueOf().toString() + '.png';
      const file = new File([img], key, { type: 'image/png' });
      sdata.attachments[key] = {};
      sdata.attachments[key].data = file;
      notifyOrderDetailsChange();
      try {
        raiseUploadImage(cameraParams.dataId, cameraParams.subdataId, file, false);
      } catch (err) {
        console.log(err);
      }
    }
    setIsCameraDialogOpen(false);
  };

  const handleImageDelete = (dataId, subdataId, key) => {
    const sdata = findSubData(dataId, subdataId);
    if (sdata != null && sdata != undefined) {
      delete sdata.attachments[key];
      notifyOrderDetailsChange();
    }
  };

  const getIsAttachmentsUploadPending = (imgs) => {
    let uploadPending = false;
    if (imgs == undefined) return uploadPending;
    Object.keys(imgs).map((key) => {
      if (!validateStringForNull(imgs[key].attachment_id)) {
        uploadPending = true;
      }
    });
    return uploadPending;
  };

  const getModelNameSearchParams = (prodId, brandId) => {
    if (validateStringForNull(prodId) && validateStringForNull(brandId)) {
      return 'prod_id='+ prodId+'&brand_id='+ brandId;
    }
    return '';
  };

  const getMaterialContent = (order) => {
    switch (order.material_type) {
    case 1:
      return getPolyesterContent(order);
    case 2:
    case 4:
      return getSilkAndCutPieceContent(order);
    case 3:
      return getReadyMadeContent(order);
    default:
      return 'Invalid Material type';
    }
  };

  const handleEmailOrDownloadPdfClick = (needEmail) => {
    try {
      setIsLoading(true);
      setIsSharePdfDialogOpen(false);
      const cloned = cloneDeep(createdOrderDetails);
      cloned.need_email = needEmail;
      setCreatedOrderDetails(cloned);
      raiseDownloadPdfRequest(cloned, needEmail);
    } catch (err) {
      showSnackBar('error', err.message);
      setIsLoading(false);
    }
  };

  const handleFilterPlanDialogCloseClick = () => {
    setIsFilterPlanDialogOpen(false);
    createNewOrderDetails(selectedMarkDetails);
  };

  const handleAddToOrderClick = (selectedPlanDetails) => {
    setIsFilterPlanDialogOpen(false);
    if (selectedPlanDetails != undefined && selectedPlanDetails.length > 0) {
      selectedPlanDetails.forEach((sPlan) => {
        const material = sPlan.material_type;
        const defaultSubDataObject = material === 2 || material === 4 ? silkAndCutPieceSubDataDefaultObject :
          material === 3 ? polyesterSubDataDefaultObject : readyMadeSubDataDefaultObject;
        const key = generateQtyObjKey(sPlan.item_id, sPlan.prod_id);
        if (planProductQtyDetails[key] == undefined) {
          raiseGetOrderQuantity(sPlan.prod_id, sPlan.item_id);
        }
        const matchingOrder = orderDetails.order_data.find((order) => {
          const isMarkMatching = order.item_id === sPlan.item_id && order.material_type === material && order.mark_name === sPlan.mark_name;
          if (material === 1 || material === 2 || material === 4) {
            // Material 1, 2, 4: Check prod_id if it exists
            return isMarkMatching && (!order.prod_id || sPlan.prod_id === order.prod_id);
          } else if (material === 3) {
            // Material 3: Check cate_id and prod_id if they exist
            return (
              isMarkMatching &&
              (!order.cate_id || sPlan.cate_id === order.cate_id) &&
              (!order.prod_id || sPlan.prod_id === order.prod_id)
            );
          }
          return false;
        });

        const newSubData = sPlan.sub_data.map((subPlan, index) => {
          const subData = cloneDeep(defaultSubDataObject);

          subData.id = matchingOrder ? matchingOrder.sub_data.length + index + 1 : index + 1;
          subData.brand_id = validateStringForNull(subPlan.brand_id) ? subPlan.brand_id : '';
          subData.brand_name = validateStringForNull(subPlan.brand_name) ? subPlan.brand_name : '';
          subData.model_id = validateStringForNull(subPlan.model_id) ? subPlan.model_id : '';
          subData.model_name = validateStringForNull(subPlan.model_name) ? subPlan.model_name : '';
          subData.quantity = '';
          subData.buy_price = '';
          subData.planned_quantity = subPlan.quantity;
          subData.description = validateStringForNull(subPlan.description) ? subPlan.description : '';
          subData.plan_serial = subPlan.serial;
          subData.min_price = subPlan.min_price;
          subData.max_price = subPlan.max_price;

          if (material === 3) {
            subData.size_id = validateStringForNull(subPlan.size_id) ? subPlan.size_id : '';
            subData.color_id = validateStringForNull(subPlan.color_id) ? subPlan.color_id : '';
            subData.color_name = validateStringForNull(subPlan.color_name) ? subPlan.color_name : '';
          } else if (material === 1) {
            subData.qty_per_set = validateStringForNull(subPlan.qty_per_set) ? subPlan.qty_per_set : '6';
            subData.no_of_sets = validateStringForNull(subPlan.no_of_sets) ? subPlan.no_of_sets : '';
          }
          return subData;
        });

        if (matchingOrder) {
          matchingOrder.prod_id = sPlan.prod_id;
          matchingOrder.prod_name = sPlan.prod_name;
          matchingOrder.sub_data = [...matchingOrder.sub_data, ...newSubData];
          if (material === 3) {
            matchingOrder.cate_id = sPlan.cate_id;
            matchingOrder.cate_name = sPlan.cate_name;
          }
        } else {
          const defaultObj = cloneDeep(readyMadeDefaultObject);
          const newOrder = {
            ...defaultObj,
            id: orderDetails.order_data.length + 1,
            item_id: sPlan.item_id,
            material_type: material,
            mark_name: sPlan.mark_name,
            prod_id: sPlan.prod_id,
            prod_name: sPlan.prod_name,
            sub_data: newSubData
          };
          if (material === 3) {
            newOrder.cate_id = sPlan.cate_id;
            newOrder.cate_name = sPlan.cate_name;
          }
          orderDetails.order_data.push(newOrder);
        }
      });
      notifyOrderDetailsChange();
    }
  };

  const handleCreateNewOrderButtonClick = () => {
    setIsSharePdfDialogOpen(false);
    if (validateStringForNull(createdOrderDetails.created_plan_id)) {
      raiseGetOrderListRequest(createdOrderDetails.created_plan_id);
    }
    setPlanProductQtyDetails({});
  };

  const handleRemarksTextChange = (event) => {
    setOrderDetails({ ...orderDetails, [event.target.name]: event.target.value });
  };

  const handleJumpingPriceDialogClick = (order, subData) => {
    if (validateStringForNull(subData.jumping_price_id)) {
      selectedJumpingPriceData.data = jumpingPriceCache[subData.jumping_price_id];
    } else {
      selectedJumpingPriceData.data = subData;
      selectedJumpingPriceData.data.data_id = order.id;
      selectedJumpingPriceData.data.sub_data_id = subData.id;
      selectedJumpingPriceData.data.cate_id = order.cate_id;
      selectedJumpingPriceData.data.cate_name = order.cate_name;
      selectedJumpingPriceData.data.prod_id = order.prod_id;
      selectedJumpingPriceData.data.prod_name = order.prod_name;
      selectedJumpingPriceData.data.mark_name = order.mark_name;
      selectedJumpingPriceData.data.item_id = order.item_id;
    }
    notifySelectedSubDataForJumpingPriceDetails();
    setIsCreateJumpingRangeDialogOpen(true);
  };

  const notifySelectedSubDataForJumpingPriceDetails = () => {
    setSelectedJumpingData({ ...selectedJumpingPriceData, refresh_id: selectedJumpingPriceData.refresh_id +1 });
  };

  const handleJumpingPriceDialogCloseClick = () =>{
    setIsCreateJumpingRangeDialogOpen(false);
  };

  const handleJumpingPriceConfirmClick = (jumpingPriceObj) => {
    const jumpingPriceCacheId = Object.keys(jumpingPriceObj)[0];
    const newObj = cloneDeep(jumpingPriceCache);
    newObj[jumpingPriceCacheId] = jumpingPriceObj[jumpingPriceCacheId];
    setJumpingPriceCache(newObj);
    createJumpingPriceOrderDetails(jumpingPriceObj);
    setIsCreateJumpingRangeDialogOpen(false);
  };

  const createJumpingPriceOrderDetails = (data) => {
    const [jumpingPriceCacheId, jumpingPriceData] = Object.entries(data)[0];
    const { jumping_price_data: jumpingPriceDataArray } = jumpingPriceData;

    const matchingOrder = orderDetails.order_data.find((order) => {
      return order.id === jumpingPriceData.data_id;
    });

    if (!matchingOrder) {
      console.log('No matching order_data found for the given Jumping Price Object');
      return;
    }

    const matchingSubDataIndex = matchingOrder.sub_data.findIndex((sub) => {
      return sub.id === jumpingPriceData.sub_data_id;
    });
    if (matchingSubDataIndex !== -1) {
      matchingOrder.sub_data.splice(matchingSubDataIndex, 1);
    } else {
      console.log('No matching sub_data found in order_data for the given Jumping Price Object');
      return;
    }

    matchingOrder.sub_data = matchingOrder.sub_data.filter(
      (sub) => sub.jumping_price_id !== jumpingPriceCacheId
    );
    let startIndex = 1;
    if (matchingOrder.sub_data.length > 0) {
      startIndex = matchingOrder.sub_data[matchingOrder.sub_data.length - 1].id + 1;
    }

    const defaultSubDataObj = cloneDeep(readyMadeDefaultObject);

    const newSubDataArray = jumpingPriceDataArray.map((jumpData, index) => ({
      ...defaultSubDataObj,
      id: startIndex + index,
      model_id: jumpingPriceData.model_id,
      model_name: jumpingPriceData.model_name,
      brand_id: jumpingPriceData.brand_id,
      brand_name: jumpingPriceData.brand_name,
      color_id: jumpingPriceData.color_id,
      color_name: jumpingPriceData.color_name,
      size_id: jumpData.size_id,
      buy_price: jumpData.buy_price,
      quantity: jumpData.quantity,
      jumping_price_id: jumpingPriceCacheId,
      jumping_price_sub_data_id: jumpData.id,
      attachments: jumpingPriceData.attachments
    }));
    matchingOrder.sub_data = [...matchingOrder.sub_data, ...newSubDataArray];
    newSubDataArray.map((subData) => {
      updateQtyCache(subData, matchingOrder.prod_id, matchingOrder.item_id, -1, undefined);
    });
    notifyOrderDetailsChange();
  };

  const getReadyMadeContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid item container xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  disabled={!isCreateAccessGranted}
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Category Name'}
                  isDisabled={!isCreateAccessGranted}
                  apiInfo={{
                    searchURI: APIData.getCategory,
                    searchField: 'cate_name',
                    idField: 'cate_id'
                  }}
                  defaultValue={{
                    cate_id: order.cate_id,
                    cate_name: order.cate_name
                  }}
                  onSelectionChange={(event, value) =>
                    handleAutoCompleteChange(value, order, 'cate_id', 'cate_name')}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <CustomAutocomplete
                  label={'Product Name'}
                  isDisabled={!isCreateAccessGranted}
                  apiInfo={{
                    searchURI: APIData.getProductName,
                    searchField: 'prod_name',
                    idField: 'prod_id',
                    searchParams: getProductNameSearchParams(order.cate_id)
                  }}
                  defaultValue={{
                    prod_id: order.prod_id,
                    prod_name: order.prod_name
                  }}
                  onSelectionChange={(event, value) =>
                    handleAutoCompleteChange(value, order, 'prod_id', 'prod_name')}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  disabled={!isCreateAccessGranted}
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton color='inherit' onClick={() => handleShowPlanDetails(order)}>
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((planSubData, j) => (
              <Grid item container xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={3.5} >
                  <CustomAutocomplete
                    label={'Brand Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getBrand,
                      searchField: 'brand_name',
                      idField: 'brand_id'
                    }}
                    defaultValue={{
                      brand_id: planSubData.brand_id,
                      brand_name: planSubData.brand_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleSubdataAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name', order)}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5} >
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Model Name'}
                    apiInfo={{
                      searchURI: APIData.getModel,
                      searchField: 'model_name',
                      idField: 'model_id',
                      searchParams: getModelNameSearchParams(order.prod_id, planSubData.brand_id)
                    }}
                    defaultValue={{
                      model_id: planSubData.model_id,
                      model_name: planSubData.model_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleSubdataAutoCompleteChange(value, planSubData, 'model_id', 'model_name', order)}
                  />
                </Grid>
                <Grid item xs={12} sm={3.5}>
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Color Name'}
                    apiInfo={{
                      searchURI: APIData.getColor,
                      searchField: 'color_name',
                      idField: 'color_id'
                    }}
                    defaultValue={{
                      color_id: planSubData.color_id,
                      color_name: planSubData.color_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleSubdataAutoCompleteChange(value, planSubData, 'color_id', 'color_name', order)}
                  />
                </Grid>
                <Grid item xs={4} sm={2} >
                  <TextField
                    id="size"
                    label="Size"
                    name="size_id"
                    disabled={!isCreateAccessGranted}
                    autoComplete ='off'
                    value={planSubData.size_id}
                    variant='standard'
                    onChange={(event) => handleSubDataTextChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6.5} sm={3} >
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    disabled={!isCreateAccessGranted}
                    name="buy_price"
                    autoComplete ='off'
                    variant='standard'
                    type='number'
                    value={planSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                    helperText={getPlannedPriceRange(planSubData.min_price, planSubData.max_price)}
                  />
                </Grid>
                {validateStringForNull(order.cate_id) && validateStringForNull(order.prod_id) ? (
                  <Grid item xs={1} sm={1} display='flex' justifyContent='center' sx={{ mt: 2, ml: -1.5 }} >
                    <Tooltip title='Jumping Price'>
                      <span>
                        <IconButton disabled={!isCreateAccessGranted} onClick={() =>
                          handleJumpingPriceDialogClick(order, planSubData)} color='primary' size='small'>
                          {planSubData.jumping_price_id != undefined ? (
                            <PlaylistAddCheckCircleRoundedIcon
                              style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer', color: 'green' }} />
                          ) : (
                            <PlaylistAddCircleRoundedIcon
                              style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                ) : ''}
                <Grid item xs={4} sm={2} >
                  <TextField
                    id="bought_quantity"
                    label="B/P Qty"
                    name="bought_quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={getPlanBoughtQty(order.item_id, order.prod_id, planSubData)}
                    disabled={true}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4.5} sm={2.5} >
                  <TextField
                    id="buy_quantity"
                    label="Buy Quantity"
                    disabled={!isCreateAccessGranted}
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={3.5} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleRemoveSubDataClick(order, planSubData)}
                      color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={planSubData.attachments != undefined ? Object.keys(planSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, planSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(planSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getSilkAndCutPieceContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid item container xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  disabled={!isCreateAccessGranted}
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_id}
                  onChange={(event) => handleProductIdChange(event, order)}
                  size='small'
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(order.id, order.prod_id, order.item_id)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton disabled={!isCreateAccessGranted} tabIndex={-1} onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  disabled={!isCreateAccessGranted}
                  tabIndex={-1}
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton color='inherit' tabIndex={-1} onClick={() => handleShowPlanDetails(order)}>
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((planSubData, j) => (
              <Grid item container xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={5.25} >
                  <CustomAutocomplete
                    label={'Brand Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getBrand,
                      searchField: 'brand_name',
                      idField: 'brand_id'
                    }}
                    defaultValue={{
                      brand_id: planSubData.brand_id,
                      brand_name: planSubData.brand_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleSubdataAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name', order)}
                  />
                </Grid>
                <Grid item xs={12} sm={5.25} >
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Model Name'}
                    apiInfo={{
                      searchURI: APIData.getModel,
                      searchField: 'model_name',
                      idField: 'model_id',
                      searchParams: getModelNameSearchParams(order.prod_id, planSubData.brand_id)
                    }}
                    defaultValue={{
                      model_id: planSubData.model_id,
                      model_name: planSubData.model_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleSubdataAutoCompleteChange(value, planSubData, 'model_id', 'model_name', order)}
                  />
                </Grid>
                <Grid item xs={6} sm={3.5}>
                  <TextField
                    id="description"
                    disabled={!isCreateAccessGranted}
                    label="Description"
                    name="description"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.description}
                    onChange={(event) => handleSubDataTextChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    disabled={!isCreateAccessGranted}
                    name="buy_price"
                    autoComplete ='off'
                    variant='standard'
                    type='number'
                    value={planSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                    helperText={getPlannedPriceRange(planSubData.min_price, planSubData.max_price)}
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <TextField
                    id="bought_quantity"
                    label="B/P Qty"
                    name="bought_quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={getPlanBoughtQty(order.item_id, order.prod_id, planSubData)}
                    size='small'
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4.5} sm={2}>
                  <TextField
                    id="buy_quantity"
                    label="Buy Quantity"
                    disabled={!isCreateAccessGranted}
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={3.5} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleRemoveSubDataClick(order, planSubData)}
                      color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={planSubData.attachments != undefined ? Object.keys(planSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, planSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(planSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  const getPolyesterContent = (order) => {
    return (
      <Grid item xs={12} key={order.id}>
        <Card elevation={12}>
          <Grid key={order.id} container>
            <Grid item container xs={12} columnSpacing={2} rowSpacing={2}
              sx={{ backgroundColor: '#20529524', p: 2, alignItems: 'center', display: 'flex', mt: 0, pt: 0 }}>
              <Grid item xs={2.8} sm={1.2} md={1.1}>
                <TextField
                  id="mark_name"
                  name="mark_name"
                  autoComplete ='off'
                  variant='outlined'
                  value={order.mark_name}
                  size='small'
                  fullWidth
                  sx={{ backgroundColor: '#205295', borderRadius: '7px',
                    '& .MuiInputBase-input': { textAlign: 'center' }
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={9.2} sm={4.4} md={4.5}>
                <TextField
                  id="prod_id"
                  label="Product Id"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_id}
                  onChange={(event) => handleProductIdChange(event, order)}
                  size='small'
                  disabled={!isCreateAccessGranted}
                  fullWidth
                  onBlur={() => handleProductDetailsSearch(order.id, order.prod_id, order.item_id)}
                />
              </Grid>
              <Grid item xs={7.8} sm={4.4} md={4.5}>
                <TextField
                  id="prod_name"
                  label="Product Name"
                  name="prod_id"
                  autoComplete ='off'
                  sx={{ backgroundColor: 'white' }}
                  value={order.prod_name}
                  size='small'
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4.2} sm={2} md={1.9} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                <IconButton tabIndex={-1} disabled={!isCreateAccessGranted} onClick={() => handleAddDataClick(order)} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
                <IconButton
                  disabled={!isCreateAccessGranted}
                  tabIndex={-1}
                  onClick={() => handleRemoveDataClick(order.id)} color='error' size='small' sx={{ p: 1 }}>
                  <DeleteIcon/>
                </IconButton>
                <IconButton tabIndex={-1} color='inherit' onClick={() => handleShowPlanDetails(order)} >
                  {order.is_expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                </IconButton>
              </Grid>
            </Grid>
            {order.is_expanded == true ? order.sub_data.map((planSubData, j) => (
              <Grid item container xs={12} key={planSubData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
                <Grid item xs={12} sm={4.75} >
                  <CustomAutocomplete
                    label={'Brand Name'}
                    isDisabled={!isCreateAccessGranted}
                    apiInfo={{
                      searchURI: APIData.getBrand,
                      searchField: 'brand_name',
                      idField: 'brand_id'
                    }}
                    defaultValue={{
                      brand_id: planSubData.brand_id,
                      brand_name: planSubData.brand_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleSubdataAutoCompleteChange(value, planSubData, 'brand_id', 'brand_name', order)}
                  />
                </Grid>
                <Grid item xs={12} sm={4.75} >
                  <CustomAutocomplete
                    isDisabled={!isCreateAccessGranted}
                    label={'Model Name'}
                    apiInfo={{
                      searchURI: APIData.getModel,
                      searchField: 'model_name',
                      idField: 'model_id',
                      searchParams: getModelNameSearchParams(order.prod_id, planSubData.brand_id)
                    }}
                    defaultValue={{
                      model_id: planSubData.model_id,
                      model_name: planSubData.model_name
                    }}
                    onSelectionChange={(event, value) =>
                      handleSubdataAutoCompleteChange(value, planSubData, 'model_id', 'model_name', order)}
                  />
                </Grid>
                <Grid item xs={5} sm={2.5}>
                  <TextField
                    id="buy_price"
                    label="Buy Price"
                    disabled={!isCreateAccessGranted}
                    name="buy_price"
                    autoComplete ='off'
                    variant='standard'
                    value={planSubData.buy_price}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                    type='number'
                    helperText={getPlannedPriceRange(planSubData.min_price, planSubData.max_price)}
                  />
                </Grid>
                <Grid item xs={3.5} sm={2}>
                  <TextField
                    id="bought_quantity"
                    label="B/P Qty"
                    name="bought_quantity"
                    autoComplete ='off'
                    variant='standard'
                    value={getPlanBoughtQty(order.item_id, order.prod_id, planSubData)}
                    size='small'
                    disabled={true}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3.5} sm={2}>
                  <TextField
                    id="no_of_sets"
                    label="No Of Sets"
                    name="no_of_sets"
                    autoComplete ='off'
                    disabled={!isCreateAccessGranted}
                    variant='standard'
                    value={planSubData.no_of_sets}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <TextField
                    id="qty_per_set"
                    label="Quan Per Set"
                    name="qty_per_set"
                    autoComplete ='off'
                    variant='standard'
                    disabled={!isCreateAccessGranted}
                    value={planSubData.qty_per_set}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                    type='phone'
                  />
                </Grid>
                <Grid item xs={4} sm={2}>
                  <TextField
                    id="buy_quantity"
                    label="Buy Quantity"
                    disabled={!isCreateAccessGranted}
                    name="quantity"
                    autoComplete ='off'
                    variant='standard'
                    type='phone'
                    value={planSubData.quantity}
                    onChange={(event) => handleSubDataNumberChange(event, planSubData, order)}
                    size='small'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={1.5} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
                  {j === order.sub_data.length - 1 && (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleAddSubDataClick(order)} color='primary' size='small'>
                      <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {order.sub_data.length > 1 ? (
                    <IconButton disabled={!isCreateAccessGranted} onClick={() => handleRemoveSubDataClick(order, planSubData)}
                      color='error' size='small'>
                      <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                  <Badge badgeContent={planSubData.attachments != undefined ? Object.keys(planSubData.attachments).length : 0}
                    showZero color="success" overlap="circular">
                    <IconButton onClick={() => handleGalleryClick(order.id, planSubData.id)} color='success' size='small'>
                      <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  </Badge>
                  {getIsAttachmentsUploadPending(planSubData.attachments) ? (
                    <IconButton color='warning' size='small'>
                      <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                    </IconButton>
                  ) : ''}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )) : ''}
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {isViewAccessGranted ? (
        <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
          <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography variant='h5' align='center'>
                Orders Booking
              </Typography>
            </Grid>
            <Grid item container xs={12} sm={6}>
              <Grid item xs={11}>
                <FormControl fullWidth >
                  <InputLabel size="small" id="plan-list-select-label"> Plan Id</InputLabel>
                  <Select
                    labelId="plan-list-select-label"
                    id="plan-list-select"
                    label="Plan id"
                    name='plan_id'
                    value={orderDetails.plan_id}
                    onChange={(event) => handlePlanChange(event)}
                    size="small"
                  >
                    {planList.map((obj) => (
                      <MenuItem key={obj.plan_id} value={obj.plan_id}>
                        {obj.plan_id} {obj.plan_tag ? `- ${obj.plan_tag}` : ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} alignContent='center'>
                <Tooltip title='View Plan'>
                  <span>
                    <IconButton
                      sx={{ backgroundColor: 'white' }} color='primary'
                      onClick={handleViewPlanClick}
                      size='small'
                      disabled={!validateStringForNull(orderDetails.plan_id)}
                    >
                      <VisibilityIcon/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={8} sm={4}>
              <FormControl fullWidth >
                <InputLabel size="small" id="order-list-select-label"> Order Id</InputLabel>
                <Select
                  labelId="order-list-select-label"
                  id="order-list-select"
                  label="Order Id"
                  name='order_id'
                  value={orderDetails.order_id}
                  onChange={(event) => handleOrderChange(event)}
                  size="small"
                >
                  {orderList.map((obj) => (
                    <MenuItem key={obj.order_id} value={obj.order_id}>
                      {obj.order_id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={2}>
              <TextField
                id="order Date"
                label="Order Date"
                name="order_date"
                autoComplete ='off'
                value={orderDetails.order_date}
                size='small'
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomAutocomplete
                label={'Supplier Name'}
                apiInfo={{
                  searchURI: APIData.getSupplier,
                  searchField: 'ac_name',
                  idField: 'ac_id'
                }}
                disabled={!isCreateAccessGranted}
                defaultValue={{
                  ac_id: orderDetails.ac_id,
                  ac_name: orderDetails.ac_name
                }}
                onSelectionChange={(event, value) =>
                  handleOrderDetailsAutoCompleteChange('ac_id', 'ac_name', value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomAutocomplete
                label={'Agent Name'}
                apiInfo={{
                  searchURI: APIData.getSupplier,
                  searchField: 'ac_name',
                  idField: 'ac_id'
                }}
                disabled={!isCreateAccessGranted}
                defaultValue={{
                  ac_id: orderDetails.agent_id,
                  ac_name: orderDetails.agent_name
                }}
                onSelectionChange={(event, value) =>
                  handleOrderDetailsAutoCompleteChange('agent_id', 'agent_name', value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomAutocomplete
                label={'Transport Name'}
                apiInfo={{
                  searchURI: APIData.getTransport,
                  searchField: 'tran_name',
                  idField: 'tran_id'
                }}
                disabled={!isCreateAccessGranted}
                defaultValue={{
                  tran_id: orderDetails.tran_id,
                  tran_name: orderDetails.tran_name
                }}
                onSelectionChange={(event, value) =>
                  handleOrderDetailsAutoCompleteChange('tran_id', 'tran_name', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {orderDetails.order_data.length > 0 ?
              orderDetails.order_data.map((order, index) => (
                <React.Fragment key={index}>
                  {getMaterialContent(order)}
                </React.Fragment>
              )) : ''}
            {validateStringForNull(orderDetails.order_id) && isCreateAccessGranted ? (
              <Grid item container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, mb: { xs: 1 } }}>
                  <Typography>
                    {orderDetails.order_data.length > 0 ? 'You can also add' : 'Select Mark'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {markList.map((mark) => (
                    <Chip
                      sx={{ m: 1 }}
                      key={mark.item_id}
                      label={mark.mark_name}
                      onClick={() => handleAddMarkClick(mark)}
                    />
                  ))}
                </Grid>
                {orderDetails.order_data.length > 0 ? (
                  <React.Fragment>
                    <Grid item xs={12} sx={{ my: 2 }}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        name="remarks"
                        autoComplete ='off'
                        value={orderDetails.remarks}
                        onChange={(event) => {handleRemarksTextChange(event);}}
                        size='small'
                        multiline
                        fullWidth
                        maxRows={4}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex', mt: 1 }}>
                      <Button
                        type='submit'
                        variant="text"
                        onClick={handleResetClick}
                        sx={{ mr: 2, fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                      >
                        Reset
                      </Button>
                      {validateStringForNull(orderDetails.order_id) && orderDetails.order_id != '-- NEW --' ? (
                        <Button
                          type="submit"
                          variant='outlined'
                          color='success'
                          onClick={handleSharePOClick}
                          sx={{ mr: 2, fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                          endIcon={<ShareIcon sx={{ fontSize: Utils.isMobile() ? '18px' : '20px' }} />}
                        >
                          Share Po
                        </Button>
                      ) : ''}
                      <Button
                        type="submit"
                        variant='contained'
                        sx={{ fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                        onClick={handleCreateOrUpdateOrderRequest}
                      >
                        {isUpdateRequest == true ? 'Update' : 'Create' }
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : ''}
              </Grid>
            ) : ''}
          </Grid>
          <Dialog
            fullScreen
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }
            }}
            open={isViewPlanDialogOpen}
            TransitionComponent={Transition}
            onClose={() => {}}
            PaperProps={{
              sx: {
                width: {
                  lg: '50%'
                }
              }
            }}
          >
            <AppBar position='sticky'>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setIsViewPlanDialogOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  View Plan Details
                </Typography>
              </Toolbar>
            </AppBar>
            <ViewPlanDetails planId={orderDetails.plan_id} />
          </Dialog>
          <Dialog
            fullScreen
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }
            }}
            open={isFilterPlanDialogOpen}
            TransitionComponent={Transition}
            onClose={() => {}}
            PaperProps={{
              sx: {
                width: {
                  lg: '50%'
                }
              }
            }}
          >
            <AppBar position='sticky'>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleFilterPlanDialogCloseClick()}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Filter Plan Details
                </Typography>
              </Toolbar>
            </AppBar>
            <FilterPlanDetails selectedPlan={filteredPlanByMark.filter_plan_data} onAddOrderClick={handleAddToOrderClick}/>
          </Dialog>
          <Dialog
            fullScreen
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }
            }}
            open={isCreateJumpingRangeDialogOpen}
            TransitionComponent={Transition}
            onClose={() => {}}
            PaperProps={{
              sx: {
                width: {
                  lg: '50%'
                }
              }
            }}
          >
            <AppBar position='sticky'>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleJumpingPriceDialogCloseClick()}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Jumping Price
                </Typography>
              </Toolbar>
            </AppBar>
            <JumpingPrice data={selectedJumpingPriceData.data} onConfirmClick={handleJumpingPriceConfirmClick} />
          </Dialog>
          <Dialog
            fullScreen
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }
            }}
            open={isCameraDialogOpen}
            TransitionComponent={Transition}
            onClose={() => {}}
            PaperProps={{
              sx: {
                width: {
                  lg: '40%'
                }
              }
            }}
          >
            <AppBar position='sticky'>
              <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Capture Image
                </Typography>
              </Toolbar>
            </AppBar>
            <TSCamera isOpen={isCameraDialogOpen} handleImageCapture={(img) => handleImageCapture(img)}
              handleClose={() => setIsCameraDialogOpen(false)} />
          </Dialog>
          <Dialog
            fullScreen
            sx={{
              '& .MuiDialog-container': {
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }
            }}
            open={isGalleryOpen}
            TransitionComponent={Transition}
            onClose={() => {}}
            PaperProps={{
              sx: {
                width: {
                  lg: '40%'
                }
              }
            }}
          >
            <AppBar position='sticky'>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setIsGalleryOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  Gallery
                </Typography>
              </Toolbar>
            </AppBar>
            <TSGallery dataId={cameraParams.dataId} subdataId={cameraParams.subdataId}
              images={cameraParams.images} openCamera={handleAddPhotoClick}
              handleAddPic={handleImageCapture} handleRemovePic={handleImageDelete} handleUploadPic={raiseUploadImage}
              handleDownloadPic={handleDownloadPic} allowedFeatures={allowedFeatures} handleError={handleError}/>
          </Dialog>
          <Dialog open={isSharePdfDialogOpen}
            onClose={() => setIsSharePdfDialogOpen(false)} maxWidth="xs" fullWidth
            sx={{
              '& .MuiDialog-paper': {
                borderRadius: '15px'
              }
            }}
          >
            <Box sx={{ textAlign: 'center', p: 3, pb: 1 }}>
              <IconButton
                aria-label="close"
                onClick={() => setIsSharePdfDialogOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" gutterBottom sx={{ p: 1, mt: 2 }}>
                {pdfDialogMessage}
              </Typography>
            </Box>
            <DialogActions sx={{ justifyContent: 'center', mb: 4, flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                <Button
                  onClick={() => handleEmailOrDownloadPdfClick(true)}
                  sx={{
                    backgroundColor: '#4cac4c',
                    '&:hover': {
                      backgroundColor: '#4cac4c'
                    }
                  }}
                  variant="contained"
                  endIcon={<EmailIcon />}
                >
                  Email Po
                </Button>
                <Button
                  onClick={() => handleEmailOrDownloadPdfClick(false)}
                  sx={{
                    backgroundColor: '#4cac4c',
                    '&:hover': {
                      backgroundColor: '#4cac4c'
                    }
                  }}
                  variant="contained"
                  endIcon={<FileDownloadIcon />}
                >
                  Download Po
                </Button>
              </Box>
              {createdOrderDetails.is_create ? (
                <React.Fragment>
                  <Typography variant="h6" textAlign="center">
                    Or
                  </Typography>
                  <Button
                    onClick={handleCreateNewOrderButtonClick}
                    variant="contained"
                    color="primary"
                    endIcon={<AddCircleIcon />}
                  >
                    Create New Order
                  </Button>
                </React.Fragment>
              ) : ''}
            </DialogActions>
          </Dialog>
        </Container>
      ) : ''}
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </React.Fragment>
  );
};

export default OrdersBooking;
