import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import ItemTimeline from '../components/reports/ItemTimeline';

const ItemTimelineReport = () => {
  const pageTitle = 'Item Timeline | Rajmahal Apps';
  return (
    <React.Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#FFF',
          minHeight: 'calc(94vh - 64px)',
          alignItems: 'flex-start',
          pb: 5,
          display: 'flex'
        }}
      >
        <ItemTimeline />
      </Box>
    </React.Fragment>
  );
};

export default ItemTimelineReport;
