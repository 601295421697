import { AppBar, Backdrop, Badge, Box, Button, Card, CircularProgress, Dialog, DialogActions, Divider, FormControl, FormControlLabel, Grid,
  IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Slide, TextField, Toolbar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { validateNumber, validateStringForNull } from '../../../../utils/FieldValidations.js';
import Services from '../../../../utils/Services.js';
import APIData from '../../../../utils/APIData.js';
import { blue } from '@mui/material/colors';
import CustomSnackbar from '../../../../common/components/CustomSnackbar.js';
import { CookieUtils, Utils } from '../../../../utils/UtilFunctions.js';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { cloneDeep } from 'lodash';
import { ATTACHMENT_STATUS } from '../../../../utils/EnumDefinitions.js';
import moment from 'moment';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import TSCamera from '../../../../common/components/TSCamera.js';
import TSGallery from '../../../../common/components/TSGallery.js';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import { ORDERS_FEATURES, validateFeature } from '../../../../utils/FeatureValidator.js';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DownloadIcon from '@mui/icons-material/Download';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const OrdersJewelleryBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const purchaserId = CookieUtils.getUserId();
  const [orderList, setOrderList] = useState([]);
  const [isUpdateRequest, setIsUpdateRequest] = useState(false);
  const [isViewAccessGranted, setIsViewAccessGranted] = useState(true);
  const [isDownloadPdfDialogOpen, setIsDownloadPdfDialogOpen] = useState(false);
  const APIToken = {
    GET_JEWELLERY_BOOKING_ORDER_LIST: 'CJB01',
    GET_JEWELLERY_BOOKING_ORDER: 'CJB02',
    CREATE_JEWELLERY_BOOKING_ORDER: 'CJB04',
    UPDATE_JEWELLERY_BOOKING_ORDER: 'CJB05',
    UPLOAD_JEWELLERY_ORDER_ATTACHMENTS: 'CJB06',
    DOWNLOAD_JEWELLERY_ORDER_ATTACHMENT: 'CJB07',
    DOWNLOAD_JEWELLERY_ORDER_ATTACHMENTS: 'CJB08',
    GET_USER: 'CJB09',
    DOWNLOAD_JEWELLERY_ORDER_PDF: 'CJB10'
  };
  const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [cameraParams, setCameraParams] = useState({});
  const [allowedFeatures, setAllowedFeatures] = useState([]);
  const [pdfDialogMessage, setPdfDialogMessage] = useState('');
  const [createdOrderDetails, setCreatedOrderDetails] = useState({
    created_order_id: '',
    need_email: false,
    is_create: true
  });

  const jewelleryDefaultSubObject = {
    id: 1,
    mc: '',
    va: '',
    va_unit: 'PERCENTAGE',
    mc_unit: 'PERCENTAGE',
    purity: '',
    weight: '',
    prod_name: '',
    no_of_pieces: '',
    attachments: {}
  };

  const [orderDetails, setOrderDetails] = useState({
    order_id: '',
    order_date: moment().format( 'DD-MM-YYYY'),
    order_tag: '',
    order_type: 'IMMEDIATE',
    party_name: '',
    party_gstin: '',
    party_phone: '',
    delivery_date: null,
    party_address: '',
    data: [jewelleryDefaultSubObject],
    refresh_id: 0,
    remarks: '',
    personal_remarks: ''
  });

  const [originalValue, setOriginalValue] = useState(orderDetails);

  const [snackBarStatus, setSnackBarStatus] = useState(Utils.getInitialStatusBarState);
  const handleSnackBarClose = () => {
    setSnackBarStatus(Utils.getInitialStatusBarState);
  };
  const getSnackbar = (
    <CustomSnackbar
      isOpen={snackBarStatus.open}
      severity={snackBarStatus.severity}
      message={snackBarStatus.message}
      onClose={handleSnackBarClose}
    />
  );

  const showSnackBar = (status, message) => {
    setSnackBarStatus({
      open: true,
      severity: status,
      message: message
    });
  };

  useEffect(() => {
    raiseGetUserRequest();
  }, []);

  const processSuccess = (apiData, apiToken, callbackValues, response) => {
    if (apiData == APIData.getJewelleryBookingOrderList && apiToken == APIToken.GET_JEWELLERY_BOOKING_ORDER_LIST ) {
      if (response.data != undefined) {
        const updatedOrderList = [
          ...response.data,
          { order_id: '-- NEW --', order_date: moment().format( 'DD-MM-YYYY') }
        ];
        setOrderList(updatedOrderList);
        const filteredOrder = updatedOrderList.find((obj) => obj.order_id == '-- NEW --');
        orderDetails.order_id = validateStringForNull(filteredOrder.order_id) ? filteredOrder.order_id : '';
        orderDetails.order_date = validateStringForNull(filteredOrder.order_date) ? filteredOrder.order_date : '';
        orderDetails.order_tag = '',
        orderDetails.order_type = 'IMMEDIATE',
        orderDetails.party_name = '',
        orderDetails.party_gstin = '',
        orderDetails.party_address = '',
        orderDetails.party_phone = '',
        orderDetails.delivery_date = null,
        orderDetails.data = [jewelleryDefaultSubObject];
        orderDetails.remarks = '',
        orderDetails.personal_remarks = '';
        notifyOrderDetailsChange();
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order list');
      }
      setIsUpdateRequest(false);
    } else if (apiData == APIData.getJewelleryBookingOrder && apiToken == APIToken.GET_JEWELLERY_BOOKING_ORDER ) {
      if (response.data != undefined) {
        populateJewelleryData(response.data, callbackValues.showShareDialog, callbackValues.isCreateOrder);
        setIsUpdateRequest(true);
      } else {
        showSnackBar('error', response.message ?? 'unable to retrieve order details');
      }
    } else if (apiData == APIData.createJewelleryBookingOrder && apiToken == APIToken.CREATE_JEWELLERY_BOOKING_ORDER) {
      setPdfDialogMessage('Order Created successfully! You can now download PO');
      const cloned = cloneDeep(orderList);
      cloned.splice(0, 0, { order_id: response.data.order_id, order_date: moment().format( 'DD-MM-YYYY') });
      setOrderList(cloned);
      raiseGetOrderRequest(response.data.order_id, true, true);
      return;
    } else if (apiData == APIData.updateJewelleryBookingOrder && apiToken == APIToken.UPDATE_JEWELLERY_BOOKING_ORDER) {
      setPdfDialogMessage('Order Updated Successfully! You can now download PO');
      raiseGetOrderRequest(callbackValues.order_id, true, false);
      return;
    } else if (apiData == APIData.uploadJewelleryOrderAttachments && apiToken == APIToken.UPLOAD_JEWELLERY_ORDER_ATTACHMENTS) {
      const orderData = findData(callbackValues.dataId);
      const attachmentId = response.data.attachments[callbackValues.imageKey];
      if (attachmentId == undefined) {
        orderData.attachments[callbackValues.imageKey].attachment_id = undefined;
        orderData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
        if (callbackValues.showSnackBar == true) {
          showSnackBar('error', 'Image upload failed. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
        }
      } else {
        orderData.attachments[callbackValues.imageKey].attachment_id = attachmentId;
        orderData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.downloadJewelleryOrderAttachment && apiToken == APIToken.DOWNLOAD_JEWELLERY_ORDER_ATTACHMENT) {
      const orderData = findData(callbackValues.dataId);
      const attachment = response.data.attachment;
      const attachmentId = response.data.attachment_id;
      let showSB = false;
      if (attachmentId == orderData.attachments[callbackValues.imageKey].attachment_id) {
        try {
          orderData.attachments[callbackValues.imageKey].data = Utils.base64ToFile(attachment, callbackValues.imageKey);
          orderData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.DONE;
        } catch (err) {
          showSB = true;
          orderData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
        }
      } else {
        showSB = true;
        orderData.attachments[callbackValues.imageKey].data = undefined;
        orderData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      }
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Image download failed. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.DOW && apiToken.downloadJewelleryOrderAttachments == APIToken.DOWNLOAD_JEWELLERY_ORDER_ATTACHMENTS) {
      const orderData = findData(callbackValues.dataId);
      const attachments = response.data;
      let showSB = false;
      callbackValues.imageKeys.map((key) => {
        const aId = orderData.attachments[key].attachment_id;
        const arr = attachments.find((obj) => obj.attachment_id == aId);
        if (arr != undefined && arr != null) {
          const attachment = arr.attachment;
          try {
            orderData.attachments[key].data = Utils.base64ToFile(attachment, key);
            orderData.attachments[key].status = ATTACHMENT_STATUS.DONE;
          } catch (err) {
            showSB = true;
            orderData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
          }
        }
      });
      if (callbackValues.showSnackBar == true && showSB == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (response.message != undefined ? `[Error: ${response.message}] ` : ''));
      }
      notifyOrderDetailsChange();
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER ) {
      if (response.data.app_list != undefined && response.data.app_list.length > 0) {
        const filtered = response.data.app_list.filter((app) => app.app_id == 'ORDERS');
        if (filtered != undefined && filtered.length > 0) {
          setAllowedFeatures(filtered[0].available_features);
          const isAllowed = validateFeature(filtered[0].available_features, ORDERS_FEATURES.VIEW_ORDERS);
          if (!isAllowed || filtered[0].role != 1) {
            setIsViewAccessGranted(false);
            showSnackBar('error', 'You don\'t have permission to view jewellery order details');
          } else if (validateStringForNull(purchaserId)) {
            raiseGetOrderListRequest();
            return;
          }
        }
      }
    } else if (apiData == APIData.downloadJewelleryOrderPdf && apiToken == APIToken.DOWNLOAD_JEWELLERY_ORDER_PDF) {
      showSnackBar('success', 'PDF downloaded successfully.');
    }
    setIsLoading(false);
  };

  const processError = (apiData, apiToken, callbackValues, err) => {
    if (callbackValues != undefined && callbackValues.suppressSnackBar == true) {
      setIsLoading(false);
      return;
    };
    let defaultMsg = 'Unhandled Exception';
    if (apiData == APIData.getJewelleryBookingOrderList && apiToken == APIToken.GET_JEWELLERY_BOOKING_ORDER_LIST) {
      defaultMsg = 'Failed to retrieve Order List';
    } else if (apiData == APIData.getJewelleryBookingOrder && apiToken == APIToken.GET_JEWELLERY_BOOKING_ORDER ) {
      defaultMsg = 'Failed to retrieve Order';
    } else if (apiData == APIData.createJewelleryBookingOrder && apiToken == APIToken.CREATE_JEWELLERY_BOOKING_ORDER) {
      defaultMsg = 'Failed to Create Order';
    } else if (apiData == APIData.updateJewelleryBookingOrder && apiToken == APIToken.UPDATE_JEWELLERY_BOOKING_ORDER) {
      defaultMsg = 'Failed to Update Order';
    } else if (apiData == APIData.uploadJewelleryOrderAttachments && apiToken == APIToken.UPLOAD_JEWELLERY_ORDER_ATTACHMENTS) {
      const orderData = findData(callbackValues.dataId);
      orderData.attachments[callbackValues.imageKey].attachment_id = undefined;
      orderData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NEW;
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Image upload failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadJewelleryOrderAttachment && apiToken == APIToken.DOWNLOAD_JEWELLERY_ORDER_ATTACHMENT) {
      const orderData = findData(callbackValues.dataId);
      orderData.attachments[callbackValues.imageKey].data = undefined;
      orderData.attachments[callbackValues.imageKey].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Image download failed. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.downloadJewelleryOrderAttachments && apiToken == APIToken.DOWNLOAD_JEWELLERY_ORDER_ATTACHMENTS) {
      const orderData = findData(callbackValues.dataId);
      callbackValues.imageKeys.map((key) => {
        orderData.attachments[key].data = undefined;
        orderData.attachments[key].status = ATTACHMENT_STATUS.NOT_DOWNLOADED;
      });
      if (callbackValues.showSnackBar == true) {
        showSnackBar('error', 'Few images failed to download. Try again. ' + (err.message != undefined ? `[Error: ${err.message}] ` : ''));
      }
      notifyOrderDetailsChange();
      return;
    } else if (apiData == APIData.getUser && apiToken == APIToken.GET_USER) {
      defaultMsg= 'Failed to get Logged in user details';
    } else if (apiData == APIData.downloadJewelleryOrderPdf && apiToken == APIToken.DOWNLOAD_JEWELLERY_ORDER_PDF) {
      defaultMsg = 'Failed to Generate PDF';
    }
    showSnackBar('error', err.message ?? defaultMsg);
    setIsLoading(false);
  };

  const raiseGetUserRequest = () => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getUser, uriValues: [CookieUtils.getUserId()] },
      APIToken.GET_USER, processSuccess, processError);
  };

  const raiseGetOrderListRequest = (isCreateAllowed) => {
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: APIData.getJewelleryBookingOrderList },
      APIToken.GET_JEWELLERY_BOOKING_ORDER_LIST, processSuccess, processError, { isCreateAllowed: isCreateAllowed } );
  };

  const raiseGetOrderRequest = (orderId, showShareDialog, isCreateOrder) => {
    setIsLoading(true);
    const params = 'order_id=' + orderId;
    Services.sendBackendRequest({ apiData: APIData.getJewelleryBookingOrder, params: params },
      APIToken.GET_JEWELLERY_BOOKING_ORDER, processSuccess, processError, { showShareDialog: showShareDialog, isCreateOrder: isCreateOrder } );
  };

  const notifyOrderDetailsChange = () => {
    setOrderDetails({ ...orderDetails, refresh_id: orderDetails.refresh_id + 1 });
  };

  const handleDownloadPOClick = () => {
    setPdfDialogMessage('Your PO is ready to download click on below button to proceed');
    setIsDownloadPdfDialogOpen(true);
  };

  const populateOrderAttachments = (attachmentIds, dataId) => {
    const attachments = {};
    if (attachmentIds == undefined) return attachments;
    attachmentIds.map((id, i) => {
      const key = dataId.toString() + moment().valueOf().toString() + i + '.png';
      const obj = {
        attachment_id: id,
        status: ATTACHMENT_STATUS.NOT_DOWNLOADED
      };
      attachments[key]= obj;
    });
    return attachments;
  };

  const populateJewelleryData = (data, showShareDialog, isCreateOrder) => {
    const { order_id: orderId, order_date: orderDate, meta_info: metaInfo, footer_info: footerInfo, order_details: orderData } = data;

    const orderDetailsObj = {
      order_id: orderId,
      order_date: validateStringForNull(orderDate) ? orderDate.display : '',
      order_tag: validateStringForNull(metaInfo.order_tag) ? metaInfo.order_tag : '',
      order_type: metaInfo.order_type,
      party_name: validateStringForNull(metaInfo.party_name) ? metaInfo.party_name : '',
      party_phone: validateStringForNull(metaInfo.party_phone) ? metaInfo.party_phone : '',
      party_address: validateStringForNull(metaInfo.party_address) ? metaInfo.party_address : '',
      party_gstin: validateStringForNull(metaInfo.party_gstin) ? metaInfo.party_gstin : '',
      delivery_date: validateStringForNull(metaInfo.delivery_date) ? moment(metaInfo.delivery_date.value) : null,
      remarks: validateStringForNull(footerInfo.remarks) ? footerInfo.remarks : '',
      personal_remarks: validateStringForNull(footerInfo.personal_remarks) ? footerInfo.personal_remarks : '',
      data: orderData.map((order, index) => {
        const attachments = populateOrderAttachments(order.attachment_id, index + 1);
        return {
          id: index + 1,
          mc: validateStringForNull(order.mc) ? (order.mc_unit == 'CURRENCY' ? order.mc.value : order.mc) : '',
          mc_unit: validateStringForNull(order.mc_unit) ? order.mc_unit : 'PERCENTAGE',
          va: validateStringForNull(order.va) ? (order.va_unit == 'CURRENCY' ? order.va.value : order.va) : '',
          va_unit: validateStringForNull(order.va_unit) ? order.va_unit : 'PERCENTAGE',
          purity: validateStringForNull(order.purity) ? order.purity : '',
          weight: order.weight,
          prod_name: order.prod_name,
          no_of_pieces: order.no_of_pieces,
          attachments: attachments || {}
        };
      })
    };
    setOrderDetails(orderDetailsObj);
    setCreatedOrderDetails({
      ...createdOrderDetails,
      created_order_id: orderId,
      is_create: isCreateOrder
    });
    if (showShareDialog) {
      setIsDownloadPdfDialogOpen(true);
    }
    setOriginalValue(cloneDeep(orderDetailsObj));
  };

  const validateAndGetAttachmentsForCreateUpdate = (attachments) => {
    const imgs = [];
    if (attachments == undefined) return imgs;
    Object.keys(attachments).map((key) => {
      if (!validateStringForNull(attachments[key].attachment_id)) {
        let err = 'Please wait until the attachments finish uploading.';
        err+= ' For rows with a warning sign, click the upload button again for each image to retry uploading';
        throw new Error(err);
      }
      imgs.push(attachments[key].attachment_id);
    });
    return imgs;
  };

  const handleOrderDetailsTextChange = (event) => {
    setOrderDetails({ ...orderDetails, [event.target.name]: event.target.value.toUpperCase() });
  };

  const handleOrderChange = (event) => {
    const { name, value } = event.target;
    const filteredOrder = orderList.find((order) => order.order_id === value);
    setOrderDetails({ ...orderDetails,
      [name]: value,
      order_date: moment().format( 'DD-MM-YYYY'),
      order_tag: '',
      order_type: 'IMMEDIATE',
      party_name: '',
      party_gstin: '',
      party_phone: '',
      delivery_date: null,
      party_address: '',
      data: [jewelleryDefaultSubObject],
      refresh_id: 0,
      remarks: '',
      personal_remarks: ''
    });
    setCreatedOrderDetails({ ...createdOrderDetails,
      is_create: false,
      created_order_id: '',
      need_email: false
    });
    if (filteredOrder.order_id != '-- NEW --') {
      raiseGetOrderRequest(value, false, false);
    } else {
      setIsUpdateRequest(false);
    }
  };

  const handleResetClick = () => {
    const cloned = cloneDeep(originalValue);
    setOrderDetails(cloned);
  };

  const handleAddDataClick = () => {
    const lastId = orderDetails.data[orderDetails.data.length - 1].id;
    const newSubObj = jewelleryDefaultSubObject;
    newSubObj.id = lastId + 1;
    orderDetails.data.push(newSubObj);
    notifyOrderDetailsChange();
  };

  const handleRemoveSubDataClick = (dataId) => {
    orderDetails.data = orderDetails.data.filter((item) => item.id !== dataId);
    notifyOrderDetailsChange();
  };

  const handleOrderDataTextChange = (event, orderData) => {
    const { name, value } = event.target;
    orderData[name] = value.toUpperCase();
    notifyOrderDetailsChange();
  };

  const handleOrderDataNumberChange = (event, orderData) => {
    const { name, value } = event.target;
    orderData[name] = !validateNumber(value) ? '' : Number(value);
    notifyOrderDetailsChange();
  };

  const handleAddPhotoClick = (dataId) => {
    const sdata = findData(dataId);
    if (sdata != null && sdata != undefined) {
      setCameraParams({ dataId: dataId, images: sdata.attachments });
      setIsCameraDialogOpen(true);
    }
  };

  const findData = (dataId) => {
    const orderData = orderDetails.data.find((obj) => obj.id == dataId);
    if (orderData != null && orderData != undefined) {
      return orderData;
    }
    return undefined;
  };

  const handleGalleryClick = (dataId) => {
    const orderData = findData(dataId);
    if (orderData != null && orderData != undefined) {
      setCameraParams({ dataId: dataId, images: orderData.attachments });
      setIsGalleryOpen(true);
      // const ids= [];
      Object.keys(orderData.attachments).map((key) => {
        if (orderData.attachments[key].data == undefined) {
          // ids.push(key);
          handleDownloadPic(dataId, key, false);
        }
      });
      // if (ids.length > 0) {
      //   try {
      //     raiseDownloadImages(dataId, subdataId, ids);
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
    }
  };

  const handleDownloadPic = (dataId, imgKey, showSnackBar) => {
    if (imgKey != undefined) {
      try {
        raiseDownloadImage(dataId, imgKey, showSnackBar);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleError = (status, message) => {
    showSnackBar(status, message);
  };

  const handleImageCapture = (img) => {
    const orderData = findData(cameraParams.dataId);
    if (orderData != null && orderData != undefined) {
      const key = cameraParams.dataId.toString() + moment().valueOf().toString() + '.png';
      const file = new File([img], key, { type: 'image/png' });
      orderData.attachments[key] = {};
      orderData.attachments[key].data = file;
      notifyOrderDetailsChange();
      try {
        raiseUploadImage(cameraParams.dataId, file, false);
      } catch (err) {
        console.log(err);
      }
    }
    setIsCameraDialogOpen(false);
  };

  const handleImageDelete = (dataId, key) => {
    const orderData = findData(dataId);
    if (orderData != null && orderData != undefined) {
      delete orderData.attachments[key];
      notifyOrderDetailsChange();
    }
  };

  const getIsAttachmentsUploadPending = (imgs) => {
    let uploadPending = false;
    if (imgs == undefined) return uploadPending;
    Object.keys(imgs).map((key) => {
      if (!validateStringForNull(imgs[key].attachment_id)) {
        uploadPending = true;
      }
    });
    return uploadPending;
  };

  const validateJewelleryPlanParams = (order) => {
    const formattedOrder = {
      meta_info: {},
      order_details: [],
      footer_info: {}
    };

    if (isUpdateRequest) {
      if (!validateStringForNull(order.order_id)) {
        throw new Error('Order ID is required');
      }
    }

    if (!validateStringForNull(order.order_type)) {
      throw new Error('Order Type is required');
    }
    if (!validateStringForNull(order.party_name)) {
      throw new Error('Party Name is required');
    }
    if (!validateStringForNull(order.party_phone)) {
      throw new Error('Party Phone is required');
    }
    if (!validateStringForNull(order.party_address)) {
      throw new Error('Party Address is required');
    }
    if (!validateStringForNull(order.party_gstin)) {
      throw new Error('Party GSTIN is required');
    }

    if (isUpdateRequest) {
      formattedOrder.order_id = order.order_id;
    }
    const metaInfoObj = {
      order_type: order.order_type,
      party_name: order.party_name,
      party_gstin: order.party_gstin,
      party_phone: order.party_phone,
      party_address: order.party_address
    };
    if (validateStringForNull(order.order_tag)) {
      metaInfoObj.order_tag = order.order_tag;
    }
    if (validateStringForNull(order.delivery_date)) {
      metaInfoObj.delivery_date = moment(order.delivery_date).format('yyyy-MM-DD');
    }
    formattedOrder.meta_info = metaInfoObj;
    const orderDataArray = [];
    order.data.forEach((orderData) => {
      if (!validateStringForNull(orderData.prod_name)) {
        throw new Error('Product Name is required');
      }
      if (!validateStringForNull(orderData.no_of_pieces)) {
        throw new Error('No of Pieces is required');
      }
      if (!validateStringForNull(orderData.weight)) {
        throw new Error('Weight is required');
      }
      const imgs = validateAndGetAttachmentsForCreateUpdate(orderData.attachments);
      const obj = {
        serial: order.id,
        prod_name: orderData.prod_name,
        no_of_pieces: orderData.no_of_pieces,
        weight: orderData.weight
      };
      if (validateStringForNull(orderData.mc)) {
        obj.mc = orderData.mc;
        obj.mc_unit = orderData.mc_unit;
      }
      if (validateStringForNull(orderData.va)) {
        obj.va = orderData.va;
        obj.va_unit = orderData.va_unit;
      }
      if (validateStringForNull(orderData.purity)) {
        obj.purity = orderData.purity;
      }
      if (imgs.length > 0) {
        obj.attachments = imgs;
      }
      orderDataArray.push(obj);
    });
    formattedOrder.order_details = orderDataArray;
    if (validateStringForNull(order.remarks)) {
      formattedOrder.footer_info.remarks = order.remarks;
    }
    if (validateStringForNull(order.personal_remarks)) {
      formattedOrder.footer_info.personal_remarks = order.personal_remarks;
    }
    return formattedOrder;
  };

  const handleCreateOrUpdateOrderRequest = () => {
    setIsLoading(true);
    try {
      const params = cloneDeep(orderDetails);
      const filteredParams = validateJewelleryPlanParams(params);
      raiseCreateOrUpdateOrderRequest(filteredParams);
    } catch (err) {
      showSnackBar('error', err.message ?? (isUpdateRequest ? 'Failed to Update Order' : 'Failed to Create Order'));
      setIsLoading(false);
    }
  };

  const raiseUploadImage = (dataId, img, showSnackBar) => {
    const orderData = findData(dataId);
    orderData.attachments[img.name].status = ATTACHMENT_STATUS.UPLOADING;
    notifyOrderDetailsChange();
    const formData = new FormData();
    formData.append('attachments', img);
    Services.sendMultipartRequest({ apiData: APIData.uploadJewelleryOrderAttachments, params: formData },
      APIToken.UPLOAD_JEWELLERY_ORDER_ATTACHMENTS, processSuccess, processError,
      { dataId: dataId, imageKey: img.name, showSnackBar: showSnackBar });
  };

  const raiseDownloadImage = (dataId, imgKey, showSnackBar) => {
    const orderData = findData(dataId);
    orderData.attachments[imgKey].status = ATTACHMENT_STATUS.DOWNLOADING;
    notifyOrderDetailsChange();
    const params = 'order_id=' + orderDetails.order_id + '&serial=' + dataId;
    Services.sendBackendRequest({ apiData: APIData.downloadJewelleryOrderAttachment,
      uriValues: [orderData.attachments[imgKey].attachment_id], params: params },
    APIToken.DOWNLOAD_JEWELLERY_ORDER_ATTACHMENT, processSuccess, processError,
    { dataId: dataId, imageKey: imgKey, showSnackBar: showSnackBar });
  };

  const raiseDownloadPdfRequest = (orderDetails, isEmailPDF) => {
    const params = 'email_pdf=' + isEmailPDF;
    Services.sendPDFDownloadRequest({ apiData: APIData.downloadJewelleryOrderPdf, uriValues: [orderDetails.created_order_id], params: params },
      APIToken.DOWNLOAD_JEWELLERY_ORDER_PDF, processSuccess, processError, { orderDetails: orderDetails });
  };

  const raiseCreateOrUpdateOrderRequest = (finalParams) => {
    let apiData = undefined;
    let apiToken = undefined;
    if (isUpdateRequest) {
      apiData = APIData.updateJewelleryBookingOrder;
      apiToken = APIToken.UPDATE_JEWELLERY_BOOKING_ORDER;
    } else {
      apiData = APIData.createJewelleryBookingOrder;
      apiToken = APIToken.CREATE_JEWELLERY_BOOKING_ORDER;
    }
    setIsLoading(true);
    Services.sendBackendRequest({ apiData: apiData, params: finalParams },
      apiToken, processSuccess, processError, { order_id: orderDetails.order_id } );
  };

  const handleDownloadPdfClick = (needEmail) => {
    try {
      setIsLoading(true);
      setIsDownloadPdfDialogOpen(false);
      const cloned = cloneDeep(createdOrderDetails);
      cloned.need_email = needEmail;
      setCreatedOrderDetails(cloned);
      raiseDownloadPdfRequest(cloned, needEmail);
    } catch (err) {
      showSnackBar('error', err.message);
      setIsLoading(false);
    }
  };

  const handleCreateNewOrderButtonClick = () => {
    setIsDownloadPdfDialogOpen(false);
    raiseGetOrderListRequest();
  };

  const handleDateChange = (date) => {
    setOrderDetails({ ...orderDetails, delivery_date: date });
  };

  const getJewelleryUIContent = (orderData, i) => {
    return (
      <Grid item xs={12} key={orderData.id} sx={{ mt: 2 }}>
        <Card elevation={12}>
          <Grid item container xs={12} key={orderData.id} rowSpacing={2} columnSpacing={2} sx={{ py: 1, px: 2 }}>
            <Grid item xs={6} sm={3} >
              <TextField
                id="prod_name"
                label="Product Name"
                name="prod_name"
                autoComplete ='off'
                variant='standard'
                disabled={!isViewAccessGranted}
                value={orderData.prod_name}
                onChange={(event) => handleOrderDataTextChange(event, orderData)}
                size='small'
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3} >
              <TextField
                id="no_of_pieces"
                label="No Of Pieces"
                name="no_of_pieces"
                autoComplete ='off'
                variant='standard'
                disabled={!isViewAccessGranted}
                value={orderData.no_of_pieces}
                type='number'
                onChange={(event) => handleOrderDataNumberChange(event, orderData)}
                size='small'
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                id="weight"
                label="Weight"
                name="weight"
                autoComplete ='off'
                value={orderData.weight}
                disabled={!isViewAccessGranted}
                variant='standard'
                onChange={(event) => handleOrderDataTextChange(event, orderData)}
                size='small'
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                id="purity"
                label="Purity"
                name="purity"
                autoComplete ='off'
                variant='standard'
                disabled={!isViewAccessGranted}
                value={orderData.purity}
                onChange={(event) => handleOrderDataTextChange(event, orderData)}
                size='small'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={5} sx={{ mt: -1 }}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography
                  variant="h6"
                  component="h5"
                  sx={{
                    position: 'absolute',
                    top: '-10px',
                    left: '16px',
                    background: '#fff',
                    padding: '0 8px'
                  }}
                >
                  VA
                </Typography>
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    px: '8px',
                    marginTop: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                  }}
                >
                  <Grid container columnSpacing={2}>
                    <Grid item xs={6} sm={7} sx={{ mt: 1 }} >
                      <TextField
                        id="va"
                        name="va"
                        autoComplete ='off'
                        variant='standard'
                        value={orderData.va}
                        disabled={!isViewAccessGranted}
                        type='number'
                        onChange={(event) => handleOrderDataNumberChange(event, orderData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} sm={5} display='flex' justifyContent='flex-end'>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="va_unit"
                          value={orderData.va_unit}
                          disabled={!isViewAccessGranted}
                          onChange={(event) => handleOrderDataTextChange(event, orderData)}
                        >
                          <FormControlLabel disabled={!isViewAccessGranted} value="PERCENTAGE" control={<Radio />} label="%" />
                          <FormControlLabel disabled={!isViewAccessGranted} value="CURRENCY" control={<Radio />} label="₹" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} sx={{ mt: -1 }}>
              <Box sx={{ position: 'relative', width: '100%' }}>
                <Typography
                  variant="h6"
                  component="h5"
                  sx={{
                    position: 'absolute',
                    top: '-10px',
                    left: '16px',
                    background: '#fff',
                    padding: '0 8px'
                  }}
                >
                  MC
                </Typography>
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    px: '8px',
                    marginTop: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                  }}
                >
                  <Grid container columnSpacing={2}>
                    <Grid item xs={6} sm={7} sx={{ mt: 1 }} >
                      <TextField
                        id="mc"
                        name="mc"
                        autoComplete ='off'
                        variant='standard'
                        value={orderData.mc}
                        disabled={!isViewAccessGranted}
                        type='number'
                        onChange={(event) => handleOrderDataNumberChange(event, orderData)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} sm={5} display='flex' justifyContent='flex-end'>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="mc_unit"
                          value={orderData.mc_unit}
                          disabled={!isViewAccessGranted}
                          onChange={(event) => handleOrderDataTextChange(event, orderData)}
                        >
                          <FormControlLabel disabled={!isViewAccessGranted} value="PERCENTAGE" control={<Radio />} label="%" />
                          <FormControlLabel disabled={!isViewAccessGranted} value="CURRENCY" control={<Radio />} label="₹" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} sx={{ mt: 1, justifyContent: 'flex-end', display: 'flex' }}>
              {i === orderDetails.data.length - 1 && (
                <IconButton disabled={!isViewAccessGranted} onClick={() => handleAddDataClick()} color='primary' size='small'>
                  <AddCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }} />
                </IconButton>
              )}
              {orderDetails.data.length > 1 ? (
                <IconButton disabled={!isViewAccessGranted} onClick={() => handleRemoveSubDataClick(orderData.id)}
                  color='error' size='small'>
                  <RemoveCircleIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                </IconButton>
              ) : ''}
              <Badge badgeContent={orderData.attachments != undefined ? Object.keys(orderData.attachments).length : 0}
                showZero color="success" overlap="circular">
                <IconButton disabled={!isViewAccessGranted} onClick={() => handleGalleryClick(orderData.id)} color='success' size='small'>
                  <AddAPhotoIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                </IconButton>
              </Badge>
              {getIsAttachmentsUploadPending(orderData.attachments) ? (
                <IconButton color='warning' size='small'>
                  <WarningIcon style={{ fontSize: 20, textAlignVertical: 'center', cursor: 'pointer' }}/>
                </IconButton>
              ) : ''}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Container maxWidth="md" sx={{ backgroundColor: 'white', py: 2 }}>
        <Grid container direction='row' columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography variant='h5' align='center'>
              Jewellery Booking
            </Typography>
          </Grid>
          <Grid item xs={7} sm={4}>
            <FormControl fullWidth >
              <InputLabel size="small" id="order-list-select-label"> Order Id</InputLabel>
              <Select
                labelId="order-list-select-label"
                id="order-list-select"
                label="Order Id"
                name='order_id'
                value={orderDetails.order_id}
                disabled={!isViewAccessGranted}
                onChange={(event) => handleOrderChange(event)}
                size="small"
              >
                {orderList.map((obj) => (
                  <MenuItem key={obj.order_id} value={obj.order_id}>
                    {obj.order_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5} sm={2}>
            <TextField
              id="Order Date"
              label="Order Date"
              name="order_date"
              autoComplete ='off'
              disabled={!isViewAccessGranted}
              value={orderDetails.order_date}
              size='small'

              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="Order Tag"
              label="Order Tag"
              name="order_tag"
              autoComplete ='off'
              disabled={!isViewAccessGranted}
              value={orderDetails.order_tag}
              onChange={(event) => {handleOrderDetailsTextChange(event);}}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item container xs={12} sm={4} sx={{ mt: -1 }}>
            <Box sx={{ position: 'relative', width: '100%' }}>
              <Typography
                variant="h6"
                component="h5"
                sx={{
                  position: 'absolute',
                  top: '-4px',
                  left: '16px',
                  background: '#fff',
                  padding: '0 8px'
                }}
              >
                Order Type
              </Typography>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  px: '16px',
                  marginTop: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }}
              >
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="order_type"
                      value={orderDetails.order_type}
                      onChange={(event) => {handleOrderDetailsTextChange(event);}}
                    >
                      <FormControlLabel disabled={!isViewAccessGranted} value="IMMEDIATE" control={<Radio />} label="Immediate" />
                      <FormControlLabel disabled={!isViewAccessGranted} value="FORWARD" control={<Radio />} label="Forward" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="party_name"
              label="Party Name"
              name="party_name"
              autoComplete ='off'
              disabled={!isViewAccessGranted}
              value={orderDetails.party_name}
              onChange={(event) => {handleOrderDetailsTextChange(event);}}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="party_phone"
              label="Party Phone"
              name="party_phone"
              autoComplete ='off'
              disabled={!isViewAccessGranted}
              value={orderDetails.party_phone}
              onChange={(event) => {handleOrderDetailsTextChange(event);}}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="party_address"
              label="Party Address"
              name="party_address"
              autoComplete ='off'
              disabled={!isViewAccessGranted}
              value={orderDetails.party_address}
              onChange={(event) => {handleOrderDetailsTextChange(event);}}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="party_gstin"
              label="Party GST"
              name="party_gstin"
              autoComplete ='off'
              disabled={!isViewAccessGranted}
              value={orderDetails.party_gstin}
              onChange={(event) => {handleOrderDetailsTextChange(event);}}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Delivery Date"
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                disabled={!isViewAccessGranted}
                value={orderDetails.delivery_date}
                name="delivery_date"
                disablePast
                onChange= {handleDateChange}
                slotProps={{ textField: { variant: 'outlined', size: 'small', width: '100%' } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {orderDetails.data.length > 0 ?
            orderDetails.data.map((order, index) => (
              <React.Fragment key={index}>
                {getJewelleryUIContent(order, index)}
              </React.Fragment>
            )) : ''}
          {validateStringForNull(orderDetails.order_id) ? (
            <Grid item container>
              {orderDetails.data.length > 0 ? (
                <React.Fragment>
                  <Grid item xs={12} sx={{ my: 2 }}>
                    <TextField
                      id="personal_remarks"
                      label="Personal Remarks"
                      name="personal_remarks"
                      autoComplete ='off'
                      disabled={!isViewAccessGranted}
                      value={orderDetails.personal_remarks}
                      onChange={(event) => {handleOrderDetailsTextChange(event);}}
                      size='small'
                      multiline
                      fullWidth
                      maxRows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ my: 2 }}>
                    <TextField
                      id="remarks"
                      label="Remarks"
                      name="remarks"
                      autoComplete ='off'
                      disabled={!isViewAccessGranted}
                      value={orderDetails.remarks}
                      onChange={(event) => {handleOrderDetailsTextChange(event);}}
                      size='small'
                      multiline
                      fullWidth
                      maxRows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex', mt: 1 }}>
                    <Button
                      type='submit'
                      variant="text"
                      onClick={handleResetClick}
                      sx={{ mr: 2, fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                    >
                      Reset
                    </Button>
                    {validateStringForNull(orderDetails.order_id) && orderDetails.order_id != '-- NEW --' ? (
                      <Button
                        type="submit"
                        variant='outlined'
                        color='success'
                        onClick={handleDownloadPOClick}
                        sx={{ mr: 2, fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                        endIcon={<DownloadIcon sx={{ fontSize: Utils.isMobile() ? '18px' : '20px' }} />}
                      >
                        Generate Po
                      </Button>
                    ) : ''}
                    <Button
                      type="submit"
                      variant='contained'
                      sx={{ fontSize: Utils.isMobile() ? '0.75rem' : '0.875rem' }}
                      onClick={handleCreateOrUpdateOrderRequest}
                    >
                      {isUpdateRequest == true ? 'Update' : 'Create' }
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : ''}
            </Grid>
          ) : ''}
        </Grid>
        <Dialog
          fullScreen
          sx={{
            '& .MuiDialog-container': {
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }
          }}
          open={isCameraDialogOpen}
          TransitionComponent={Transition}
          onClose={() => {}}
          PaperProps={{
            sx: {
              width: {
                lg: '40%'
              }
            }
          }}
        >
          <AppBar position='sticky'>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Capture Image
              </Typography>
            </Toolbar>
          </AppBar>
          <TSCamera isOpen={isCameraDialogOpen} handleImageCapture={(img) => handleImageCapture(img)}
            handleClose={() => setIsCameraDialogOpen(false)} />
        </Dialog>
        <Dialog
          fullScreen
          sx={{
            '& .MuiDialog-container': {
              justifyContent: 'flex-end',
              alignItems: 'flex-end'
            }
          }}
          open={isGalleryOpen}
          TransitionComponent={Transition}
          onClose={() => {}}
          PaperProps={{
            sx: {
              width: {
                lg: '40%'
              }
            }
          }}
        >
          <AppBar position='sticky'>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsGalleryOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Gallery
              </Typography>
            </Toolbar>
          </AppBar>
          <TSGallery dataId={cameraParams.dataId} subdataId={cameraParams.subdataId}
            images={cameraParams.images} openCamera={handleAddPhotoClick}
            handleAddPic={handleImageCapture} handleRemovePic={handleImageDelete} handleUploadPic={raiseUploadImage}
            handleDownloadPic={handleDownloadPic} allowedFeatures={allowedFeatures} handleError={handleError}/>
        </Dialog>
        <Dialog open={isDownloadPdfDialogOpen}
          onClose={() => setIsDownloadPdfDialogOpen(false)} maxWidth="xs" fullWidth
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: '15px'
            }
          }}
        >
          <Box sx={{ textAlign: 'center', p: 3, pb: 1 }}>
            <IconButton
              aria-label="close"
              onClick={() => setIsDownloadPdfDialogOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" gutterBottom sx={{ p: 1, mt: 2 }}>
              {pdfDialogMessage}
            </Typography>
          </Box>
          <DialogActions sx={{ justifyContent: 'center', mb: 4, flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
              <Button
                onClick={() => handleDownloadPdfClick(false)}
                sx={{
                  backgroundColor: '#4cac4c',
                  '&:hover': {
                    backgroundColor: '#4cac4c'
                  }
                }}
                variant="contained"
                endIcon={<FileDownloadIcon />}
              >
                Download Po
              </Button>
            </Box>
            {createdOrderDetails.is_create ? (
              <React.Fragment>
                <Typography variant="h6" textAlign="center">
                  Or
                </Typography>
                <Button
                  onClick={handleCreateNewOrderButtonClick}
                  variant="contained"
                  color="primary"
                  endIcon={<AddCircleIcon />}
                >
                  Create New Order
                </Button>
              </React.Fragment>
            ) : ''}
          </DialogActions>
        </Dialog>
      </Container>
      {getSnackbar}
      <Backdrop open={isLoading}>
        <CircularProgress style= {{ color: blue[200] }} />
      </Backdrop>
    </React.Fragment>
  );
};

export default OrdersJewelleryBooking;
